import React from "react";
// import $ from 'jquery';
import { NavLink } from 'react-router-dom';
import { logout } from "../actions/userActions";
import { useDispatch } from "react-redux";

function Header() {
    // var windows = $(window);
    const dispatch = useDispatch();
    const logoutHandler = () => {
        dispatch(logout());
    };
    /*--
        Menu Sticky
    -----------------------------------*/
    // var sticky = $('.header-sticky');

    // windows.on('scroll', function () {
    //     var scroll = windows.scrollTop();
        // if (scroll < 300) {
        //     sticky.removeClass('is-sticky');
        // } else {
        //     sticky.addClass('is-sticky');
        // }
    // });
    const loginRegLink = (
        <ul>
            <li><NavLink to="/" >Home</NavLink></li>
            <li><NavLink to="/event" >Event</NavLink></li>
            <li><NavLink to="/job" >Job</NavLink></li>
        </ul>
    );
    var name = JSON.parse(localStorage.getItem('info'));
    var user 
    if(name != null)
    {
      user = name['name']
    }else{
    user = 'user'
    }
    const loginprofile = (
        <ul>
            <li><a href="#0"> <i className="lnr lnr-user" />
                Hi, {user}  <small className="icon-arrow"></small></a>
                <ul className="sub-menu">
                    <li ><NavLink to="/memberdetailprofile" >Profile</NavLink></li>
                    <li ><NavLink to="/daftar_activity_admin" >Aktifitas</NavLink></li>
                    <li onClick={logoutHandler}><NavLink to="/login" >Keluar</NavLink></li>
                </ul>
            </li>
        </ul>
    );
    const notlogin = (
        <ul>
            <li><NavLink to="/login" > <i className="lnr lnr-user" />
                <span>Login</span>
            </NavLink>
            </li>
        </ul>
    );
    // const userLink = (
    //     <ul>
    //         <li><NavLink to="/" >Home</NavLink></li>
    //         <li><NavLink to="/event" >Event</NavLink></li>
    //         <li><NavLink to="/job" >Job</NavLink></li>
    //         <li><NavLink to="/professionlist" >Profession</NavLink></li>
    //         <li><NavLink to="/memberlist">Manage Member</NavLink></li>
    //         <li><NavLink to="/eventlist">Manage Event</NavLink></li>
    //         <li><NavLink to="/managejob">Manage Job</NavLink></li>
    //     </ul>
    // );
    return (
        <header className="black-logo-version header-sticky sticky-white d-none d-lg-block " >

            <div className="main-header">
                <div className="container pl-15 pl-md-0 pr-15 pr-md-0">
                    <div className="row align-items-center no-gutters">
                        <div className="col-xl-2 col-lg-2 col-12">
                            <div className="logo">
                                <a href="/"><img src="/assets/images/logo-digihealth.png" width="80%" alt="digihealth" /></a>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-12">
                            <nav className="main-menu">
                                {loginRegLink}

                            </nav>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-12">
                            <div className="header-btn-action d-flex justify-content-end">
                                <div className="btn-action-wrap d-flex">
                                    <div className="jp-author item">

                                        <nav className="main-menu">
                                            {localStorage.userInfo ? loginprofile : notlogin}

                                        </nav>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    )
}
export default Header;
