import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Headermenu";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { verifikasi } from "../../actions/userActions";


export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            message:''

        };
        
    }
    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let query = params.get('sid');
        if(query !== null )
        {
            this.receivedData(query)
        }else{
            this.props.history.push(`/login`)
        }
    }

    receivedData(query) {
        this.setState({loading:true,message:"Mohon menunggu sedang proses verifikasi"})
        verifikasi(query).then(res => {
            if(res.code === 200)
            {
                this.setState({loading:false,message:"User anda berhasil diverfikasi"})
                this.logoutHandler()
            }else{
                this.setState({loading:false,message:"Mohon Maaf User tidak ditemukan"})

            }
        })
    }

     logoutHandler (){
        localStorage.clear();
        this.props.history.push(`/login`);
    };
    
    render() {
        return (
        <div id="main-wrapper" >
            <Headers />
            <Headermobile />
            <Menumobile />
            <BottomNavbarMobile />
            <BottomNavbarMobilePopup />

            <div className="login-register-section section bg_color--5 pt-70 pt-sm-50 pt-xs-50 pb-70 pb-sm-60 pb-xs-50">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-12">
                            <div className="error-404 not-found">
                                <div className="text-label-404">
                                    <h1>{this.state.message}</h1>
                                </div>
                                <div className="home-link">
                                    <span>Mohon hubungi admin jika anda mengalami kendala. </span>
                                </div>
                                <div className="search-404">
                                    <div style={this.state.loading ? {} : { display: 'none' }} class="spinner-border text-primary col-form-label br-2" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footers />
        </div>
   
   )
}
}

