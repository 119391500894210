import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import Popupmenu from "../../components/Popupmenu";
import { getjobListclient, getCity, addCandidate, CheckCandidate, SendmailLamaranditinjau } from "../../actions/jobAction";
import ReactPaginate from 'react-paginate';
import { setTitle, setDescription } from "../../actions/userActions";
import Moment from 'react-moment';
import 'moment/locale/id';
import Select from 'react-select'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)


const customStyles = {
    control: (base, state) => ({
        ...base
    }),
    menu: (base) => ({
        ...base
    }),
    menuList: (base) => ({
        ...base,
        minHeight: "150px" // your desired height
    })
};

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            data: [],
            perPage: 25,
            currentPage: 0,
            searchTitle: '',
            searchActive: '',
            selectOptions: [],
            joblocation: "",
            title: ''

        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.onChange = this.onChange.bind(this)
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
        this.receivedData()
    }
    handleChange(e) {
        if (e !== null) {
            this.setState({ joblocation: e.value })
        } else {
            this.setState({ joblocation: "" })
        }
    }

    onLamar = (id, jobname, company, location, type, psychotest, e) => {
        e.preventDefault()
        var info = JSON.parse(localStorage.getItem('info'));
        if (info === null) {
            MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap Login terlebih dahulu!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                e.preventDefault()
                if (result.isConfirmed) {
                    window.location.href = '/login'
                }
            })
        } else if (info.gelardepan === undefined || info.gelarbelakang === undefined || info.tlp === undefined) {

            MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkapi Profil Umum dan Pribadi, Alamat, Pendidikan dan upload CV Anda terlebih dahulu.!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = '/memberdetailprofile'
                }
            })

        } else if (info.nip === undefined || info.str === undefined || info.npkidi === undefined || info.nik === undefined || info.tempatlahir === undefined || info.tanggallahir === undefined || info.profesi === "-") {

            MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkapi Profile Pribadi Anda terlebih dahulu!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = '/memberdetailprofile'

                }
            })

        } else if (info.address.length === 0) {

            MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkapi informasi Alamat Anda terlebih dahulu!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = '/listaddress'
                }
            })

        } else if (info.education.length === 0) {

            MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkapi informasi Pendidikan Anda terlebih dahulu!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = '/listpendidikan'
                }
            })
        } else {
            CheckCandidate(id).then(res => {
                const data = res['data'] ? res['data'] : [];
                var filterbyuser = data.filter(item => (info.email ? item.email === info.email : []))
                var today = new Date();
                var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                if (filterbyuser.length === 0) {
                    const add = {
                        applydate:date,
                        email:info.email,
                        gelardepan:info.gelardepan,
                        gelarbelakang:info.gelarbelakang,
                        phone:info.tlp,
                        status:'Lamaran Ditinjau',
                        name:info.name,
                        jobid:id,
                        jobtitle:jobname
                    }
                    let payloadjob = [];
                    payloadjob.push({ 'id': id, 'name': jobname, 'company':company, 'location':location, 'type':type, 'psychotest':psychotest });
                    localStorage.setItem('payloadjob', JSON.stringify(payloadjob));
                    addCandidate(add)
                    SendmailLamaranditinjau()
                    this.props.history.push(`/success-apply`)

                }else{
                    MySwal.fire({
                        icon: 'info',
                        title: 'Oops...',
                        text: 'Mohon maaf anda telah melamar pekerjaan ini !'
                    })
                }
            })

        }


    }

    getlistcity() {
        getCity().then(res => {
            const data = res['rajaongkir']['results'] ? res['rajaongkir']['results'] : []
            const options = data.map(d => ({
                "value": d.city_name,
                "label": d.city_name

            }))

            this.setState({ selectOptions: options })
        })
    }
    receivedData() {

        getjobListclient({ "ishome": false }).then(res => {
            const data = res['data'] ? res['data'] : [];
            const newdata = []
                for (let index = 0; index < data.length; index++) {
                    if(this.calculateTime(data[index]['expiredate']) === "Expired"){}
                    else
                    {
                        newdata.push({
                            "id":data[index]['id'],
                            "ishome":data[index]['ishome'],
                            "isapply":data[index]['isapply'],
                            "active":data[index]['active'],
                            "companyname":data[index]['companyname'],
                            "expiredate":data[index]['expiredate'],
                            "jobdescription":data[index]['jobdescription'],
                            "joblocation":data[index]['joblocation'],
                            "jobtitle":data[index]['jobtitle'],
                            "jobtype":data[index]['jobtype'],
                            "priority":data[index]['priority'],
                            "slug":data[index]['slug'],
                            "url":data[index]['url'],
                            "postdate":data[index]['postdate']

                        })

                    }
                    
                }
            
            const result = newdata.filter(item => (this.state.searchTitle ? item.jobtype === this.state.searchTitle : item.jobtype))
            const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
            const filterData = slice.filter(item => (this.state.searchTitle ? item.jobtype === this.state.searchTitle : item.jobtype))
            
            this.setState(
                {
                    data: [...filterData]
                }
            )
            if (data.length !== 0) {
                this.setState({
                    pageCount: Math.ceil(result.length / this.state.perPage),

                    filterData
                })
            } else {
                this.setState({
                    pageCount: 0,

                    filterData
                })
            }

        })
    }

    calculateTime = (key) => {
        const difference = +new Date(`${key}`) - +new Date();
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-"
        let datenow = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`

        var timeLeft
        if (datenow > key) {
            timeLeft = "Expired"
            return timeLeft;
        } else {
            timeLeft = Math.floor(difference / (1000 * 60 * 60 * 24))
            return timeLeft + 1 + ' Day';
        }

    };


    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    calculateTimeLeft = (key) => {
        const difference = +new Date(`${key}`) - +new Date();
        var timeLeft
        if (difference < 1000) {
            timeLeft = Math.floor(difference / (1000 * 60 * 60 * 24))
        }
        return Math.abs(timeLeft);
    };

    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let query = params.get('title');
        localStorage.removeItem('payloadjob')
        if (query !== null) {
            this.setState({ title: query })
        } else {
            this.props.history.push(`/job`)
        }
        setTitle('Job')
        setDescription('Kami berkomitmen untuk membangun komunitas tenaga kesehatan dengan selalu meningkatkan kualitas dan membantu tenaga kesehatan menemukan kesempatan kerja yang spesifik di dunia kesehatan.')
        this.receivedData()
        this.getlistcity()
    }


    render() {
        return (
            <div id="main-wrapper">
                <Headers />
                <Headermobile />
                <Menumobile />
                <Popupmenu />
                <BottomNavbarMobile menu="job" />
                <BottomNavbarMobilePopup />
                <div className="breadcrumb-section section bg_color--5 pt-100 pt-sm-100 pt-xs-100 pb-30 pb-sm-50 pb-xs-40">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-breadcrumb-content">
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Beranda</a></li>
                                        <li>Pekerjaan </li>
                                    </ul>
                                    <h1>Lowongan Pekerjaan</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="job-listing-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-lg-12 order-lg-2 order-1">
                                <div className="filter-form">
                                    <div className="result-sorting">
                                        <div className="form-left">
                                            <div className="sort-by">
                                                <form action="#">
                                                    <div className="form-group row">
                                                        <label className="col-sm-2 col-form-label" >Filter:</label>
                                                        <div className="col-sm-4" >
                                                            <select className="nice-select" name="searchTitle" value={this.state.searchTitle} onChange={this.onChange}>
                                                                <option value="">Semua</option>
                                                                <option value="Full-Time">Full-Time</option>
                                                                <option value="Part-Time">Part-Time</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-2 col-form-label" >&nbsp;</label>
                                                        <div className="col-sm-4">
                                                            <div className="text-left" style={{ width: "160px" }}>
                                                                <Select styles={customStyles} isClearable={true} maxMenuHeight={10} options={this.state.selectOptions} placeholder={<div>Semua Lokasi</div>} onChange={this.handleChange.bind(this)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div id="grid" className="tab-pane fade  show active">
                                        <div className="row">
                                            {this.state.data
                                                .sort((a, b) => a.priority > b.priority ? 1 : -1)
                                                .filter(one => (this.state.searchTitle ? one.jobtype === this.state.searchTitle : one.jobtype) && (one.active) && (this.state.title ? one.jobtitle.toLowerCase().indexOf(this.state.title.toLowerCase()) > -1 : one.jobtitle) && (this.state.joblocation ? one.joblocation === this.state.joblocation : one.joblocation))
                                                .map((one, index) => (
                                                    <div className="col-md-4 mb-20" key={index}>
                                                        {/* Single Job Start  */}
                                                        <div className="digi-job-grid">
                                                            <div className="digi-job-badge">
                                                                <span>{one.jobtype}</span>
                                                            </div>
                                                            <div className="job-info">
                                                                <div className="job-title">
                                                                    <a href={"jobs/" + one.slug}>{one.jobtitle}</a>
                                                                </div>
                                                                <div className="job-company">
                                                                    {one.companyname}
                                                                </div>
                                                                <div className="job-location">
                                                                    {one.joblocation}
                                                                </div>

                                                                <div className="job-action">
                                                                    {this.calculateTime(one.expiredate) !== "Expired" && one.isapply === true ? <button className="ht-btn transparent-btn-two w-100" onClick={this.onLamar.bind(this, one.id, one.jobtitle, one.companyname, one.joblocation, one.jobtype, one.psychotest)}>Lamar Sekarang</button> : null}
                                                                    <a href={"jobs/" + one.slug} className="read-more">Baca Selengkapnya <i className="lnr lnr-chevron-right" /></a>
                                                                </div>

                                                                <div className="job-meta">
                                                                    <div className="field-datetime">Ditayangkan pada : <Moment locale="id" fromNow>{one.postdate}</Moment></div>
                                                                    <div className="field-datetime">Lamar sebelum &nbsp;&nbsp;&nbsp; : <Moment locale="id" format="dddd, DD MMM YYYY">{one.expiredate}</Moment></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Single Job End */}
                                                    </div>
                                                ))}
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <ReactPaginate
                                                    previousLabel={<i className="fa fa-angle-left"></i>}
                                                    nextLabel={<i className="fa fa-angle-right"></i>}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={1}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={"page-pagination justify-content-center"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footers />
            </div>


        )
    }
}
