import React from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";

const NotFound = () => (
    <div id="main-wrapper" >
        <Headers />
        <Headermobile />
        <Menumobile />
        <BottomNavbarMobile menu="account" />
        <BottomNavbarMobilePopup />

        <div className="breadcrumb-section section bg_color--5 pt-60 pt-sm-50 pt-xs-40 pb-60 pb-sm-50 pb-xs-40">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="page-breadcrumb-content">
                            <h1>&nbsp;</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="login-register-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-lg-12">
                        <div className="error-404 not-found">
                            <div className="text-label-404">
                                <h1>oops !!! page not found </h1>
                            </div>
                            <div className="home-link">
                                <span>Maaf halaman yang anda tuju tidak di temukan  </span>
                                silahkan menghubungi customer service jika anda mengalami kendala<span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footers />
    </div>
);

export default NotFound;