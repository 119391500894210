import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import ReactPaginate from 'react-paginate';
import { NavLink } from 'react-router-dom';
import { getListbyuser } from "../../actions/eventActions";
const bucket = process.env.REACT_APP_API_KEY

const font = {
    fontSize: '12px',
}
const margin ={
    marginRight: "20px"
}

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            data: [],
            perPage: 25,
            currentPage: 0,
            searchTitle: '',
            searchTipe: '',
            searchActive: '',

        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.onChange = this.onChange.bind(this)

    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    onDetail(id, e) {
        let recent = [];
        recent.push({ 'id': id });
        localStorage.setItem('recent', JSON.stringify(recent));
        this.props.history.push(`/event_member`)
        window.location.reload(`/event_member`)
    }


    receivedData() {

        getListbyuser().then(res => {
            if (res.status !== 'success') {
                localStorage.removeItem('userInfo')
                this.props.history.push(`/login`)
            } else {
               
                    let newDate = new Date()
                    let date = newDate.getDate();
                    let month = newDate.getMonth() + 1;
                    let year = newDate.getFullYear();
                    let separator = "-"
                    let datenow = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
                    var datecheck = this.state.searchActive
                    var filterData
                    var result
                    const data = res['data'] ? res['data'] : [];
                    if (datecheck === "today") {
                        result = data.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type)
                            && (item.date === datenow))
                        const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type)
                            && (item.date === datenow))
                    } else if (datecheck === "yesterday") {

                        result = data.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type)
                            && (item.date < datenow))
                        const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type)
                            && (item.date < datenow))

                    } else if (datecheck === "tomorrow") {
                        result = data.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type)
                            && (item.date > datenow))
                        const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type)
                            && (item.date > datenow))

                    } else {

                        result = data.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type))
                        const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type))
                    }
                    var member = JSON.parse(localStorage.getItem('info'));
                    var idmember = member['id'];
                    const postData = filterData
                        .map(one => <React.Fragment>
                            <div className="col-md-6">
                                <div className="shadow p-3 mb-5 bg-white rounded">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <img src={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/event/"+one.banner} width={'100%'} height={'80%'} className="rounded" alt="Cinque Terre" />
                                            </div>
                                            <div className="col-md-7" style={{ fontSize: "10px" }}>
                                                <strong className="input-group mb-0">{one.title}</strong>
                                                <label className="input-group mb-0">{one.type} - {one.date}</label>
                                                <label className="input-group mb-0">Moderator : {one.moderator}</label>
                                                <label className="input-group mb-0">Pembicara : {one.speakers.length}</label>
                                                <label className="input-group mb-0">Nomor Pendaftaran :
                                                    {one.paticipans.map((value) =>
                                                        <div>
                                                            {value.idmember === idmember ?
                                                                <div className="productText">
                                                                    {value.nomorpendaftaran}
                                                                </div>
                                                                : null}
                                                            <br /></div>
                                                    )}</label>
                                                <label className="input-group mb-0"><button onClick={this.onDetail.bind(this, one.id)} className="btn btn-warning btn-sm"><i className="fas fa-file-contract"></i> Lihat Detail</button></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>)

                    this.setState({
                        pageCount: Math.ceil(result.length / this.state.perPage),

                        postData
                    })
                
            }
        })
    }
    Filter = (e) => {

        this.receivedData()
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo) {
            this.receivedData()
        }else{
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }
    render() {
        return (
            <div id="main-wrapper">
                <Headers />
                <Headermobile />
                <Menumobile />
                <BottomNavbarMobile />
                <BottomNavbarMobilePopup />
                <div className="dashboard-content-section section bg_color--5">
                    <div className="container-fluid p-0">
                        <div className="row no-gutters">
                            {/* <Menudashboard /> */}
                            <div className="col-xl-12 col-lg-9">
                                <div className="dashboard-main-inner">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="page-breadcrumb-content mb-40">
                                                <h4>&nbsp;</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashboard-overview">
                                        <div className="row">
                                            <div className="col-xl-12 col-12">
                                                <div className="profile-applications mb-50">
                                                    <div className="profile-applications-heading">
                                                        <ul className="nav">
                                                            <li key="1" style={margin}><NavLink to="/memberdetailprofile" style={font}>Profile</NavLink></li>
                                                            <li key="2" style={margin}><NavLink to="/daftar_activity_admin" style={font}>Aktivitas</NavLink></li>
                                                            <li key="3" style={margin}><NavLink to="/listaddress" style={font}>Alamat</NavLink></li>
                                                            <li key="4" style={margin}><NavLink to="/listpendidikan" style={font}>Pendidikan</NavLink></li>
                                                            <li key="5" style={margin}><NavLink to="/listpekerjaan" style={font}>Pekerjaan</NavLink></li>
                                                            <li key="6" style={margin}><NavLink to="/attachmentFile" style={font}>Lampiran</NavLink></li>
                                                        </ul>
                                                    </div>
                                                    <div className="applications-heading">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={this.state.searchTitle}
                                                                        name="searchTitle"
                                                                        placeholder="Kegiatan"
                                                                        onChange={this.onChange}
                                                                    />
                                                                </div></div>
                                                            <div className="col-md-4">
                                                                <div className="form-group row">
                                                                    <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Status</label>
                                                                    <div className="col-sm-9">
                                                                        <select className="form-control" name="searchActive" value={this.state.searchActive} onChange={this.onChange}>
                                                                            <option value="">Semua</option>
                                                                            <option value="today">Sedang Berlangsung</option>
                                                                            <option value="tomorrow">Akan Datang</option>
                                                                            <option value="yesterday">Sudah Selesai</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group row">
                                                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Tipe</label>
                                                                    <div className="col-sm-10">
                                                                        <select className="form-control" name="searchTipe" value={this.state.searchTipe} onChange={this.onChange}>
                                                                            <option value="">Semua</option>
                                                                            <option value="Seminar">Seminar</option>
                                                                            <option value="Webinar">Webinar</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <button className="btn btn-primary btn-sm btn-block" data-dismiss="modal" aria-label="Close" onClick={this.Filter.bind(this)}><i className="fas fa-search"></i> Filter</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="job-applications-main-block">
                                                        <div className="container">
                                                            <br />
                                                            <div className="row">
                                                                {this.state.postData}
                                                            </div>
                                                            <ReactPaginate
                                                                previousLabel={<i className="fa fa-angle-left"></i>}
                                                                nextLabel={<i className="fa fa-angle-right"></i>}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={this.state.pageCount}
                                                                marginPagesDisplayed={1}
                                                                onPageChange={this.handlePageClick}
                                                                containerClassName={"page-pagination justify-content-center"}
                                                                subContainerClassName={"pages pagination"}
                                                                activeClassName={"active"} />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footers />
            </div>


        )
    }
}
