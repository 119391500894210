import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { setTitle, setDescription } from "../../actions/userActions";



export default class App extends Component {
  componentDidMount() {
    setTitle('Privasi dan Kerahasiaan')
    setDescription('Kami berkomitmen untuk membangun komunitas tenaga kesehatan dengan selalu meningkatkan kualitas dan membantu tenaga kesehatan menemukan kesempatan kerja yang spesifik di dunia kesehatan.')
  }
  render() {
    return (
      <div id="main-wrapper">
        <Headers />
        <Headermobile />
        <Menumobile />
        <BottomNavbarMobile />
        <BottomNavbarMobilePopup />
        <div className="breadcrumb-section section bg_color--5 pt-60 pt-sm-50 pt-xs-40 pb-30 pb-sm-50 pb-xs-40">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-breadcrumb-content">
                  {/* <ul className="page-breadcrumb">
                    <li><a href="/">Beranda</a></li>
                    <li>police </li>
                  </ul> */}
                  <br/><br/>
                  <h1>Privasi dan Kerahasiaan</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="job-listing-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-12 order-lg-2 order-1">
                <div className="tab-content">
                  <div id="grid" className="tab-pane fade  show active">
                    <div className="row">
                      <div className="single-job-grid">

                        <p><strong>Pengumpulan Informasi Pengguna</strong></p>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '11pt', padding: '12pt 0pt 0pt 0pt' }}><span >DigiHealth akan mengumpulkan informasi-informasi Pengguna. Pengguna yang terus menggunakan layanan DigiHealth maka secara langsung memberikan persetujuannya untuk memberikan informasi-informasi tersebut. Pengumpulan informasi ini dilakukan DigiHealth pada saat Pengguna:</span></p>
                        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Mendaftarkan diri sebagai anggota DigiHealth saat melakukan transaksi dalam DigiHealth</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Melengkapi profil anggota DigiHealth</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Berpartisipasi pada survey kesehatan DigiHealth</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Berpartisipasi pada kuis-kuis kesehatan DigiHealth</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '12pt' }}><span >Mengunggahnya dalam bentuk konten lain ke DigiHealth</span></p>
                          </li>
                        </ul>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt', padding: '0pt 0pt 11pt 0pt' }}><span >Selain dari informasi-informasi pribadi Pengguna, DigiHealth juga dapat mengumpulkan informasi-informasi yang tidak secara langsung terkait dengan Pengguna melalui tapi tidak terbatas pada kuki HTTP, aplikasi bergerak milik DigiHealth, sekalipun Pengguna belum mendaftar sebagai anggota DigiHealth.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt', padding: '1pt 0pt 0pt 0pt' }}><strong >Jenis Informasi</strong></p>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '11pt', padding: '12pt 0pt 0pt 0pt' }}><span >Informasi yang dikumpulkan oleh DigiHealth adalah berupa tapi tidak terbatas pada informasi-informasi yang tertulis di bawah ini:</span></p>
                        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Data diri Pengguna: Nama, Jenis Kelamin, Usia, Status Perkawinan, Alamat, Nomor Telepon, Alamat Email, Pekerjaan.</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Data pemesanan sediaan farmasi, alat kesehatan: Nama sediaan farmasi, nama alat kesehatan, diagnosis, nama dokter yang meresepkan sediaan farmasi atau alat kesehatan.</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Data diri pasien: Nama, Jenis Kelamin, Usia, Status Perkawinan, Alamat, Nomor Telepon, Alamat Email, Pekerjaan, diagnosis penyakit</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Data kesehatan Pengguna: Riwayat Penyakit Sekarang, Riwayat Penyakit Dahulu, Riwayat Alergi, Hasil Laboratorium, Tinggi Badan, Berat Badan, Riwayat Imunisasi, Gaya Hidup, Aktivitas Sehari-hari.</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '12pt' }}><span >Data lainnya yang adalah untuk keperluan analisa statistik DigiHealth atau pihak ketiga yang bekerja sama dengan DigiHealth. Pengumpulan data jenis ini tidak selalu dilakukan karena hanya bersifat sementara.</span></p>
                          </li>
                        </ul>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt', padding: '12pt 0pt 0pt 0pt' }}><strong>Penggunaan Informasi Pengguna</strong></p>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '11pt', padding: '12pt 0pt 0pt 0pt' }}><span >DigiHealth akan menggunakan informasi-informasi yang didapat dari Pengguna untuk perbaikan dan perbaharuan layanan DigiHealth, yaitu untuk beberapa tapi tidak terbatas pada hal-hal yang tercantum di bawah ini:</span></p>
                        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Mengirimkan Pengguna informasi terbaru terkait minat, resiko kesehatan termasuk juga tapi tidak terbatas, pemberitahuan, berita terkini, pengumuman, melalui email atau aplikasi mobile atau sistem pesan elektronik lainnya.</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Mengirimkan Pengguna promosi Produk terbaru DigiHealth, pemberitahuan DigiHealth, berita terkini, pengumuman DigiHealth, yang dilakukan melalui email atau aplikasi mobile atau sistem pesan elektronik lainnya.</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Mengirimkan Pengguna konten Kesehatan terkait minat, resiko kesehatan, rekam atau catatan pembelian Produk sebelumnya yang dilakukan melalui email atau aplikasi mobile atau sistem pesan elektronik lainnya.</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Administrasi akun Pengguna</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Membuka akses Pengguna untuk beberapa fitur dan layanan DigiHealth</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Memberikan respon terhadap pertanyaan-pertanyaan Pengguna</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Berkomunikasi dengan Pengguna mengenai masalah administrasi akun Pengguna</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Mendapatkan kritik dan masukan dari Pengguna terkait fitur dan layanan yang disajikan DigiHealth</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Analisa statistik terhadap perilaku Pengguna terkait penggunaan layanan dan fitur DigiHealth</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p ><span >Memberikan konten yang lebih relevan untuk Pengguna sesuai dengan resiko kesehatan Pengguna</span></p>
                          </li>
                          <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc' }}>
                            <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '12pt' }}><span >Melakukan penelitian dan pengukuran terhadap aktivitas Pengguna dalam DigiHealth</span></p>
                          </li>
                        </ul>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt', padding: '0pt 0pt 11pt 0pt' }}><span >DigiHealth TIDAK membagikan informasi-informasi Pengguna DigiHealth kepada pihak ketiga manapun kecuali dalam bentuk laporan statistik yang tidak menuliskan informasi-informasi Pengguna secara spesifik.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt', padding: '1pt 0pt 11pt 0pt' }}><span >DigiHealth menjamin keamanan dari basis data yang mengandung informasi-informasi Pengguna DigiHealth baik secara teknis maupun secara keamanan administrasi seperti perlindungan keamanan dengan kata sandi.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt', padding: '1pt 0pt 11pt 0pt' }}><span >DigiHealth juga memberikan kesempatan kepada Pengguna untuk melakukan perlindungan terhadap informasi-informasi pribadinya dengan menggunakan kata sandi. Penggunaan kata sandi ini adalah sepenuhnya tanggung jawab Pengguna. Adanya kelalaian Pengguna yang mengakibatkan diketahuinya kata sandi ini oleh Pengguna lain atau entitas lain yang menyebabkan peretasan akun Pengguna, maka DigiHealth termasuk semua karyawan yang bekerja di DigiHealth dan Atoma tidak bertanggung jawab dan berhak untuk sepenuhnya dibebaskan dari segala tuntutan dan ganti rugi yang terjadi di kemudian hari.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt', padding: '1pt 0pt 11pt 0pt' }}><span >Semua personel DigiHealth dan Atoma WAJIB untuk merahasiakan semua informasi Pengguna DigiHealth dan DILARANG untuk membocorkan kerahasiaan ini kepada pihak manapun atas alasan apapun kecuali dalam hal keperluan penyidikan hukum.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt', padding: '1pt 0pt 11pt 0pt' }}><span >Syarat dan ketentuan yang tertulis dalam bab Privasi dan Kerahasiaan dapat diubah atau diperbaharui oleh DigiHealth atau Atoma tanpa sebelumnya memberitahukan kepada Pengguna. Pada saat terdapat pembaharuan atau perubahan atas syarat dan ketentuan, maka syarat dan ketentuan yang berlaku adalah yang semua syarat dan ketentuan yang tertulis pada dokumen terkini. Oleh karena itu, Pengguna berkewajiban untuk terus memperbaharui informasi mengenai syarat dan ketentuan yang berlaku dengan secara rutin membuka laman dokumen Syarat dan Ketentuan pada DigiHealth. Apabila Pengguna tetap menggunakan DigiHealth dan fitur-fiturnya maka secara langsung Pengguna sudah menyetujui untuk tunduk pada perubahan atas syarat dan ketentuan terkait Privasi dan Kerahasiaan.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt', padding: '1pt 0pt 0pt 0pt' }}><strong >Hukum yang Berlaku</strong></p>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt', padding: '12pt 0pt 11pt 0pt' }}><span >Terhadap Ketentuan ini berlaku hukum negara Republik Indonesia. Setiap akses terhadap Website yang dilakukan dari yurisdisksi selain Republik Indonesia wajib tunduk pada hukum negara Republik Indonesia. Pengguna yang mengakses dari lokasi diluar yurisdiksi Republik Indonesia juga wajib, dengan inisiatif Pengguna sendiri, memastikan dan bertanggung jawab agar akses Pengguna terhadap Platform serta pelaksanaan Ketentuan tidak melanggar serta patuh terhadap ketentuan peraturan perundang-undangan dan hukum yang berlaku di yurisdiksi di lokasi tersebut.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt', padding: '1pt 0pt 0pt 0pt' }}><strong >Penyelesaian Sengketa</strong></p>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt', padding: '12pt 0pt 11pt 0pt' }}><span >Setiap perselisihan terkait pelaksanaan Ketentuan ini kedua belah pihak sepakat menyelesaikannya secara muswarah mufakat. Apabila tidak dapat diselesaikan juga, kedua belah pihak dengan ini sepakat menyelesaikannya melalui Kantor Kepaniteraan Pengadilan Negeri Jakarta Selatan.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt', padding: '1pt 0pt 12pt 0pt' }}><span >Pengguna dengan ini sepakat bahwa Pengguna tidak lagi berhak mengajukan gugatan, permohonan atau klaim apapun kepada DigiHealth atau lembaga peradilan manapun terkait Ketentuan ini setelah lewat waktu 30 (tiga puluh hari) kalender sejak (i) timbulnya peristiwa/perbuatan yang Pengguna jadikan dasar untuk mengajukan gugatan atau klaim tersebut; atau (ii) terjadinya serah terima Produk dari DigiHealth atau Mitra kepada Pengguna.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '12pt' }}><span >&nbsp;</span></p>


                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footers />
      </div>


    )
  }
}
