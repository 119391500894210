import React, { Component } from 'react';
import { getListfooterclient, getListcmsclient } from "../actions/footermanajemenAction";

export default class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            level1: [],
            master: [],
            masterurl:[]
        };
    }

    componentDidMount() {
        this.receivedData();
    }

    receivedData() {
        getListfooterclient().then(res => {
            const data = res['data'] ? res['data'] : [];
            const level1 = data.filter(item => (item.level1 === true) && (item.active === true))
            const master = data.filter(item => (item.active === true))
            this.setState({ level1: [...level1], master: [...master] })

        })

        getListcmsclient().then(res => {
            const datas = res['data'] ? res['data'] : [];
            this.setState({ masterurl: [...datas] })
        })
    }

    checkcms(key) {
        var data = this.state.masterurl.filter(item => (key ? item.id === key : []))
         if(data.length !== 0){

             return data[0]['url'];
         }

        

    }

    render() {
        return (
            <footer className="footer-section section bg_color--1">
                {/* Footer Top Section Start */}
                <div className="footer-top-section section st-border pt-120 pt-lg-100 pt-md-80 pt-sm-60 pt-xs-50 pb-80 pb-lg-60 pb-md-40 pb-sm-20 pb-xs-15">
                    <div className="container">
                        <div className="row container">
                            <div className="col-xl-5 col-lg-5 col-md-6">
                                {/* Footer Widget Start */}
                                <div className="footer-widget mb-30">
                                    <div className="footer-logo">
                                        <a href="/"><img src="/assets/images/logo-digihealth.png" width="40%" alt="footer" /></a>
                                    </div>
                                    <div className="address">
                                        <i className="lnr lnr-map-marker" />
                                        <span style={{ width: "300px" }}>PT Akses Digital Kesehatan<br/>
                                        Gedung 18 Office Park 25th Fl, Suite A2<br/>
                                        Jl. TB Simatupang Kav.18, Jakarta 12430</span>
                                    </div>
                                    <div className="email">
                                        <i className="lnr lnr-envelope" />
                                        <span>info@digihealth.id</span>
                                    </div>
                                    <div className="phone theme-color fz-17"><a href="https://api.whatsapp.com/send/?phone=62811991910&text=Hi+Digihealth.id,+Saya+mau+bertanya">0811-991-910</a></div>
                                </div>
                                {/* Footer Widget End */}
                            </div>
                            <div className="col-xl-7 col-lg-7 col-md-7">
                            <div className="row">
                            {this.state.level1
                                .sort((a, b) => a.order > b.order ? 1 : -1)
                                .map(function (one, induk) {
                                    return <>
                                        <div className="col-xl-4 col-lg-4 col-md-6" key={induk}>
                                            {/* Footer Widget Start */}
                                            <div className="footer-widget mb-30">
                                                <h6 className="title">{one.title}</h6>
                                                <div className="footer-widget-link">
                                                    {this.state.master
                                                        .filter(two => two.level2 === true && two.parent === one.id)
                                                        .map(function (two, index) {
                                                            return <>
                                                                <ul key={index}>
                                                                    <li>
                                                                    {two.url !== "Empty" ? <><a href={two.url}>{two.title} </a></>: null }
                                                                    {two.page !== "Empty" ? <><a href={this.checkcms(two.page)}>{two.title}</a></>: null }
                                                                    </li>
                                                                </ul>
                                                            </>
                                                        }.bind(this)
                                                        )}
                                                </div>
                                            </div>
                                            {/* Footer Widget End */}
                                        </div>
                                    </>
                                }.bind(this))}
                                </div>
                        </div>
                        </div>
                    </div>
                </div>
                {/* Footer Top Section End */}
                {/*Footer bottom start*/}
                <div className="footer-bottom section fb-60">
                    <div className="container">
                        <div className="row no-gutters st-border pt-35 pb-35 align-items-center justify-content-between">
                            <div className="col-lg-12 col-md-12 d-flex justify-content-center">
                                <div className="copyright">
                                    <p>©2021 <a href="https://digihealth.id">Digihealth.id - PT. Akses Digital Kesehatan</a>. All rights reserved.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/*Footer bottom end*/}
            </footer>


        )
    }
}
