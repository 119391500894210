import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { getjobListclient } from "../../actions/jobAction";

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
           jobtitle:"",
           company:"",
           type:"",
           location:""
        };
    }
    componentDidMount() {

        var jobid = JSON.parse(localStorage.getItem('payloadjob'));
        if(jobid !== null)
        {
            this.receivedData(jobid[0]['id'])
        }else{
            this.props.history.push(`/job`)
        }

    }

    receivedData(id) {

        getjobListclient({"ishome": false}).then(res => {
            const data = res['data'] ? res['data'] : [];
            var filterData = data.filter(one => (one.id === id))
            if(filterData.length === 0){
                this.props.history.push(`/job`)
            }else{
                this.setState({jobtitle: filterData[0]['jobtitle'], company: filterData[0]['companyname'], type: filterData[0]['jobtype'], location:filterData[0]['joblocation'] })
            }

        })

    }


    render() {
        return (
            <>
                <div id="main-wrapper" >
                    <Headers />
                    <Headermobile />
                    <Menumobile />
                    <BottomNavbarMobile menu="account" />
                    <BottomNavbarMobilePopup />

                    <div className="breadcrumb-section section bg_color--5 pt-60 pt-sm-50 pt-xs-40 pb-60 pb-sm-50 pb-xs-40">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-breadcrumb-content">
                                        <h1>&nbsp;</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="login-register-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
                        <div className="container">
                            <div className="row no-gutters">
                                <div className="col-lg-12">
                                    <div className="error-404 not-found">
                                        <img src="/assets/images/success.png" style={{ height: "300px" }} alt="success" />
                                        <div className="text-label-404">
                                            <h1>Lamaran Ditinjau</h1>
                                        </div>
                                        <div className="text-label-404">
                                            <h3>Hi <strong> {JSON.parse(localStorage.getItem('info')).gelardepan ? JSON.parse(localStorage.getItem('info')).gelardepan + '.' : ''} {JSON.parse(localStorage.getItem('info')).name}{', ' + JSON.parse(localStorage.getItem('info')).gelarbelakang} </strong></h3>
                                            <br />
                                            <h5>Terima kasih karena telah melamar pada posisi:</h5>
                                            <h6>{this.state.jobtitle}</h6>
                                            <h6>{this.state.company} - {this.state.location}</h6>
                                            <h6>{this.state.type}</h6>
                                        </div>
                                        <div className="home-link">
                                            <span>Saat ini lamaran Anda sedang kami tinjau, untuk hasil peninjauan lamaran Anda akan kami
                                                beritahukan paling lama 14 hari kerja sejak tanggal Anda mengajukan lamaran.
                                            </span>
                                        </div>
                                        <a href="/job" className="ht-btn transparent-btn-two" style={{width:"200px"}} >Lihat Semua Lowongan </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footers />
                </div>
            </>

        )
    }
}
