import {
    PROFESSION_LIST_FAIL,
    PROFESSION_LIST_REQUEST,
    PROFESSION_LIST_SUCCESS,
    PROFESSION_CREATE_FAIL,
    PROFESSION_CREATE_REQUEST,
    PROFESSION_CREATE_SUCCESS
  } from "../constants/professionConstants";
  import axios from "axios";
  
  export const listProfessions = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: PROFESSION_LIST_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          Authorization: `${userInfo.data.token}`,
        },
      }; 
  
      const {data} = await axios.get(`/api/v1/professions`, config);
      
      dispatch({
        type: PROFESSION_LIST_SUCCESS,
        payload: data['data'],
      });
  
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: PROFESSION_LIST_FAIL,
        payload: message,
      });
    }
  };

  
export const getList = () => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
      .get('/api/v1/professions', {
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      })
      .then(res => {
          res.data.status = 'success'
          return res.data
      }).catch(err => {
          return {
              error:'Please login again!',
              status:'failed',
              message:err.message
          }
      })
}
  
export const addToList = profession => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .post(
      '/api/v1/professions/add',
      {
        level1: profession.level1,
        level2: profession.level2,
        level3: profession.level3,
        name: profession.name,
        Parent: profession.Parent,
        code: profession.code,
        order: profession.order,
        Active: profession.Active
      },
      {
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': token['data']['token']
        }
      }
    )
    .then(function(response) {
        return response.data;
    }).catch(err => {
        return {
            error:'Error to add',
            status:'failed',
            message:err.message
        }
    })
}


export const updateItem = UpdateRequest => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .put(
      `/api/v1/professions/update/${UpdateRequest.id}`,
      {
        nama: UpdateRequest.nama,
        level1: UpdateRequest.level1,
        level2: UpdateRequest.level2,
        level3: UpdateRequest.level3,
        name: UpdateRequest.name,
        Parent:UpdateRequest.Parent,
        code: UpdateRequest.code,
        order:UpdateRequest.order,
        Active: UpdateRequest.Active
      },
      {
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': token['data']['token']
        }
      }
    )
    .then(function(response) {
        return response.data;
    })
}


export const deleteItem = (id) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .delete(`/api/v1/professions/delete/${id}`, {
      headers: { 
            'Content-Type': 'application/json',
            'Authorization': token['data']['token']
        }
    })
    .then(function(response) {
      return response.data;
  }).catch(err => {
      return {
          error:'Error to add',
          status:'failed',
          message:err.message
      }
  })
}
  
  export const createProfessionAction = (level1, level2, level3, name, Parent, code, order) => async (
    dispatch,
    getState
  ) => {
    try {
      dispatch({
        type: PROFESSION_CREATE_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userInfo.data.token}`,
        },
      };
  
      const { data } = await axios.post(
        `/api/v1/professions/add`,
        {level1, level2, level3, name, Parent, code, order},
        config
      );
  
      dispatch({
        type: PROFESSION_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: PROFESSION_CREATE_FAIL,
        payload: message,
      });
    }
  };

