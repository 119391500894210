import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Headermenu";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
// import Menudashboard from "../../components/MenuDashboard";
import ReactPaginate from 'react-paginate';
import { getList } from "../../actions/userActions";

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            data: [],
            perPage: 25,
            currentPage: 0,
            searchTitle: '',
            searchActive: '',
            isUpdate: false,

        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.onChange = this.onChange.bind(this)
        this.onEdit = this.onEdit.bind(this)
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    receivedData() {
        getList().then(res => {
            if (res.status !== 'success') {
                localStorage.removeItem('userInfo')
                this.props.history.push(`/login`)
            } else {

                const data = res['data'];
                const result = data.filter(item => (this.state.searchTitle ? item.name === this.state.searchTitle : item.name) || (this.state.searchTitle ? item.email === this.state.searchTitle : item.email))
                const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                const filterData = slice.filter(item => (this.state.searchTitle ? item.name === this.state.searchTitle : item.name) || (this.state.searchTitle ? item.email === this.state.searchTitle : item.email))
                const postData = filterData
                    .map((one, index) => (

                        <tr className="application-item">
                            <td>{one.name}</td>
                            <td>{one.email}</td>
                            <td>{one.gelardepan}</td>
                            <td>{one.gelarbelakang}</td>
                            <td>{one.tlp}</td>
                            <td>{one.profesi}</td>
                            <td>{one.spesialisasi}</td>
                            <td>{one.consltant}</td>
                            <td>  <button onClick={this.onEdit.bind(this, one.id, one.name, one.gelardepan, one.gelarbelakang, one.tlp, one.nip, one.str, one.npkidi, one.nik, one.tempatlahir, one.tanggallahir, one.profesi, one.spesialisasi, one.consltant, one.email, one.address, one.education, one.jobs)} style={{ width: '30px' }} className="btn btn-warning btn-sm btn-block"><i className="fas fa-edit"></i></button></td>
                        </tr>
                    ))

                this.setState({
                    pageCount: Math.ceil(result.length / this.state.perPage),

                    postData
                })
            }
        });
    }
    Filter = (e) => {

        this.receivedData()
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    componentDidMount() {
        this.receivedData()
    }

    onEdit(id, name, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, email, address, education, jobs, e) {
        let recent = [];
        recent.push({
            'id': id, 'name': name, 'gelardepan': gelardepan, 'gelarbelakang': gelarbelakang, 'tlp': tlp, 'str': str, 'nip': nip, 'npkidi': npkidi, 'nik': nik, 'tempatlahir': tempatlahir, 'tanggallahir': tanggallahir,
            'profesi': profesi, 'spesialisasi': spesialisasi, 'consltant': consltant, 'email': email, 'address': address, 'education': education, 'jobs': jobs
        });
        localStorage.setItem('recentmember', JSON.stringify(recent));
        this.props.history.push(`/memberdetailprofile`)

    }

    render() {
        return (
            <div id="main-wrapper">
                <Headers />
                <Headermobile />
                <Menumobile />
                <BottomNavbarMobile />
                <BottomNavbarMobilePopup />
                <div className="dashboard-content-section section bg_color--5">
                    <div className="container-fluid p-0">
                        <div className="row no-gutters">
                            {/* <Menudashboard /> */}
                            <div className="col-xl-12 col-lg-9">
                                <div className="dashboard-main-inner">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="page-breadcrumb-content mb-40">
                                                <h4>Member Management</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashboard-overview">
                                        <div className="row">
                                            <div className="col-xl-12 col-12">
                                                <div className="submited-applications mb-50">
                                                    <div className="applications-heading">
                                                        <h3>List Member</h3>

                                                    </div>
                                                    <div className="row applications-heading">
                                                       
                                                        <div className="col-md3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1"></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={this.state.searchTitle}
                                                                    name="searchTitle"
                                                                    placeholder="enter name"
                                                                    onChange={this.onChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Profession</label>
                                                                <select className="form-control" name="searchActive" value={this.state.searchActive} onChange={this.onChange}>
                                                                    <option value="">Semua</option>
                                                                    <option value="true">Aktif</option>
                                                                    <option value="false">Non Aktif</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Specialitation</label>
                                                                <select className="form-control" name="searchActive" value={this.state.searchActive} onChange={this.onChange}>
                                                                        <option value="">Semua</option>
                                                                        <option value="true">Aktif</option>
                                                                        <option value="false">Non Aktif</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Sub Specialitation</label>
                                                                <select className="form-control" name="searchActive" value={this.state.searchActive} onChange={this.onChange}>
                                                                        <option value="">Semua</option>
                                                                        <option value="true">Aktif</option>
                                                                        <option value="false">Non Aktif</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1"></label>
                                                                <button className="btn btn-primary btn-sm btn-block" data-dismiss="modal" aria-label="Close" onClick={this.Filter.bind(this)}><i className="fas fa-search"></i> Filter</button>

                                                            </div>
                                                        </div>

                                                       

                                                    </div>

                                                    <div className="job-applications-main-block">
                                                        <div className="job-applications-table">
                                                            <table className="table" id="tree-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Email</th>
                                                                        <th>Gelar Depan</th>
                                                                        <th>Gelar Belakang</th>
                                                                        <th>Phone Number</th>
                                                                        <th>Profession</th>
                                                                        <th>Speciality</th>
                                                                        <th>Sub-Speciality</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.postData}
                                                                </tbody>
                                                            </table>
                                                            <ReactPaginate
                                                                previousLabel={<i className="fa fa-angle-left"></i>}
                                                                nextLabel={<i className="fa fa-angle-right"></i>}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={this.state.pageCount}
                                                                marginPagesDisplayed={1}
                                                                onPageChange={this.handlePageClick}
                                                                containerClassName={"page-pagination justify-content-center"}
                                                                subContainerClassName={"pages pagination"}
                                                                activeClassName={"active"} />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footers />
            </div>


        )
    }
}
