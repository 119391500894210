import {
    EVENT_UPDATE_FAIL,
    EVENT_UPDATE_REQUEST,
    EVENT_UPDATE_SUCCESS,
    EVENT_CREATE_FAIL,
    EVENT_CREATE_REQUEST,
    EVENT_CREATE_SUCCESS,
    EVENT_LIST_FAIL,
    EVENT_LIST_REQUEST,
    EVENT_LIST_SUCCESS
  } from "../constants/eventConstants";

  export const eventListReducer = (state = { events : [] }, action) => {
    switch (action.type) {
      case EVENT_LIST_REQUEST:
        return { loading: true };
      case EVENT_LIST_SUCCESS:
        return { loading: false, events: action.payload };
      case EVENT_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const eventCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case EVENT_CREATE_REQUEST:
        return { loading: true };
      case EVENT_CREATE_SUCCESS:
        return { loading: false, success: true };
      case EVENT_CREATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const eventUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case EVENT_UPDATE_REQUEST:
        return { loading: true };
      case EVENT_UPDATE_SUCCESS:
        return { loading: false, userInfo: action.payload, success: true };
      case EVENT_UPDATE_FAIL:
        return { loading: false, error: action.payload, success: false };
      default:
        return state;
    }
  };
