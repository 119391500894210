import { BrowserRouter as Router, Route } from "react-router-dom";
 
import Home from "./screens/Home/index";
import Login from "./screens/Auth/index";
// import Dashboard from "./screens/Dashboard/index";
import Forgetpassword from "./screens/Auth/forgetpassword";
import Resetpassword from "./screens/Auth/resetpassword";
import Eventlist from "./screens/Event/list";
import Professionlist from "./screens/Profession/list";
import Memberlist from "./screens/Member/list";
import MemberDetailProfile from "./screens/Member/detail_profile";
import MemberDetailaddressadmin from "./screens/Member/detail_address";
import DetailPendidikan from "./screens/Member/daftar_pendidikan";
import DetailPekerjaan from "./screens/Member/daftar_pekerjaan";
import AttachmentFile from "./screens/Member/attachmentFile";
import Jobclient from "./screens/Job/job_client";
import JobSuccess from "./screens/Job/success_apply";
import JobHistory from "./screens/Member/history_job";
import Eventclient from "./screens/Event/event_client";
import Eventdetail from "./screens/Event/detail";
import Eventdetailnew from "./screens/Event/detail_v2";
import Daftar_activity_admin from "./screens/Member/daftar_activity";
import Eventdetailadmin from "./screens/Member/event_detail";
import Eventregister from "./screens/Event/event_register";
import Certificate from "./screens/Member/certificate";
import Termsandconditions from "./screens/Termsandconditions/index";
import Policypivacy from "./screens/Policypivacy/index";
import Jobdetail from "./screens/Job/detail_job";
import Jobdetailnew from "./screens/Job/detail_job_v2";
import Verifikasiemail from "./screens/Verifikasi/index";
import Faq from "./screens/Faq/index";
import Aboutus from "./screens/Aboutus/index";
import Calltoaction from "./screens/Member/profile_mobile";
import NotFound from './screens/404/index';
import Downloadlink from "./screens/Event/download";
import Detailcms from "./screens/Footer_manajemen/index";
import { Switch } from 'react-router-dom';


function App() {
  

    return (
    <Router> 
       <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/home" component={Home} exact />
          <Route path="/login" component={Login} exact />
          {/* <Route path="/dashboard" component={Dashboard}  /> */}
          <Route path="/forgetpassword" component={Forgetpassword}  />
          <Route path="/resetpassword" component={Resetpassword}  />
          <Route path="/eventlist" component={Eventlist}  />
          <Route path="/professionlist" component={Professionlist}  />
          <Route path="/memberlist" component={Memberlist}  />
          <Route path="/memberdetailprofile" component={MemberDetailProfile}  />
          <Route path="/job" component={Jobclient}  />
          <Route path="/success-apply" component={JobSuccess}  />
          <Route path="/riwayat-lamaran" component={JobHistory}  />
          <Route path="/event" component={Eventclient}  />
          <Route path="/event_detail" component={Eventdetail}  />
          <Route path="/daftar_activity_admin" component={Daftar_activity_admin}  />
          <Route path="/event_member" component={Eventdetailadmin}  />
          <Route path="/listaddress" component={MemberDetailaddressadmin}  />
          <Route path="/event_register" component={Eventregister}  />
          <Route path="/certificate" component={Certificate}  />
          <Route path="/termsandconditions" component={Termsandconditions}  />
          <Route path="/policypivacy" component={Policypivacy}  />
          <Route path="/jobdetail" component={Jobdetail}  />
          <Route path="/verifikasiemail" component={Verifikasiemail}  />
          <Route path="/faq" component={Faq}  />
          <Route path="/tentangkami" component={Aboutus}  />
          <Route path="/profile" component={Calltoaction}  />
          <Route path="/listpendidikan" component={DetailPendidikan}  />
          <Route path="/listpekerjaan" component={DetailPekerjaan}  />
          <Route path="/attachmentFile" component={AttachmentFile} />
          <Route path="/downloadlink" component={Downloadlink} />
          <Route path="/events/:slug" component={Eventdetailnew}/>
          <Route path="/jobs/:slug" component={Jobdetailnew}/>
          <Route path="/:id" component={Detailcms} />
          <Route component={NotFound} />

      </Switch>
    </Router>
  );
}

export default App;
