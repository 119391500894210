import React, { useEffect, useState } from "react";
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { useSelector, useDispatch } from "react-redux";
import { updateUserAction } from "../../actions/userActions";
import ErrorMessage from "../../components/ErrorMessage";
import { NavLink } from 'react-router-dom';
const font = {
    fontSize: '12px',
}
const margin ={
    marginRight: "20px"
}

function Edit({ history }) {
    const dispatch = useDispatch();
    const [title, setTitle] = useState("");
    const [index, setIndex] = useState(0);
    const [name, setName] = useState("");
    const [gelardepan, setGelardepan] = useState("");
    const [gelarbelakang, setGelarbelakang] = useState("");
    const [tlp, setTlp] = useState("");
    const [nip, setNip] = useState("");
    const [email, setEmail] = useState("");
    const [str, setStr] = useState("");
    const [npkidi, setNpkidi] = useState("");
    const [nik, setNik] = useState("");
    const [tempatlahir, setTempatlahir] = useState("");
    const [tanggallahir, setTanggallahir] = useState("");
    const [profesi, setProfesi] = useState("");
    const [spesialisasi, setSpesialisasi] = useState("");
    const [consltant, setConsltant] = useState("true");
    const [address, setAddress] = useState([]);
    const [education, setEducation] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [jobslist, setJobslist] = useState([]);
    const [sertifikat, setSertifikat] = useState([{ name: "", file: "-" }]);
    const [cv, setCv] = useState("");
    const [strfile, setStrfile] = useState("");
    const [bank, setBank] = useState("");
    const [profile, setProfile] = useState("");
    const userUpdate = useSelector((state) => state.userUpdate);
    const userLogin = useSelector((state) => state.userLogin);
    
    const [mulai, setMulai] = useState("");
    const [berhenti, setBerhenti] = useState("");
    const [institusi, setInstitusi] = useState("");
    const [jabatan, setJabatan] = useState("");

  
    const {
        // loading: loadingUpdate,
        error: errorUpdate,
        success: successUpdate,
    } = userUpdate;
    const { userInfo } = userLogin;
    useEffect(() => {
       
        if (userInfo) {
            history.push("/listpekerjaan");
            var prod = JSON.parse(localStorage.getItem('info'));
            setName(prod['name']);
            setGelardepan(prod['gelardepan']);
            setGelarbelakang(prod['gelarbelakang']);
            setTlp(prod['tlp']);
            setEmail(prod['email']);
            setNip(prod['nip']);
            setStr(prod['str']);
            setNpkidi(prod['npkidi']);
            setNik(prod['nik']);
            setTempatlahir(prod['tempatlahir']);
            setTanggallahir(prod['tanggallahir']);
            setProfesi(prod['profesi']);
            setSpesialisasi(prod['spesialisasi']);
            setConsltant(prod['consltant']);
            setProfile(prod['profile']);
            if(prod['address'].length !== 0){
                setAddress(prod['address']);
            }
            if(prod['education'].length !== 0){
                setEducation(prod['education']);
            }
            if(prod['jobs'].length !== 0){
                setJobs(prod['jobs']);
                setJobslist(prod['jobs']);
            }
            if(prod['cv'] !== undefined){
                setCv(prod['cv']);
            }
            if(prod['strfile'] !== undefined){
                setStrfile(prod['strfile']);
            }
            if(prod['bank'] !== undefined){
                setBank(prod['bank']);
            }
            if(prod['sertifikat'] !== null ){
                setSertifikat(prod['sertifikat']);
            }
        }else{
            history.push("/login");

        }
    }, [
        history,
        userInfo,
        successUpdate,
    ]);

    const resetHandler = () => {
        setMulai("");
        setBerhenti("");
        setInstitusi("");
        setJabatan("");

    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateUserAction( name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs, strfile, bank, cv, sertifikat, profile ));
        if (!name) return;
        resetHandler();
        history.push("/listpekerjaan");
        // window.location.reload()
    };

    const updateHandler = (e) => {
        e.preventDefault();
        const list = [...jobs];
        list[index]['mulai'] = mulai;
        list[index]['berhenti'] = berhenti;
        list[index]['institusi'] = institusi;
        list[index]['jabatan'] = jabatan;
        setJobs(list);
        dispatch(updateUserAction( name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs, profile ));
        resetHandler();
        history.push("/listpekerjaan");
    };


    var x = index
    // handle click event of the Add button
    const handleAddClick = () => {
        setTitle("Tambah Riwayat Pekerjaan")
        document.getElementById('mulai').value='';
        document.getElementById('berhenti').value='';
        document.getElementById('institusi').value='';
        document.getElementById('jabatan').value='';

        var awal = jobs.length
        setIndex(awal)
        setJobs([...jobs, { mulai: "", berhenti: "", institusi: "", jabatan: ""}]);
    };

    const handleEditClick = (id, mulai, berhenti, institusi, jabatan, e) => {
        setTitle("Edit Riwayat Pekerjaan")
        var awal = id
        setIndex(awal)
        setMulai(mulai);
        setBerhenti(berhenti);
        setInstitusi(institusi);
        setJabatan(jabatan);
    };

    const handleReset = () => {
        setTitle("Tambah Riwayat Kerja")
        const list = [...jobs];
        list.splice(index, 1);
        setJobs(list);
        setIndex(0)
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...jobs];
        list[index][name] = value;
        setJobs(list);
    };

    
    
    // handle click event of the Remove button
    const handleRemoveClick = key => {
        const list = [...jobs];
        list.splice(key, 1);
        setJobs(list);
        dispatch(updateUserAction( name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, list, profile ));
        window.location.reload('/listpekerjaan')
    };
   
  
    return (
        <div id="main-wrapper">
            <Headers />
            <Headermobile />
            <Menumobile />
            <BottomNavbarMobile />
            <BottomNavbarMobilePopup />

            <div className="dashboard-content-section section bg_color--5">
                <div className="container-fluid p-0">
                    <div className="row no-gutters">
                        {/* <Menudashboard /> */}
                        <div className="col-xl-12 col-lg-9">
                            <div className="dashboard-main-inner">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-breadcrumb-content mb-40">
                                        <h4>&nbsp;</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-overview">
                                    <div className="row">
                                        <div className="col-xl-12 col-12">
                                            <div className="profile-applications mb-50">
                                                <div className="profile-applications-heading">
                                                    <ul className="nav">
                                                        <li key="1" style={margin}><NavLink to="/memberdetailprofile" style={font}>Profile</NavLink></li>
                                                        <li key="2" style={margin}><NavLink to="/daftar_activity_admin" style={font}>Aktivitas</NavLink></li>
                                                        <li key="3" style={margin}><NavLink to="/listaddress" style={font}>Alamat</NavLink></li>
                                                        <li key="4" style={margin}><NavLink to="/listpendidikan" style={font}>Pendidikan</NavLink></li>
                                                        <li key="5" style={margin}><NavLink to="/listpekerjaan" style={font}>Pekerjaan</NavLink></li>
                                                        <li key="6" style={margin}><NavLink to="/attachmentFile" style={font}>Lampiran</NavLink></li>
                                                    </ul>
                                                </div>
                                                 

                                                {errorUpdate && <ErrorMessage variant="danger">{errorUpdate}</ErrorMessage>}
                                                <div className="profile-applications-main-block">
                                               
                                                    <div className="profile-applications-form">
                                                       <div className="row">
                                                       <div className="col-md-10">
                                                            <strong>Daftar Pekerjaan</strong>
                                                        </div>
                                                        <div className="col-md-2 text-right">
                                                            <button className="btn btn-primary " data-toggle="modal" data-target="#paycard" onClick={handleAddClick} ><i className="fas fa-plus"></i> Tambah </button>
                                                        </div>
                                                        <br></br><br></br>
                                                       {jobslist.map((y, l) => {

                                                            return (
                                                            <div className="col-md-4 mb-3" key={l}>
                                                                <div className="shadow p-3 mb-5 bg-white rounded">
                                                                    <div className="container">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <strong className="input-group mb-0">Tahun Mulai {y.mulai}</strong>
                                                                                <strong className="input-group mb-0">Tahun Berhenti {y.berhenti}</strong>
                                                                                <strong className="input-group mb-0">Jabatan {y.jabatan}</strong>
                                                                                <strong>Institusi {y.institusi}</strong>
                                                                                <br/><br/>
                                                                            </div>
                                                                            
                                                                              <div className="row">
                                                                                <div className="col-md-12" >
                                                                                <button className="btn btn-sm btn-warning " data-toggle="modal" data-target="#edit"  onClick={() => handleEditClick(l, y.mulai, y.berhenti, y.institusi, y.jabatan)} ><i className="fas fa-edit"></i> Edit</button>
                                                                                &nbsp;
                                                                                <button className="btn btn-sm btn-danger"  onClick={() => handleRemoveClick(l)} ><i className="fas fa-trash"></i> Hapus</button>
                                                                                </div>
                                                                            </div>      
                                                                               
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                       )})}
                                                       </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <Footers />
    
        <div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => handleReset()}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">Tahun Mulai</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input
                                                name="mulai"
                                                id="mulai"
                                                className="form-control"
                                                placeholder="Tahun Mulai"
                                                value={x.mulai}
                                                type="number"
                                                onChange={e => handleInputChange(e, index)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">Tahun Berhenti</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                            <input
                                                name="berhenti"
                                                id="berhenti"
                                                className="form-control"
                                                placeholder="Tahun Berhenti"
                                                value={x.berhenti}
                                                type="number"
                                                onChange={e => handleInputChange(e, index)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">Institusi</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                            <input
                                                name="institusi"
                                                id="institusi"
                                                className="form-control"
                                                placeholder="institusi"
                                                value={x.institusi}
                                                onChange={e => handleInputChange(e, index)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">Jabatan</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                            <input
                                                name="jabatan"
                                                id="jabatan"
                                                className="form-control"
                                                placeholder="jabatan"
                                                value={x.jabatan}
                                                onChange={e => handleInputChange(e, index)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" onClick={submitHandler.bind(this)} className="btn btn-success btn-block" data-dismiss="modal" aria-label="Close">
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
 

                <div className="modal fade" id="edit" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">Tahun Mulai</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={mulai}
                                                name="mulai"
                                                onChange={(e) => setMulai(e.target.value)}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">Tahun Berhenti</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                            <input
                                                name="berhenti"
                                                className="form-control"
                                                placeholder="Tahun Berhenti"
                                                value={berhenti}
                                                type="number"
                                                onChange={(e) => setBerhenti(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">Institusi</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                            <input
                                                name="institusi"
                                                className="form-control"
                                                placeholder="institusi"
                                                value={institusi}
                                                onChange={(e) => setInstitusi(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">Jabatan</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                            <input
                                                name="jabatan"
                                                className="form-control"
                                                placeholder="jabatan"
                                                value={jabatan}
                                                onChange={(e) => setJabatan(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" onClick={updateHandler.bind(this)} className="btn btn-success btn-block" data-dismiss="modal" aria-label="Close"
                                    >
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
 
    </div>

    )
}
export default Edit;
