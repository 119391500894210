import React, { useState, useEffect } from "react";
import { getToken } from "./firebaseInit";
import $ from "jquery";
import { NavLink } from "react-router-dom";
import { logout } from "../actions/userActions";
import { useDispatch } from "react-redux";

function Header() {
  const [isTokenFound, setTokenFound] = useState(false);
  
  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        // console.log("Token is", data);
        var requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        fetch("/api/v1/auth/find/" + data, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            var resultcode = JSON.parse(result);
            if (resultcode.code === 500) {
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");

              var raw = JSON.stringify({
                endpoint: "",
                p256dh: "",
                auth: data,
              });

              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
              };

              fetch("/api/v1/auth/regisworker", requestOptions)
                .then((response) => response.text())
                .then((result) => result)
                .catch((error) => console.log("error", error));
            }
          })
          .catch((error) => console.log("error", error));
      }
      return data;
    }

    tokenFunc();
  }, [setTokenFound]);
  // var windows = $(window);
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
  };

  /*-----------------------------------
        Menu Sticky
    -----------------------------------*/
  // var sticky = $('.header-sticky');

  // windows.on('scroll', function () {
  //     var scroll = windows.scrollTop();
  // if (scroll < 100) {
  //     sticky.removeClass('is-sticky');
  // } else {
  //     sticky.addClass('is-sticky');
  // }
  // });

  $("input[type=text],textarea,input[type=number],input[type=email],input[type=password]").focus(function () {
    document.getElementById("menumobile").style.display = "none";
  });
  $("input[type=text],textarea,input[type=number],input[type=email],input[type=password]").blur(function () {
    document.getElementById("menumobile").style.display = "block";
  });

  const menu = (
    <ul>
      <li key="1">
        <NavLink to="/">Beranda</NavLink>
      </li>
      <li key="2">
        <NavLink to="/event">Kegiatan</NavLink>
      </li>
      <li key="3">
        <NavLink to="/job">Pekerjaan</NavLink>
      </li>
    </ul>
  );
  var name = JSON.parse(localStorage.getItem("info"));
  var user;
  if (name != null) {
    user = name["name"];
  } else {
    user = "user";
  }

  const menuUser = (
    <div className="jp-author item">
      <nav className="main-menu">
        <ul>
          <li>
            <a href="#0">
              {" "}
              <i className="lnr lnr-user" />
              Hi, {user} <small className="icon-arrow"></small>
            </a>
            <ul className="sub-menu">
              <li key="1">
                <NavLink to="/memberdetailprofile">Akun</NavLink>
              </li>
              <li key="2">
                <NavLink to="/daftar_activity_admin">Aktivitas</NavLink>
              </li>
              <li key="3">
                <NavLink to="/riwayat-lamaran">Riwayat Lamaran</NavLink>
              </li>
              <li key="4" onClick={logoutHandler}>
                <NavLink to="/login">Keluar</NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );

  const buttonLogin = (
    <div className="jp-author-action-two item">
      <NavLink className="ht-btn transparent-btn-two" to="/login">
        <i className="lnr lnr-user"></i> <span>Login</span>
      </NavLink>
    </div>
  );

  // const userLink = (
  //     <ul>
  //        <li><NavLink to="/" >Home</NavLink></li>
  //         <li><NavLink to="/event" >Event</NavLink></li>
  //         <li><NavLink to="/job" >Job</NavLink></li>
  //         <li><NavLink to="/professionlist" >Profession</NavLink></li>
  //         <li><NavLink to="/memberlist">Manage Member</NavLink></li>
  //         <li><NavLink to="/eventlist">Manage Event</NavLink></li>
  //         <li><NavLink to="/managejob">Manage Job</NavLink></li>
  //     </ul>
  // );

  return (
    // <header className="black-logo-version header-sticky sticky-white d-none d-lg-block " >
    <header className="header-absolute black-logo-version header-sticky sticky-white no-padding d-none d-lg-block pt-20 pb-20 is-sticky">
      <div className="main-header">
        <div className="container pl-90 pl-lg-15 pl-md-15 pr-90 pr-lg-15 pr-md-15">
          <div className="row align-items-center no-gutters">
            <div className="col-xl-2 col-lg-2 col-12">
              <div className="logo">
                <a href="/">
                  <img
                    src="/assets/images/logo-digihealth.png"
                    width="85%"
                    alt="digihealth"
                  />
                </a>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-12">
              <nav className="main-menu">{menu}</nav>
            </div>
            <div className="col-xl-3 col-lg-3 col-12">
              {/* <div className="header-btn-action d-flex justify-content-end">
                                <div className="btn-action-wrap d-flex">
                                    <div className="jp-author item">
                                        <nav className="main-menu">
                                            { localStorage.userInfo ? menuUser : buttonLogin }
                                        </nav>

                                    </div>
                                </div>
                            </div> */}
              <div className="header-btn-action d-flex justify-content-end">
                <div className="btn-action-wrap d-flex">
                  {localStorage.userInfo ? menuUser : buttonLogin}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;
