import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/ErrorMessage";
import { resetPasswordAction } from "../../actions/userActions";
import Footers from "../../components/Footer";
import Headers from "../../components/Headermenu";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
const style = {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '400px',
    height: '90%',
}

function Forgetpassword({ history }) {
    const [password, setPassword] = useState("");
    const [konfirmasi, setKonfirmasi] = useState("");

    const dispatch = useDispatch();

    const resetPassword = useSelector((state) => state.resetPassword);
    const { loading, error, success, userInfo } = resetPassword;
    useEffect(() => {
        if (userInfo) {
            history.push("/");
            // window.location.href = '/';
        }
    }, [history, userInfo]);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const query = params.get('digihealth');
    var id;
    const submitHandler = (e) => {
        e.preventDefault();
        if(query !== null )
        {
            id = query
        }else{
            id = 'digihealth'
        }
        dispatch(resetPasswordAction(id, password, konfirmasi));
    };

    return (
        <div id="main-wrapper" >
            <Headers />
            <Headermobile />
            <Menumobile />
            <BottomNavbarMobile menu="account" />
            <BottomNavbarMobilePopup />
            <div className="breadcrumb-section section bg_color--5 pt-60 pt-sm-50 pt-xs-40 pb-60 pb-sm-50 pb-xs-40">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-breadcrumb-content">
                                <ul className="page-breadcrumb">
                                    <li><a href="/">Home</a></li>
                                    <li>Perbaharui Kata Sandi</li>
                                </ul>
                                <h1>Perbaharui Kata Sandi</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="login-register-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-12">
                            <div className="login-register-form-area" style={style}>
                                <div className="login-tab-menu">
                                   <center><h4>Perbaharui Kata Sandi</h4></center>
                                </div>
                                <div className="tab-content">
                                    <div id="login" className="tab-pane fade show active">
                                        <div className="login-register-form">
                                            <form onSubmit={submitHandler}>
                                                {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
                                                {success && <ErrorMessage variant="warning">{success}</ErrorMessage>}
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="single-input">
                                                            <input placeholder="New Password" name="password"
                                                                    type="password"
                                                                    value={password}
                                                                    onChange={(e) => setPassword(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="single-input">
                                                            <input placeholder="Konfirmasi New Password" name="konfirmasi"
                                                                    type="password"
                                                                    value={konfirmasi}
                                                                    onChange={(e) => setKonfirmasi(e.target.value)} />
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="col-12 mb-25"><button className="ht-btn">Submit</button></div>
                                                </div>
                                            </form>
                                            {loading && <Loading />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footers />
        </div>
    )
}
export default Forgetpassword;
