import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { getDetails } from "../../actions/eventActions";
import { PDFDownloadLink, Page, Text, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Parser from 'html-react-parser';
import Moment from 'react-moment';
import 'moment/locale/id';
import { getList } from "../../actions/professionAction";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const bucket = process.env.REACT_APP_API_KEY

const styles = StyleSheet.create({
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    aset:{
        width: 750, height: 550, padding: 20, textAlign: 'center', border: '5px solid #787878'
    },
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
      },
  });


export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banner: '',
            title: '',
            type: '',
            date: '',
            time: '',
            timeend:'',
            user:'',
            moderator: '',
            skp: '',
            jmlhmateri: '',
            nomorpendaftaran: '',
            linkmeet: '',
            meetid: '',
            passcode: '',
            sertificate: '',
            speakers: [],
            desc: '',
            gelardepan:'',
            gelarbelakang:'',
            skppeserta:'',
            nosertificate: '',
            datetime:'',
            verifikasi:'false',
            namalengkap:'',
            email:'',
            tlp:'',
            str:'',
            npa:'',
            nik:'',
            tglLahir:'',
            tmpLahir:'',
            profesi:'',
            spesialisasi:'',
            consultant:'',
            listpropesi:[]
        };
    }
    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo) {
            this.resetstate()
        }else{
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }
    resetstate(){
        this.setState({
            banner: '',
            title: '',
            type: '',
            date: '',
            time: '',
            timeend:'',
            user:'',
            moderator: '',
            skp: '',
            jmlhmateri: '',
            nomorpendaftaran: '',
            linkmeet: '',
            meetid: '',
            passcode: '',
            sertificate: '',
            speakers: [],
            desc: '',
            gelardepan:'',
            gelarbelakang:'',
            skppeserta:'',
            nosertificate: '',
            datetime:'',
            verifikasi:'false',
            namalengkap:'',
            email:'',
            tlp:'',
            str:'',
            npa:'',
            nik:'',
            tglLahir:'',
            tmpLahir:'',
            profesi:'',
            spesialisasi:'',
            consultant:'',
            listpropesi:[]
        })
        this.receivedData()

    }
     Br = () => "\n";

    MyDoc = () => (
        <Document>
        <Page size="LETTER" orientation="landscape">
          <Image src={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/sertificate/"+this.state.sertificate} style={styles.pageBackground} />
            <Text>
            <Text style={styles.aset}>
            {this.Br()}{this.Br()}{this.Br()}{this.Br()}{this.Br()}{this.Br()}{this.Br()}
            {/* <Text style={{fontSize: 50, fontWeight: 'bold'}}>Certificate of Completion</Text>{this.Br()}{this.Br()}{this.Br()} */}
            <Text style={{fontSize: 20}}>{this.state.nosertificate}</Text>
            {this.Br()}{this.Br()}{this.Br()}{this.Br()}
            <Text style={{fontSize: 20}}><b>{this.state.gelardepan && this.state.gelardepan+'.' } {this.state.user}{this.state.gelarbelakang && ', '+this.state.gelarbelakang}</b></Text>{this.Br()}{this.Br()}{this.Br()}{this.Br()}
            {/* <Text style={{fontSize: 25}}><i>has completed the course</i></Text> {this.Br()}{this.Br()} */}
            {/* <Text style={{fontSize: 25}}>{this.state.title}</Text>{this.Br()}{this.Br()} */}
            </Text>
            </Text>
        </Page>
      </Document>
      );

    
    receivedData() {
        getList().then(res => {
            const data = res['data'] ? res['data'] : [];
            this.setState({listpropesi:[...data]})

        })
        getDetails().then(res => {
            if (res.status !== 'success') {
                localStorage.removeItem('recent')
                localStorage.removeItem('info')
                localStorage.removeItem('userInfo')
                this.props.history.push(`/`)
            } else {
                var master = JSON.parse(localStorage.getItem('info'));
                if (master === null) {
                    localStorage.removeItem('recent')
                    localStorage.removeItem('info')
                    localStorage.removeItem('userInfo')
                    this.props.history.push(`/`)
                }else{
                    var user = master['name']
                    var vertifikasiemail = master['verifikasi']
                    var namalengkap = master['name']
                    var email = master['email']
                    var tlp = master['tlp']
                    var str = master['str']
                    var npa = master['npkidi']
                    var nik = master['nik']
                    var tglLahir = master['tanggallahir']
                    var tmpLahir = master['tempatlahir']
                    var profesi = master['profesi']
                    var spesialisasi = master['spesialisasi']
                    var consultant = master['consltant']
                    var masterinfo = JSON.parse(localStorage.getItem('userInfo'));
                    var id = masterinfo['data']['user']['id']
                }
                //this validation level profession PD-177
                let checkprofesi = this.state.listpropesi.filter(item => (item.level1 === true) &&  (profesi ? item.name === profesi : []) ) 
                // check level1
                if(checkprofesi.length > 0)
                {
                    //check level2
                    let checkspesialisasi = this.state.listpropesi.filter(item => (item.level2 === true) &&  (checkprofesi[0]['id'] ? item.parent === checkprofesi[0]['id']  : []) )

                    if(spesialisasi !== '-' && checkspesialisasi.length !== 0){
                        let checkspesialisasis = this.state.listpropesi.filter(item => (item.level2 === true) &&  (spesialisasi ? item.name === spesialisasi  : []) )
                        let checkconsultant = this.state.listpropesi.filter(item => (item.level3 === true) &&  (checkspesialisasis[0]['id'] ? item.parent === checkspesialisasis[0]['id'] : []) )
                        // check level3
                        if(checkconsultant.length === 0){ consultant = 'N' }
                    }else if(spesialisasi === '-' && checkspesialisasi.length !== 0){
                        
                    }else{
                        consultant = 'N'
                        spesialisasi = 'N'
                    }
                }
                
                const servertime = res['message'];
                this.setState({
                    verifikasi: vertifikasiemail,
                    namalengkap:namalengkap,
                    email:email,
                    tlp:tlp,
                    str:str,
                    npa:npa,
                    nik:nik,
                    tglLahir:tglLahir,
                    tmpLahir:tmpLahir,
                    profesi:profesi,
                    spesialisasi:spesialisasi,
                    consultant:consultant,
                    banner: res['data']['banner'],
                    title: res['data']['title'],
                    type: res['data']['type'],
                    date: res['data']['date'],
                    time: res['data']['time'],
                    timeend: res['data']['timeend'],
                    user: user,
                    moderator: res['data']['moderator'],
                    skp: res['data']['skp'],
                    jmlhmateri: res['data']['jmlhmateri'],
                    speakers: res['data']['speakers'],
                    desc: res['data']['desc'],
                    meetid: res['data']['meetid'],
                    passcode: res['data']['passcode'],
                    linkmeet: res['data']['linkmeet'],
                    sertificate: res['data']['sertificate'],
                    datetime : servertime
                })
                var participan = res['data']['paticipans']
                for (let index = 0; index < participan.length; index++) {
                    if (res['data']['paticipans'][index]['idmember'] === id) {
                        this.setState({
                            nomorpendaftaran: res['data']['paticipans'][index]['nomorpendaftaran'],
                            nosertificate: res['data']['paticipans'][index]['sertificate'],
                            skppeserta: res['data']['paticipans'][index]['skp'],
                            gelardepan: res['data']['paticipans'][index]['gelardepan'],
                            gelarbelakang: res['data']['paticipans'][index]['gelarbelakang']
                        })
                    }
                }


            }

        })

    }

    alertProfile()
    {
        
        if (this.state.verifikasi === "false") {
            MySwal.fire({
               icon: 'info',
               title: 'Oops...',
               text: 'Harap Verifikasi Email terlebih dahulu!',
               showDenyButton: false,
               showCancelButton: false,
               confirmButtonText: 'OK',
               denyButtonText: `Don't save`,
           }).then((result) => {
               /* Read more about isConfirmed, isDenied below */
               if (result.isConfirmed) {
                   window.location.href = '/memberdetailprofile'
               }
           })
        }else{
                MySwal.fire({
                   icon: 'info',
                   title: 'Oops...',
                   text: 'Harap Lengkapi Data Profile Terlebih Dahulu',
                   showDenyButton: false,
                   showCancelButton: false,
                   confirmButtonText: 'OK',
                   denyButtonText: `Don't save`,
               }).then((result) => {
                   /* Read more about isConfirmed, isDenied below */
                   if (result.isConfirmed) {
                       window.location.href = '/memberdetailprofile'
                   }
               })
            
        }
    }


    render() {
       

        return (
            <div id="main-wrapper">
                <Headers />
                <Headermobile />
                <Menumobile />
                <BottomNavbarMobile />
                <BottomNavbarMobilePopup />
                {/* Page Banner Section Start */}
                <div className="page-banner-section section bg_color--5 pt-120 pt-lg-100 pt-md-80 pt-sm-60 pt-xs-50">
                    <div className="container p-0">
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="banner-image" id="banner-image">
                                    <img src={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/event/"+this.state.banner} alt="banner" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Page Banner Section End */}
                {/* Breadcrumb Section Start */}
                <div className="breadcrumb-section section bg_color--5">
                    <div className="container job-content-box">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-breadcrumb-content">
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="#0">Event</a></li>
                                        <li>Event Detail</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="job-head-content">
                                    <h1 className="title">{this.state.title}</h1>
                                    <p className="employer-name"></p>
                                    <div className="job-meta-detail">
                                        <ul>
                                            <li className="posted">
                                                <i className="lnr lnr-calendar-full" />
                                                <span className="text"> Tanggal: </span>
                                                <span className="time"><Moment locale="id" format="DD MMM YYYY">{this.state.date}</Moment></span>
                                            </li>
                                            <li className="expries">
                                                <i className="lnr lnr-clock" />
                                                <span className="text"> Waktu: </span>
                                                <span className="date theme-color"> {this.state.time}  <strong>-</strong> {this.state.timeend} </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-sm-60 mt-xs-50">
                                <div className="sidebar-wrapper-three">
                                    <div className="common-sidebar-widget sidebar-three mb-0 pb-0">
                                        <div className="sidebar-job-apply">
                                            <div className="action-button">
                                         {(() => {
                                            if(this.state.skppeserta !== "-" && this.state.nosertificate !== "-" && this.state.verifikasi !== "false" && this.state.str !== undefined  && this.state.nik !== undefined && this.state.tglLahir !== undefined && this.state.tmpLahir !== undefined && this.state.profesi !== '-' && this.state.spesialisasi !== '-' && this.state.consultant !== '-')
                                            {
                                               return( <PDFDownloadLink document={this.MyDoc()} className="ht-btn text-center"  fileName={'sertificate '+this.state.type+' '+this.state.title+'.pdf'} >
                                                {({ blob, url, loading, error }) => (loading ? 'Load Sertifikat' : 'Download Sertifikat')}
                                                </PDFDownloadLink> )

                                            }else if(this.state.skppeserta === "-" && this.state.nosertificate === "-" && this.state.verifikasi !== "false" && this.state.str !== undefined  && this.state.nik !== undefined && this.state.tglLahir !== undefined && this.state.tmpLahir !== undefined && this.state.profesi !== '-' && this.state.spesialisasi !== '-' && this.state.consultant !== '-' )
                                            {
                                               return (
                                                <a href="#0" className="btn btn-secondary btn-block disabled" role="button" aria-disabled="true" >Sertifikat Belum Tersedia</a>
                                               )                                                 
                                            }else if(this.state.skppeserta === "-" && this.state.nosertificate === "-" && this.state.str === undefined  && this.state.nik === undefined && this.state.tglLahir === undefined && this.state.tmpLahir === undefined && this.state.profesi === '-' && this.state.spesialisasi === '-' && this.state.consultant === '-' )
                                            {
                                                return (
                                                    <a href="#0" className="btn btn-secondary btn-block disabled" role="button" aria-disabled="true" >Sertifikat Belum Tersedia</a>
                                                    )                                                 
                                             }
                                            else if(this.state.skppeserta !== "-" && this.state.nosertificate !== "-" && this.state.str === undefined  && this.state.nik === undefined && this.state.tglLahir === undefined && this.state.tmpLahir === undefined && this.state.profesi === '-' && this.state.spesialisasi === '-' && this.state.consultant === '-' )
                                            {
                                                return (
                                                    <button className="ht-btn text-center" onClick={() => this.alertProfile()}  aria-disabled="true" >Download Sertifikat</button>
                                                )                                                 
                                             }else if(this.state.skppeserta !== "-" && this.state.nosertificate !== "-" && this.state.verifikasi === "false")
                                             {
                                                return (
                                                    <button className="ht-btn text-center" onClick={() => this.alertProfile()}  aria-disabled="true" >Download Sertifikat</button>
                                                )  
                                             }else{
                                                return (
                                                    <button className="ht-btn text-center" onClick={() => this.alertProfile()}  aria-disabled="true" >Download Sertifikat</button>
                                                ) 
                                             }

                                        })()}
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <strong>Pendaftaran {this.state.type} Berhasil</strong>
                        <div className="employer-meta-box mt-30">
                            <div className="row no-gutters">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    {/* Single Meta Field Start */}
                                    <div className="single-meta-field meta-field-three">
                                        <div className="field-label">
                                            <i className="lnr lnr-earth" />
                                            <span>Meeting Room </span>
                                        </div>
                                        <div className="field-value">&nbsp;&nbsp;&nbsp;&nbsp;<a href={this.state.linkmeet} >Room</a></div>
                                    </div>
                                    {/* Single Meta Field Start */}
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    {/* Single Meta Field Start */}
                                    <div className="single-meta-field meta-field-three">
                                        <div className="field-label">
                                            <i className="lnr  lnr-users" />
                                            <span>Meeting ID </span>
                                        </div>
                                        <div className="field-value">&nbsp;&nbsp;&nbsp;&nbsp;{this.state.meetid}</div>
                                    </div>
                                    {/* Single Meta Field Start */}
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    {/* Single Meta Field Start */}
                                    <div className="single-meta-field meta-field-three border-0">
                                        <div className="field-label">
                                            <i className="lnr lnr-lock" />
                                            <span>Passcode </span>
                                        </div>
                                        <div className="field-value">&nbsp;&nbsp;&nbsp;&nbsp;{this.state.passcode}</div>
                                    </div>
                                    {/* Single Meta Field Start */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* Breadcrumb Section Start */}
                {/* Job Details Section Start */}
                <div className="job-details-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
                    <div className="container job-content-box st-border">
                        <div className="row">
                            <div className="col-lg-4 order-lg-2 order-2 mt-sm-60 mt-xs-50">
                                <div className="sidebar-wrapper-three">
                                    <div className="common-sidebar-widget sidebar-three">
                                        <h2 className="sidebar-title">Informasi Kegiatan</h2>
                                        <div className="sidebar-meta">
                                            <div className="row no-gutters">
                                                <div className="col-lg-12">
                                                    {/* Single Meta Field Start */}
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-tag" />
                                                            <span>Tipe: </span>
                                                        </div>
                                                        <div className="field-value"><strong>{this.state.type}</strong></div>
                                                    </div>
                                                    {/* Single Meta Field Start */}
                                                </div>
                                                <div className="col-lg-12">
                                                    {/* Single Meta Field Start */}
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-user" />
                                                            <span>Moderator: </span>
                                                        </div>
                                                        <div className="field-value"><strong>{this.state.moderator}</strong></div>
                                                    </div>
                                                    {/* Single Meta Field Start */}
                                                </div>
                                                <div className="col-lg-12">
                                                    {/* Single Meta Field Start */}
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-gift" />
                                                            <span>Nilai SKP </span>
                                                        </div>
                                                        <div className="field-value"><strong>{this.state.skp}</strong></div>
                                                    </div>
                                                    {/* Single Meta Field Start */}
                                                </div>

                                                <div className="col-lg-12">
                                                    {/* Single Meta Field Start */}
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-mic" />
                                                            <span>Pembicara </span>
                                                        </div>
                                                        <div className="field-value">
                                                            <div className="job-skill-tag">
                                                                {this.state.speakers.map((value, key) =>
                                                                    <a href="#0" className="fw-600 btn"><strong>{value.name}</strong></a>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Single Meta Field Start */}
                                                </div>
                                                <div className="col-lg-12">
                                                    {/* Single Meta Field Start */}
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-layers" />
                                                            <span>Materi </span>
                                                        </div>
                                                        <div className="field-value">
                                                            <div className="job-skill-tag">
                                                                {this.state.speakers.map(function (value) {
                                                                    return <>
                                                                    {(() => {
                                                                    let str = this.state.datetime
                                                                    const server = str.split(" ");
                                                                    let datenow = server[0]
                                                                    let timenow = server[1]
                                                                    
                                                                        if (value.file !== "-" && value.file !== "" && value.type === "1") {
                                                                            return (
                                                                                <a target="blank" href={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/materi/"+value.file} className="fw-600 btn"><strong><i class="fas fa-file-download"></i> {value.materi}</strong></a>
                                                                            )
                                                                        }else if (value.file !== "-" && value.file !== "" && value.type === "2" && datenow === this.state.date && timenow > this.state.timeend | timenow === this.state.timeend) {
                                                                            return (
                                                                                <a target="blank" href={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/materi/"+value.file} className="fw-600 btn"><strong><i class="fas fa-file-download"></i> {value.materi}</strong></a>
                                                                            )
                                                                        }else if (value.file !== "-" && value.file !== "" && value.type === "3" && this.state.skppeserta !== "-" && this.state.nosertificate !== "-") {
                                                                            return (
                                                                                <a target="blank" href={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/materi/"+value.file} className="fw-600 btn"><strong><i class="fas fa-file-download"></i> {value.materi}</strong></a>
                                                                            )
                                                                        }else {
                                                                            return (
                                                                                <a href="#0" className="fw-600 btn disabled"><strong>{value.materi}</strong></a>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </>
                                                               }.bind(this))}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Single Meta Field Start */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 order-lg-1 order-1 pr-55 pr-md-15 pr-sm-15 pr-xs-15">
                                <div className="job-detail-content">
                                    <div className="field-descriptions mt-xs-20 mb-60 mb-sm-30 mb-xs-30">
                                        <h2 className="sidebar-title">Deskripsi</h2>
                                        <p>{Parser(this.state.desc)}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Job Details Section End */}

                <Footers />
            </div>


        )
    }
}
