import React, { Component } from 'react';
import { getDetail } from "../../actions/eventActions";
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import axios from 'axios'
var fileDownload = require('js-file-download');
const bucket = process.env.REACT_APP_API_KEY

export default class App extends Component {

    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let query = params.get('kegiatan');
        if (query !== null) {
            this.handlefile(query)
        } else {
            this.props.history.push(`/event`)
        }



    }

    handlefile = (id) => {

        getDetail(id).then(res => {
            var speakers = res['data']['speakers']
            var timeend = res['data']['timeend']
            var date = res['data']['date']
            const servertime = res['message'];
            const server = servertime.split(" ");
            let datenow = server[0]
            let timenow = server[1]
            speakers.map(function (value) {
                return <>
                    {(() => {

                        if (value.file !== "-" && value.file !== "" && value.type === "1") {
                            axios.get('https://prosehat-digihealth.s3.us-east-2.amazonaws.com/' + bucket + '/materi/' + value.file, {
                                responseType: 'blob',
                            })
                                .then((res) => {
                                    fileDownload(res.data, value.file)
                                })

                        } else if (value.file !== "-" && value.file !== "" && value.type === "2" && datenow === date && timenow > timeend | timenow === timeend) {
                            axios.get('https://prosehat-digihealth.s3.us-east-2.amazonaws.com/' + bucket + '/materi/' + value.file, {
                                responseType: 'blob',
                            })
                                .then((res) => {
                                    fileDownload(res.data, value.file)
                                })
                        }
                        // else if (value.file !== "-" && value.file !== "" && value.type === "3") {
                        //     axios.get('https://prosehat-digihealth.s3.us-east-2.amazonaws.com/' + bucket + '/materi/' + value.file, {
                        //         responseType: 'blob',
                        //     })
                        //     .then((res) => {
                        //         fileDownload(res.data, value.file)
                        //     })
                        // }
                    })()}
                </>

            })

        })



        // window.open('https://google.com', '_self');
        // window.close();
    }

    handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
            })
    }


    render() {
        return (
            <div id="main-wrapper" >
                <Headers />
                <Headermobile />
                <Menumobile />
                <BottomNavbarMobile menu="account" />
                <BottomNavbarMobilePopup />

                <div className="breadcrumb-section section bg_color--5 pt-60 pt-sm-50 pt-xs-40 pb-60 pb-sm-50 pb-xs-40">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-breadcrumb-content">

                                    <h1>&nbsp;</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="login-register-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-lg-12">
                                <div className="error-404 not-found">
                                    <div className="text-label-404">
                                        <h1>Unduh Materi Sukses</h1>
                                    </div>
                                    <div className="home-link">
                                        <span>Terima kasih telah menunjungi halaman unduh materi ini  </span>
                                        silahkan menghubungi customer service jika anda mengalami kendala<span>
                                        </span>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                <Footers />
            </div>
        )
    }




}
