import React, { useEffect, useState } from "react";
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../components/Loading";
import { updateUserAction, upload, deletecontent } from "../../actions/userActions";
import ErrorMessage from "../../components/ErrorMessage";
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import $ from 'jquery';
const MySwal = withReactContent(Swal)
const font = {
    fontSize: '12px',
}
const margin ={
    marginRight: "20px"
}

function Edit({ history }) {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [gelardepan, setGelardepan] = useState("");
    const [gelarbelakang, setGelarbelakang] = useState("");
    const [tlp, setTlp] = useState("");
    const [nip, setNip] = useState("");
    const [email, setEmail] = useState("");
    const [str, setStr] = useState("");
    const [npkidi, setNpkidi] = useState("");
    const [nik, setNik] = useState("");
    const [tempatlahir, setTempatlahir] = useState("");
    const [tanggallahir, setTanggallahir] = useState("");
    const [profesi, setProfesi] = useState("");
    const [spesialisasi, setSpesialisasi] = useState("");
    const [consltant, setConsltant] = useState("");
    const [address, setAddress] = useState([]);
    const [education, setEducation] = useState([]);
    const [jobs, setJobs] = useState([]);
    const userUpdate = useSelector((state) => state.userUpdate);
    const userLogin = useSelector((state) => state.userLogin);
    const [sertifikat, setSertifikat] = useState([{ name: "", file: "-" }]);
    const [cv, setCv] = useState("");
    const [strfile, setStrfile] = useState("");
    const [bank, setBank] = useState("");
    const bucket = process.env.REACT_APP_API_KEY
    const [profile, setProfile] = useState("");

    const {
        loading: loadingUpdate,
        error: errorUpdate,
        success: successUpdate,
    } = userUpdate;
    const { userInfo } = userLogin;
  

    useEffect(() => {
        if (userInfo) {
            history.push("/attachmentFile");
            var prod = JSON.parse(localStorage.getItem('info'));
        setName(prod['name']);
        setGelardepan(prod['gelardepan']);
        setGelarbelakang(prod['gelarbelakang']);
        setTlp(prod['tlp']);
        setEmail(prod['email']);
        setNip(prod['nip']);
        setStr(prod['str']);
        setNpkidi(prod['npkidi']);
        setNik(prod['nik']);
        setTempatlahir(prod['tempatlahir']);
        setTanggallahir(prod['tanggallahir']);
        setProfesi(prod['profesi']);
        setSpesialisasi(prod['spesialisasi']);
        setConsltant(prod['consltant']);
        setProfile(prod['profile']);
        if (prod['address'].length !== 0) {
            setAddress(prod['address']);
        }
        if (prod['education'].length !== 0) {
            setEducation(prod['education']);
        }
        if (prod['jobs'].length !== 0) {
            setJobs(prod['jobs']);
        }
        if(prod['cv'] !== undefined){
            setCv(prod['cv']);
            document.getElementById("previewcv").style.display = 'block';
            document.getElementById("previewcv").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/cv/"+prod['cv'].toString();
        }
        if(prod['strfile'] !== undefined){
            setStrfile(prod['strfile']);
            document.getElementById("previewstr").style.display = 'block';
            document.getElementById("previewstr").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/str/"+prod['strfile'].toString();
        }
         if(prod['bank'] !== undefined){
            setBank(prod['bank']);
            document.getElementById("previewbank").style.display = 'block';
            document.getElementById("previewbank").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/bank/"+prod['bank'].toString();
        }
        var sert= prod['sertifikat'] ? prod['sertifikat'] : []
        if(sert.length !== 0  ){
            setSertifikat(prod['sertifikat']);
        }
    }else{
    history.push("/login");
    }
            
    }, [
        history,
        userInfo,
        successUpdate,
        bucket
    ]);

    const uploadCv = (pics) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png" || pics.type === "application/pdf")) {
            document.getElementById("previewcv").style.display = 'none';
            document.getElementById("cv").style.display = 'block';
            if (cv !== undefined) {
                const deleteRequest = {
                    path: bucket+'/cv',
                    file: cv
                }
                deletecontent(deleteRequest)
            }
             const cvRequest = {
                path: bucket+'/cv',
                myFile: pics
            }

            upload(cvRequest).then((res) => {
                document.getElementById("cv").style.display = 'none';
                setCv(res.data)
                document.getElementById("previewcv").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/cv/"+res.data
                document.getElementById("previewcv").style.display = 'block';
            }).catch((err) =>{
                MySwal.fire('Oops...', err , 'info')
            })
            
        } else {
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type atau ukuran file yang anda masukan tidak cocok', 'info')
        }
    };

    const uploadStr = (pics) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png" || pics.type === "application/pdf")) {
            document.getElementById("previewstr").style.display = 'none';
            document.getElementById("str").style.display = 'block';

            if (strfile !== undefined) {
                const deleteRequest = {
                    path: bucket+'/str',
                    file: strfile
                }
                deletecontent(deleteRequest)
            }

             const strRequest = {
                path: bucket+'/str',
                myFile: pics
            }

            upload(strRequest).then((res) => {
                document.getElementById("str").style.display = 'none';
                setStrfile(res.data)
                document.getElementById("previewstr").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/str/"+res.data
                document.getElementById("previewstr").style.display = 'block';
            }).catch((err) =>{
                MySwal.fire('Oops...', err , 'info')
            })

            .catch((err) => {
                console.log(err);
            });
        } else {
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type atau ukuran file yang anda masukan tidak cocok', 'info')
        }
    }

    const uploadBank = (pics) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png" || pics.type === "application/pdf")) {
            document.getElementById("previewbank").style.display = 'none';
            document.getElementById("bank").style.display = 'block';

             if (bank !== undefined) {
                const deleteRequest = {
                    path: bucket+'/bank',
                    file: bank
                }
                deletecontent(deleteRequest)
            }

            const bankRequest = {
                path: bucket+'/bank',
                myFile: pics
            }
            upload(bankRequest).then((res) => {
                document.getElementById("bank").style.display = 'none';
                setBank(res.data)
                document.getElementById("previewbank").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/bank/"+res.data
                document.getElementById("previewbank").style.display = 'block';
            }).catch((err) =>{
                MySwal.fire('Oops...', err , 'info')
            })
           
        } else {
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type atau ukuran file yang anda masukan tidak cocok', 'info')
        }
    }

      // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...sertifikat];
        list[index][name] = value;
        setSertifikat(list);
    };


  
    // handle click event of the Add button
    const handleAddClick = () => {
        setSertifikat([...sertifikat, { name: "", file: "-"  }]);
    };
  
    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...sertifikat];
        if (list[index]['file'] !== undefined) {
                const deleteRequest = {
                    path: bucket+'/sertifikat',
                    file: list[index]['file']
                }
                deletecontent(deleteRequest)
        }
        list.splice(index, 1);
        setSertifikat(list);
    };
    const handleClick = (i) => {
         $("#custom"+i).trigger("click");
    }
    const handleUploadClick = (pics, index) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png" || pics.type === "application/pdf")) {
            document.getElementById("upload"+index).classList.remove("fa-upload");
            document.getElementById("upload" + index).classList.add("fa-spinner", "fa-spin");
            const list = [...sertifikat];
           if (list[index]['file'] !== undefined) {
                const deleteRequest = {
                    path: bucket+'/sertifikat',
                    file: list[index]['file']
                }
                deletecontent(deleteRequest)
            }
             const sertifikatRequest = {
                path: bucket+'/sertifikat',
                myFile: pics
            }

            upload(sertifikatRequest).then((res) => {
                document.getElementById("upload"+index).classList.remove("fa-spinner", "fa-spin");
                document.getElementById("upload"+index).classList.add("fa-upload");
                const list = [...sertifikat];
                list[index]['file'] = res.data
                setSertifikat(list);
                document.getElementById("preview"+index).href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/sertifikat/"+res.data  
             }).catch((err) =>{
                MySwal.fire('Oops...', err , 'info')
            })
            
        } else {
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type atau ukuran file yang anda masukan tidak cocok', 'info')
        }
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateUserAction(name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs, strfile, bank, cv, sertifikat, profile));
        history.push("/memberdetailprofile");
    };


    return (
        <div id="main-wrapper">
            <Headers />
            <Headermobile />
            <Menumobile />
            <BottomNavbarMobile />
            <BottomNavbarMobilePopup />

            <div className="dashboard-content-section section bg_color--5">
                <div className="container-fluid p-0">
                    <div className="row no-gutters">
                        {/* <Menudashboard /> */}
                        <div className="col-xl-12 col-lg-9">
                            <div className="dashboard-main-inner">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-breadcrumb-content mb-40">
                                        <h4>&nbsp;</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-overview">
                                    <div className="row">
                                        <div className="col-xl-12 col-12">
                                            <div className="profile-applications mb-50">
                                                <div className="profile-applications-heading">
                                                    <ul className="nav" >
                                                        <li key="1" style={margin}><NavLink to="/memberdetailprofile" style={font}>Profile</NavLink></li>
                                                        <li key="2" style={margin}><NavLink to="/daftar_activity_admin" style={font}>Aktivitas</NavLink></li>
                                                        <li key="3" style={margin}><NavLink to="/listaddress" style={font}>Alamat</NavLink></li>
                                                        <li key="4" style={margin}><NavLink to="/listpendidikan" style={font}>Pendidikan</NavLink></li>
                                                        <li key="5" style={margin}><NavLink to="/listpekerjaan" style={font}>Pekerjaan</NavLink></li>
                                                        <li key="6" style={margin}><NavLink to="/attachmentFile" style={font}>Lampiran</NavLink></li>
                                                    </ul>
                                                </div>
                                                {errorUpdate && <ErrorMessage variant="danger">{errorUpdate}</ErrorMessage>}
                                                <div className="profile-applications-main-block">
                                                    <div className="profile-applications-form">
                                                        <form onSubmit={submitHandler}>
                                                            <div className="row mb-30">
                                                                <div className="col-lg-12">
                                                                    <div className="row">
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <strong className="mb-1">Lampiran File</strong>
                                                                            <div className="single-input ">
                                                                                <div className="form-group">
                                                                                  <div className="row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Unggah Curriculum Vitae (CV) </label>
                                                                                    <div className="col-sm-5" style={{ width: '70%' }}>
                                                                                        <input
                                                                                            onChange={(e) => uploadCv(e.target.files[0])}
                                                                                            id="custom-file-cv"
                                                                                            type="file"
                                                                                            name="file"
                                                                                            label="Upload Profile Picture"
                                                                                            style={{display: "none"}}
                                                                                        />
                                                                                            <label for="imageUpload" className="btn btn-outline-primary btn-block" onClick={() =>  $("#custom-file-cv").trigger("click")}>Pilih File</label>
                                                                                    </div>
                                                                                    <div className="col-sm-2 " style={{ width: '30%' }}>
                                                                                        <div style={{ display: 'none' }} id="cv" class="spinner-border text-primary col-form-label br-2" role="status">
                                                                                            <span class="sr-only">Loading...</span>
                                                                                        </div>
                                                                                        <a style={{ display: 'none', width:'50px' }} href="#0" target="_blank" rel="noreferrer" id="previewcv" className="btn btn-primary btn-sm" ><i className="fas fa-image"></i></a>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Unggah Surat Tanda Registrasi (STR) </label>
                                                                                    <div className="col-sm-5" style={{ width: '70%' }}>
                                                                                        <input
                                                                                            onChange={(e) => uploadStr(e.target.files[0])}
                                                                                            id="custom-file-str"
                                                                                            type="file"
                                                                                            name="file"
                                                                                            label="Upload Profile Picture"
                                                                                            style={{display: "none"}}
                                                                                        />
                                                                                        <label for="imageUpload" className="btn btn-outline-primary btn-block" onClick={() =>  $("#custom-file-str").trigger("click")}>Pilih File</label>
                                                                                    </div>
                                                                                    <div className="col-sm-2 py-3" style={{ width: '30%' }}>
                                                                                        <div style={{ display: 'none' }} id="str" class="spinner-border text-primary col-form-label br-2" role="status">
                                                                                            <span class="sr-only">Loading...</span>
                                                                                        </div>
                                                                                        <a style={{ display: 'none', width:'50px' }} href="#0" target="_blank" rel="noreferrer" id="previewstr" className="btn btn-primary btn-sm" ><i className="fas fa-image"></i></a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Unggah Buku Bank </label>
                                                                                    <div className="col-sm-5" style={{ width: '70%' }}>
                                                                                         <input
                                                                                                onChange={(e) => uploadBank(e.target.files[0])}
                                                                                                id="custom-file-bank"
                                                                                                type="file"
                                                                                                name="file"
                                                                                                label="Upload Profile Picture"
                                                                                                style={{display: "none"}}
                                                                                        />
                                                                                        <label for="imageUpload" className="btn btn-outline-primary btn-block" onClick={() =>  $("#custom-file-bank").trigger("click")}>Pilih File</label>
                                                                                    </div>
                                                                                    <div className="col-sm-2 py-3" style={{ width: '30%' }}>
                                                                                        <div style={{ display: 'none' }} id="bank" class="spinner-border text-primary col-form-label br-2" role="status">
                                                                                            <span class="sr-only">Loading...</span>
                                                                                        </div>
                                                                                        <a style={{ display: 'none', width:'50px' }} href="#0" target="_blank" rel="noreferrer" id="previewbank" className="btn btn-primary btn-sm" ><i className="fas fa-image"></i></a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                        <div className="single-input ">
                                                                            <div className="form-group row">
                                                                                {/* <label htmlFor="first-name" className="col-sm-6 col-form-label">Riwayat Pendidikan <span>*</span></label> */}
                                                                                <strong className="mb-1">Unggah Sertifikat</strong>
                                                                                <div className="col-sm-12 alert alert-success">
                                                                                    <label htmlFor="first-name" style={{width: "50%"}} className="col-sm-4 col-form-label">Nama Pelatihan </label>
                                                                                    <label htmlFor="first-name" style={{width: "50%"}} className="col-sm-2 col-form-label">File </label>
                                                                                    {sertifikat.map((x, i) => {
                                                                                        return (
                                                                                            <div className="box row" key={i}>
                                                                                                <div className="col-sm-4" style={{width: "50%"}}>
                                                                                                    <input
                                                                                                        name="name"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        placeholder="Nama Pelatihan"
                                                                                                        value={x.name}
                                                                                                        onChange={e => handleInputChange(e, i)}
                                                                                                    />
                                                                                                </div>
                                                                                                
                                                                                                <div>
                                                                                                    {x.file !== "-" && <><a href={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/sertifikat/"+x.file} target="_blank" rel="noreferrer" id={"preview" + i} className="btn btn-primary btn-sm mb-2" ><i className="fas fa-image"></i></a>&nbsp;</>}
                                                                                                    <input
                                                                                                    className="btn btn-info btn-sm mb-2"
                                                                                                    onChange={(e) => handleUploadClick(e.target.files[0], i)}
                                                                                                    id={"custom"+i}
                                                                                                    type="file"
                                                                                                    name="file"
                                                                                                    style={{display: "none"}}
                                                                                                    label="Upload Profile Picture"
                                                                                                />
                                                                                                    <a href="#0"  className="btn btn-info btn-sm mb-2" onClick={() => handleClick(i)}><i id={"upload"+i} className="fas fa-upload"></i></a>&nbsp;

                                                                                                </div>
                                                                                                    {sertifikat.length !== 1 &&
                                                                                                        <span>
                                                                                                        <button className="btn btn-danger btn-sm mb-2" onClick={() => handleRemoveClick(i)}><i className="fas fa-trash-alt"></i></button>&nbsp;
                                                                                                        </span>
                                                                                                    }

                                                                                                    {sertifikat.length - 1 === i &&
                                                                                                    <>
                                                                                                        <button style={{ height: "100%"}} className="btn btn-warning btn-sm mb-2" onClick={handleAddClick}><i className="fas fa-plus-square"></i></button>
                                                                                                    </>}


                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="profile-action-btn d-flex flex-wrap align-content-center justify-content-between">
                                                                        <button className="btn btn-primary" type="submit"><i className="fas fa-save"></i> Perbaharui Lampiran</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {loadingUpdate && <Loading />}
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footers />
        </div>

    )
}
export default Edit;
