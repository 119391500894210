import React, { useState, useEffect } from "react";
import './ModalSepuluh.css';

function ModalSepuluh(props) {
    let showModal = props.show ? "show" : "";
    let styleModal = props.show ? "block" : "none";
    const calculateTimeLeft = () => {
        let timeout = new Date('2021-10-10');
        timeout.setHours(10);
        
        const difference = +timeout - +new Date();
        let timeLeft = {};
    
        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
          };
        } else {
          showModal = "none";
          styleModal = "";
        }
    
        return timeLeft;
      };
    
      const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
      // const [year] = useState(new Date().getFullYear());
    
      useEffect(() => {
        setTimeout(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
      });
    
      const timerComponents = [];
    
      Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
          // return;
          timeLeft[interval] = 0
        }
    
        timerComponents.push(
          <div className="Countdown-timer" key={interval}>
            <strong>{timeLeft[interval] < 10 ? "0" + timeLeft[interval] : timeLeft[interval]} </strong><br/><small>{interval}</small>{" "}
          </div>
        );
      });
  
    return (
        <div className={"modal fade bd-example-modal-lg " + showModal} tabIndex="-1" role="dialog" style={{display:styleModal}} aria-labelledby="myLargeModalLabel" aria-modal="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="close" onClick={props.click} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="sepuluh-header">
                            <p>
                                {/* We are coming ... */}
                                Enjoy our Preview!
                            </p>
                            <div className="sepuluh-timer">
                                {timerComponents.length ? timerComponents : <span>Time's up!</span>}
                            </div>
                            <div className="sepuluh-action">
                                <a href="/1010" className="btn btn-danger">
                                    Daftar Acara Perdana Kami
                                </a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

    )
}
export default ModalSepuluh;
