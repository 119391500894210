import axios from "axios";
  
export const getList = () => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
      .get('/api/v1/jobs/list', {
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      })
      .then(res => {
          res.data.status = 'success'
          return res.data
      }).catch(err => {
          return {
              error:'Please login again!',
              status:'failed',
              message:err.message
          }
      })
}

export const getjobListclient = params => {
  return axios
      .get(`/api/v1/jobsclient/list?home=${params.ishome}&&admin=false` , {
      headers: { 
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
          res.data.status = 'success'
          return res.data
      }).catch(err => {
          return {
              error:'Please login again!',
              status:'failed',
              message:err.message
          }
      })
}

export const CheckCandidate = id => {
  return axios
      .get(`/api/v1/auth/candidate/findbyjob/${id}` , {
      headers: { 
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
          res.data.status = 'success'
          return res.data
      }).catch(err => {
          return {
              error:'Please login again!',
              status:'failed',
              message:err.message
          }
      })
}

export const addCandidate = candidate => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .post(
      '/api/v1/candidate/add',
      {
        applydate:candidate.applydate,
        email:candidate.email,
        gelardepan:candidate.gelardepan,
        gelarbelakang:candidate.gelarbelakang,
        phone:candidate.phone,
        status:candidate.status,
        name:candidate.name,
        jobid:candidate.jobid,
        jobtitle:candidate.jobtitle
      },
      {
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': token['data']['token']
        }
      }
    )
    .then(function(response) {
        return response.data;
    }).catch(err => {
        return {
            error:'Error to add',
            status:'failed',
            message:err.message
        }
    })
}


  
export const addToList = job => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .post(
      '/api/v1/jobs/add',
      {
        jobtitle:job.jobtitle,
        jobtype:job.jobtype,
        postdate:job.postdate,
        expiredate:job.expiredate,
        companyname:job.companyname,
        joblocation:job.joblocation,
        jobdescription:job.jobdescription,
        priority:job.priority,
        url:job.url,
        ishome:job.ishome,
        active:job.active
      },
      {
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': token['data']['token']
        }
      }
    )
    .then(function(response) {
        return response.data;
    }).catch(err => {
        return {
            error:'Error to add',
            status:'failed',
            message:err.message
        }
    })
}


export const updateItem = job => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .put(
      `/api/v1/jobs/update/${job.id}`,
      {
        jobtitle:job.jobtitle,
        jobtype:job.jobtype,
        postdate:job.postdate,
        expiredate:job.expiredate,
        companyname:job.companyname,
        joblocation:job.joblocation,
        jobdescription:job.jobdescription,
        priority:job.priority,
        url:job.url,
        ishome:job.ishome,
        active:job.active
      },
      {
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': token['data']['token']
        }
      }
    )
    .then(function(response) {
        return response.data;
    })
}

export const getCity = () => {
  return axios
    .get(`https://syiham.xyz/city.php`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(function (response) {
      return response.data;
    }).catch(err => {
      return {
        error: 'Error to add',
        status: 'failed',
        message: err.message
      }
    })
}
export const deleteItem = (id) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .delete(`/api/v1/jobs/delete/${id}`, {
      headers: { 
            'Content-Type': 'application/json',
            'Authorization': token['data']['token']
        }
    })
    .then(function(response) {
      return response.data;
  }).catch(err => {
      return {
          error:'Error to add',
          status:'failed',
          message:err.message
      }
  })
}

export const getCandidatebyemail = () => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .get(`/api/v1/auth/candidate/findbyjob/email/${JSON.parse(localStorage.getItem('info')).email}`, {
      headers: { 
            'Content-Type': 'application/json',
            'Authorization': token['data']['token']
        }
    })
    .then(function(response) {
      return response.data;
  }).catch(err => {
      return {
          error:'Error to add',
          status:'failed',
          message:err.message
      }
  })
}

export const SendmailLamaranditinjau = () => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  var job = JSON.parse(localStorage.getItem('payloadjob'));

  return axios
    .post(
      `/api/v1/events/sendmail`,
      {
        email:JSON.parse(localStorage.getItem('info')).email,
        subject:"Lamaran Ditinjau",
        body:`<center><img width='50%' src='https://res.cloudinary.com/syiham-group/image/upload/v1634292073/digihealth/logo-digihealth_y4dgtt.png'><img/></center><br/><br/><br/><center><h3>Lamaran Ditinjau</h3><p>Hi<strong> ${JSON.parse(localStorage.getItem('info')).gelardepan ? JSON.parse(localStorage.getItem('info')).gelardepan + '.' : ''} ${JSON.parse(localStorage.getItem('info')).name} ${', ' + JSON.parse(localStorage.getItem('info')).gelarbelakang} </strong></p><p>Terima kasih karena telah mendaftar pada posisi:</p><p>${job[0]['name']}</p><p>${job[0]['company']} - ${job[0]['location']}</p><p>${job[0]['type']}</p><p>Saat ini lamaran Anda sedang kami tinjau, untuk hasil peninjauan lamaran Anda akan kami beritahukan paling lama 14 hari kerja sejak tanggal Anda mengajukan lamaran.</p><br>${job[0]['psychotest'] !== '-' && job[0]['psychotest'] !== undefined ? "<p>Silahkan klik tombol di bawah ini untuk melakukan psikotes</p><a href='"+job[0]['psychotest']+"' style='background-color: #008CBA; /* Green */ border: none;color: white;padding: 15px 32px;text-align: center;text-decoration: none;display: inline-block;font-size: 16px;' target='_blank' >Psikotes</a>" :''}<br><br><br><strong>DigiHealth <br> LEARN AND EARN TOGETHER</strong></center>`
      },
      {
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': token['data']['token']
        }
      }
    )
}
  