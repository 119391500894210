import React, { useEffect, useState } from "react";
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../components/Loading";
import { updateUserAction, resendverifikasi,  upload, deletecontent } from "../../actions/userActions";
import ErrorMessage from "../../components/ErrorMessage";
import { NavLink } from 'react-router-dom';
import { getList } from "../../actions/professionAction";
import { getListbyuser, updateProfileparticipant } from "../../actions/eventActions";
import $ from 'jquery';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const font = {
    fontSize: '12px',
}
const margin ={
    marginRight: "20px"
}

function Edit({ history }) {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [gelardepan, setGelardepan] = useState("");
    const [gelarbelakang, setGelarbelakang] = useState("");
    const [tlp, setTlp] = useState("");
    const [verifikasi, setVerifikasi] = useState("");
    const [nip, setNip] = useState("");
    const [email, setEmail] = useState("");
    const [str, setStr] = useState("");
    const [npkidi, setNpkidi] = useState("");
    const [nik, setNik] = useState("");
    const [tempatlahir, setTempatlahir] = useState("");
    const [tanggallahir, setTanggallahir] = useState("");
    const [profesi, setProfesi] = useState("-");
    const [spesialisasi, setSpesialisasi] = useState("-");
    const [consltant, setConsltant] = useState("-");
    const [address, setAddress] = useState([]);
    const [education, setEducation] = useState([]);
    const [jobs, setJobs] = useState([]);
    const userUpdate = useSelector((state) => state.userUpdate);
    const userLogin = useSelector((state) => state.userLogin);
    const [listspelialisasi, setListspelialisasi] = useState([]);
    const [listconsultant, setListconsultant] = useState([]);
    const [listpropesi, setListpropesi] = useState([]);
    const [event, setEvent] = useState([]);
    const [sertifikat, setSertifikat] = useState([{ name: "", file: "-" }]);
    const [cv, setCv] = useState("");
    const [strfile, setStrfile] = useState("");
    const [bank, setBank] = useState("");
    const [profile, setProfile] = useState("");
    const bucket = process.env.REACT_APP_API_KEY

    const {
        loading: loadingUpdate,
        error: errorUpdate,
        success: successUpdate,
    } = userUpdate;
    const { userInfo } = userLogin;
    const getevents = () => {
      
        getListbyuser().then(res => {
           const data = res['data'] ? res['data'] : [];
        //    let newDate = new Date()
        //    let date = newDate.getDate();
        //    let month = newDate.getMonth() + 1;
        //    let year = newDate.getFullYear();
        //    let separator = "-"
        //    let datenow = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
        //    const filterData = data.filter(item => item.date > datenow)
           setEvent([...data])

        })
    }
    const prop = () => {
        getList().then(res => {
            const data = res['data'] ? res['data'] : [];
            const filterData = data.filter(item => (true ? item.level1 === true : item.level1) &&  (item.active === true))
            setListpropesi([...filterData])

        })
    }

    const handleDeleteProfile = () => {
       if (profile !== null || profile !== undefined || profile !== "-")
       {
            document.getElementById("profile").src = "http://prosehat-digihealth.s3.us-east-2.amazonaws.com/prod/profile/2263a021-a46c-4bf1-9210-e47dada02735.png"
            const deleteRequest = {
                path: bucket+'/profile',
                file: profile
            }
           deletecontent(deleteRequest)
           setProfile("-");
        } 
    }
    const handleUploadProfile = (pics) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png")) {
             document.getElementById("uploadprofile").classList.remove("fa-upload");
             document.getElementById("uploadprofile").classList.add("fa-spinner", "fa-spin");
            if (profile !== null && profile !== undefined)
            {
               const deleteRequest = {
                    path: bucket+'/profile',
                    file: profile
                }
                deletecontent(deleteRequest)
            }

            const profileRequest = {
                path: bucket+'/profile',
                myFile: pics
            }
            upload(profileRequest).then((res) => {
                
                document.getElementById("uploadprofile").classList.remove("fa-spinner", "fa-spin");
                document.getElementById("uploadprofile").classList.add("fa-upload");
                setProfile(res.data);
                document.getElementById("profile").src = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/profile/"+res.data

            }).catch((err) => {
                document.getElementById("uploadprofile").classList.remove("fa-spinner", "fa-spin");
                document.getElementById("uploadprofile").classList.add("fa-upload");
                MySwal.fire('Oops...', 'sorry server busy please tray again' , 'info')
            })

        } else {

            document.getElementById("uploadprofile").classList.remove("fa-spinner", "fa-spin");
            document.getElementById("uploadprofile").classList.add("fa-upload");
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type atau ukuran file yang anda masukan tidak cocok', 'info')
            
        }
    }


    const kirimverifikasi = () => {
        document.getElementById("verifikasiemail").style.display = 'none';
        document.getElementById("loadresend").style.display = 'block';

        resendverifikasi().then(res => {
            if (res.code === 200) {
                document.getElementById("verifikasiemail").style.display = 'none';
                document.getElementById("loadresend").style.display = 'none';
                document.getElementById("successresend").innerHTML = 'Email verifikasi berhasil dikirim';
                
            } else {
                document.getElementById("loadresend").style.display = 'none';           
                document.getElementById("verifikasiemail").style.display = 'block';
            }
        })
    }

    const handleChange = (evt) => {
        var index = evt.target.selectedIndex;
        var optionElement = evt.target.childNodes[index]
        var option = optionElement.getAttribute('data-order');
        setSpesialisasi("-");
        setConsltant("-");
        filterspesialisasi(option)
        setProfesi(evt.target.value)
    }

    const handleChangeconsul = (evt) => {
        var index = evt.target.selectedIndex;
        var optionElement = evt.target.childNodes[index]
        var option = optionElement.getAttribute('data-order');
        setConsltant("-");
        filterlistconsultant(option)
        setSpesialisasi(evt.target.value)
    }

    const checkonloadspesialisaisi = (key) => {
        getList().then(res => {
            const data = res['data'] ? res['data'] : [];
            const filterData = data.filter(item => (key ? item.name === key : []) && (item.active === true) && (item.level1 === true))
           if(filterData.length !== 0){ filterspesialisasi(filterData[0]['id'])}
        })
    }
    const checkonloadconsltant = (key) => {
        getList().then(res => {
            const data = res['data'] ? res['data'] : [];
            const filterData = data.filter(item => (key ? item.name === key : []) &&  (item.active === true) && (item.level2 === true))
            if(filterData.length !== 0){ filterlistconsultant(filterData[0]['id'])}
        })
    }

    const filterspesialisasi = (key) => {
        if(key !== null){
        getList().then(res => {
            const data = res['data'] ? res['data'] : [];
            const filterData = data.filter(item => (key ? item.parent === key : item.parent) &&  (item.active === true))
            setListspelialisasi([...filterData])
            setListconsultant([])
        })
        }else{
            setListspelialisasi([])
            setListconsultant([])
        }
    }
    const filterlistconsultant = (key) => {
        if(key !== null){
        getList().then(res => {
            const data = res['data'] ? res['data'] : [];
            const filterData = data.filter(item => (key ? item.parent === key : item.parent) &&  (item.active === true))
            setListconsultant([...filterData])

        })}else{
            setListconsultant([]) 
        }
    }

    useEffect(() => {
        if (userInfo) {
            history.push("/memberdetailprofile");
            var prod = JSON.parse(localStorage.getItem('info'));
        prop();
        getevents();
        setName(prod['name']);
        setGelardepan(prod['gelardepan']);
        setGelarbelakang(prod['gelarbelakang']);
        setTlp(prod['tlp']);
        setEmail(prod['email']);
        setNip(prod['nip']);
        setStr(prod['str']);
        setNpkidi(prod['npkidi']);
        setNik(prod['nik']);
        setTempatlahir(prod['tempatlahir']);
        setTanggallahir(prod['tanggallahir']);
        setProfesi(prod['profesi']);
        setSpesialisasi(prod['spesialisasi']);
        setConsltant(prod['consltant']);
        setVerifikasi(prod['verifikasi']);
        setProfile(prod['profile']);
        checkonloadspesialisaisi(prod['profesi']);
        checkonloadconsltant(prod['spesialisasi']);
        if (prod['address'].length !== 0) {
            setAddress(prod['address']);
        }
        if (prod['education'].length !== 0) {
            setEducation(prod['education']);
        }
        if (prod['jobs'].length !== 0) {
            setJobs(prod['jobs']);
        }
        if(prod['cv'] !== undefined){
                setCv(prod['cv']);
        }
        if(prod['strfile'] !== undefined){
            setStrfile(prod['strfile']);
        }
        if(prod['bank'] !== undefined){
            setBank(prod['bank']);
        }
        var sert= prod['sertifikat'] ? prod['sertifikat'] : []
        if(sert.length !== 0  ){
            setSertifikat(prod['sertifikat']);
        }
    }else{
    history.push("/login");
    }
          
       
    }, [
        history,
        userInfo,
        successUpdate,
    ]);

    const resetHandler = () => {
        setName("");
        setGelardepan("");
        setGelarbelakang("");
        setTlp("");
        setEmail("");
        setNip("");
        setStr("");
        setNpkidi("");
        setNik("");
        setTempatlahir("");
        setTanggallahir("");
        setProfesi("-");
        setSpesialisasi("-");
        setConsltant("-");
        setAddress([]);
        setEducation([]);
        setJobs([]);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateUserAction(name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs, strfile, bank, cv, sertifikat, profile));
        var member = JSON.parse(localStorage.getItem('userInfo'));
        var idmember = member['data']['user']['id']
        event.map((value) => {
            console.log(value.paticipans)
            var data = [...value.paticipans]
            for (let index = 0; index < data.length; index++) {
                if(idmember === data[index]['idmember']){
                    const UpdateRequest = {
                    id: data[index]["nomorpendaftaran"],
                    nama: name,
                    paticipans: data,
                    gelarbelakang: gelarbelakang,
                    gelardepan: gelardepan,
                    email: email,
                    tlp: tlp
                }
                updateProfileparticipant(UpdateRequest)
            }
                
            }
            data = []
            // console.log(UpdateRequest)

            return value;
        })
        resetHandler();
        history.push("/memberdetailprofile");
    };


    return (
        <div id="main-wrapper">
            <Headers />
            <Headermobile />
            <Menumobile />
            <BottomNavbarMobile />
            <BottomNavbarMobilePopup />

            <div className="dashboard-content-section section bg_color--5">
                <div className="container-fluid p-0">
                    <div className="row no-gutters">
                        {/* <Menudashboard /> */}
                        <div className="col-xl-12 col-lg-9">
                            <div className="dashboard-main-inner">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-breadcrumb-content mb-40">
                                        <h4>&nbsp;</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-overview">
                                    <div className="row">
                                        <div className="col-xl-12 col-12">
                                            <div className="profile-applications mb-50">
                                                <div className="profile-applications-heading">
                                                    <ul className="nav" >
                                                        <li key="1" style={margin}><NavLink to="/memberdetailprofile" style={font}>Profile</NavLink></li>
                                                        <li key="2" style={margin}><NavLink to="/daftar_activity_admin" style={font}>Aktivitas</NavLink></li>
                                                        <li key="3" style={margin}><NavLink to="/listaddress" style={font}>Alamat</NavLink></li>
                                                        <li key="4" style={margin}><NavLink to="/listpendidikan" style={font}>Pendidikan</NavLink></li>
                                                        <li key="5" style={margin}><NavLink to="/listpekerjaan" style={font}>Pekerjaan</NavLink></li>
                                                        <li key="6" style={margin}><NavLink to="/attachmentFile" style={font}>Lampiran</NavLink></li>
                                                    </ul>
                                                </div>
                                                {errorUpdate && <ErrorMessage variant="danger">{errorUpdate}</ErrorMessage>}
                                                <div className="profile-applications-main-block">
                                                    <div className="profile-applications-form">
                                                        <form onSubmit={submitHandler}>
                                                            <div className="row mb-30">
                                                                <div className="col-lg-12">
                                                                    <div className="row">
                                                                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <strong className="mb-5">Profile Umum</strong><br/><br/>
                                                                            <div className="form-group row">
                                                                                    <div className="col-sm-2">
                                                                                        {(() => {
                                                                                            if (profile === undefined || profile === "-") {
                                                                                                return (
                                                                                                    <><img src="http://prosehat-digihealth.s3.us-east-2.amazonaws.com/prod/profile/2263a021-a46c-4bf1-9210-e47dada02735.png" id="profile" alt="profile" className="rounded-circle" height="100px" /></>
                                                                                                )
                                                                                            } else if (profile !== undefined || profile !== "-") {
                                                                                                return (
                                                                                                    <><img src={"http://prosehat-digihealth.s3.us-east-2.amazonaws.com/" + bucket + "/profile/" + profile} id="profile" alt="profile" className="rounded-circle" height="100px" /></>
                                                                                                )
                                                                                            }
                                                                                        })()}
                                                                                    </div>
                                                                                <input
                                                                                    onChange={(e) => handleUploadProfile(e.target.files[0])}
                                                                                    id="custom-file-profile"
                                                                                    type="file"
                                                                                    name="file"
                                                                                    label="Upload Profile Picture"
                                                                                    style={{display: "none"}}
                                                                                />
                                                                                <div className="col-sm-4 py-4">
                                                                                    <button type="button" className="btn btn-outline-primary" onClick={() =>  $("#custom-file-profile").trigger("click")}><i className="fas fa-cloud-upload-alt" id="uploadprofile"></i> Upload</button>&nbsp;&nbsp;
                                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => handleDeleteProfile()}> <i className="fas fa-trash-alt" id="deleteprofile"></i> Remove</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Nama Lengkap <span>*</span></label>
                                                                                    <div className="col-sm-10">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={name}
                                                                                            name="name"
                                                                                            placeholder="Masukkan Nama Lengkap"
                                                                                            onChange={(e) => setName(e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Gelar Depan </label>
                                                                                    <div className="col-sm-10">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={gelardepan}
                                                                                            name="gelardepan"
                                                                                            placeholder="Masukkan Gelar Depan"
                                                                                            onChange={(e) => setGelardepan(e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Gelar Belakang </label>
                                                                                    <div className="col-sm-10">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={gelarbelakang}
                                                                                            name="gelarbelakang"
                                                                                            placeholder="Masukkan Gelar Belakang"
                                                                                            onChange={(e) => setGelarbelakang(e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Email </label>
                                                                                    <div className="col-sm-4" >
                                                                                        <input
                                                                                            type="email"
                                                                                            className="form-control"
                                                                                            value={email}
                                                                                            readOnly={true}
                                                                                            name="email"
                                                                                            placeholder="Masukan email"
                                                                                            onChange={(e) => setEmail(e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-sm-3" style={{width:"230px", fontSize:"12px"}}>
                                                                                        {verifikasi === "true" || verifikasi === true ?
                                                                                            <div className="col-form-label">
                                                                                                Akun Terverifikasi
                                                                                            </div>
                                                                                            : null}
                                                                                        {verifikasi === "false" || verifikasi === "" || verifikasi === null || verifikasi === false || verifikasi === undefined ?
                                                                                            <label className="col-form-label" id="successresend">
                                                                                                Akun Belum Terverifikasi
                                                                                            </label>
                                                                                            : null}
                                                                                    </div>
                                                                                    <div className="col-sm-2" style={{ width: "190px" }}>
                                                                                        <div style={{ display: 'none' }} id={"loadresend"} class="spinner-border text-primary col-form-label br-1" role="status">
                                                                                            <span class="sr-only">Loading...</span>
                                                                                        </div>
                                                                                         {verifikasi === "false" || verifikasi === "" || verifikasi === null || verifikasi === false || verifikasi === undefined ?
                                                                                                <button type="button" id="verifikasiemail" className="btn btn-primary btn-sm" onClick={kirimverifikasi.bind(this)}>Kirim Email Verifikasi</button>
                                                                                            : null}
                                                                                     </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Nomor Telpon </label>
                                                                                    <div className="col-sm-10">
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            value={tlp}
                                                                                            name="tlp"
                                                                                            placeholder="Masukkan Nomor Telpon "
                                                                                            onChange={(e) => setTlp(e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <strong className="mb-1">Profile Pribadi</strong>
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">NIP </label>
                                                                                    <div className="col-sm-10">
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            value={nip}
                                                                                            name="nip"
                                                                                            placeholder="Masukan NIP "
                                                                                            onChange={(e) => setNip(e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">STR </label>
                                                                                    <div className="col-sm-10">
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            value={str}
                                                                                            name="str"
                                                                                            placeholder="Masukan STR "
                                                                                            onChange={(e) => setStr(e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">NPA IDI </label>
                                                                                    <div className="col-sm-10">
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            value={npkidi}
                                                                                            name="npkidi"
                                                                                            placeholder="Masukan NPA IDI"
                                                                                            onChange={(e) => setNpkidi(e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">NIK / Passport </label>
                                                                                    <div className="col-sm-10">
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            value={nik}
                                                                                            name="nik"
                                                                                            placeholder="Masukkan NIK / Passport"
                                                                                            onChange={(e) => setNik(e.target.value)}
                                                                                        />

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Tempat Lahir </label>
                                                                                    <div className="col-sm-10">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={tempatlahir}
                                                                                            name="tempatlahir"
                                                                                            placeholder="Masukan Tempat Lahir"
                                                                                            onChange={(e) => setTempatlahir(e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Tanggal Lahir </label>
                                                                                    <div className="col-sm-10">
                                                                                        <input
                                                                                            type="date"
                                                                                            className="form-control"
                                                                                            value={tanggallahir}
                                                                                            name="tanggallahir"
                                                                                            placeholder="Masukan Tanggal Lahir"
                                                                                            onChange={(e) => setTanggallahir(e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Profesi </label>
                                                                                    <div className="col-sm-10">
                                                                                        <select className="form-control" name="profesi" value={profesi} onChange={handleChange}>
                                                                                            <option value="">first Profesi</option>
                                                                                            {listpropesi
                                                                                                .map((one, index) => (
                                                                                                    <option value={one.name} data-order={one.id}>{one.name}</option>
                                                                                                ))}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Spesialisasi </label>
                                                                                    <div className="col-sm-10">
                                                                                        <select className="form-control" name="spesialisasi" value={spesialisasi} onChange={handleChangeconsul}>
                                                                                            <option value="-">first Spesialisasi</option>
                                                                                            {listspelialisasi
                                                                                                .map((one, index) => (
                                                                                                    <option value={one.name} data-order={one.id}>{one.name}</option>
                                                                                                ))}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="single-input ">
                                                                                <div className="form-group row">
                                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Sub-Spealisasi </label>
                                                                                    <div className="col-sm-10">
                                                                                        <select className="form-control" name="consltant" value={consltant} onChange={(e) => setConsltant(e.target.value)}>
                                                                                            <option value="-">first Sub-Spesialisasi</option>
                                                                                            {listconsultant
                                                                                                .map((one, index) => (
                                                                                                    <option value={one.name} data-order={one.id}>{one.name}</option>
                                                                                                ))}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                      
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="profile-action-btn d-flex flex-wrap align-content-center justify-content-between">
                                                                        <button className="btn btn-primary" type="submit"><i className="fas fa-save"></i> Perbaharui Profile</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {loadingUpdate && <Loading />}
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footers />
        </div>

    )
}
export default Edit;
