import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { setTitle, setDescription } from "../../actions/userActions";
import { Accordion, Card } from "react-bootstrap";
// import './faq.css';


export default class App extends Component {
  componentDidMount() {
    setTitle('Frequently Asked Questions')
    setDescription('Kami berkomitmen untuk membangun komunitas tenaga kesehatan dengan selalu meningkatkan kualitas dan membantu tenaga kesehatan menemukan kesempatan kerja yang spesifik di dunia kesehatan.')
  }
  render() {
    return (
      <div id="main-wrapper">
        <Headers />
        <Headermobile />
        <Menumobile />
        <BottomNavbarMobile />
        <BottomNavbarMobilePopup />
        <div className="breadcrumb-section section bg_color--5 pt-60 pt-sm-50 pt-xs-40 pb-30 pb-sm-50 pb-xs-40">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-breadcrumb-content">
                  {/* <ul className="page-breadcrumb">
                    <li><a href="/">Beranda</a></li>
                    <li>Faq </li>
                  </ul> */}
                  <br/><br/>
                  <h1>Frequently Asked Questions</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="job-listing-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-12 order-lg-2 order-1">
                <div className="tab-content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="single-job-grid">
                        <nav className="py-4">
                          <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true" style={{ fontSize: "12px" }}> Registrasi</a>
                            <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false" style={{ fontSize: "12px" }}>Keanggotaan</a>
                            <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false" style={{ fontSize: "12px" }}>Learn</a>
                            <a className="nav-item nav-link" id="nav-earn-tab" data-toggle="tab" href="#nav-earn" role="tab" aria-controls="nav-earn" aria-selected="false" style={{ fontSize: "12px" }}>Earn</a>
                          </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                          <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <strong>A. Registrasi dan Akses</strong><br/><br/>
                            <Accordion defaultActiveKey="0">
                              <Card>
                                <Accordion.Toggle as={Card.Header} aria-expanded="true" aria-controls="panelsStayOpen-collapseOne" variant="link" eventKey="0">
                                Apa itu Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="0">
                                  <Card.Body>Digihealth.id merupakan platform bagi tenaga kesehatan untuk saling berkoneksi satu sama lain, dengan tagline “Learn and Earn Together”. “Learn” berarti para tenaga kesehatan dapat selalu meningkatkan pengetahuan dengan berpartisipasi dalam berbagai webinar dan pelatihan kesehatan secara daring, tentunya berkesempatan untuk mendapatkan SKP. “Earn” berarti para tenaga kesehatan bisa mendapatkan pekerjaan sesuai dengan minat dan sertifikasi yang dimiliki.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                  Siapa saja yang boleh bergabung di Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="1">
                                  <Card.Body>Seluruh tenaga kesehatan di Indonesia bisa bergabung di Digihealth, mulai dari dokter umum, dokter spesialis, dokter gigi umum, dokter gigi spesialis, dokter hewan, bidan, perawat, farmasi (apoteker & tenaga teknis kefarmasian), tenaga kesehatan masyarakat & lingkungan, nutrisionis & dietisien, dan psikolog klinis. Tidak menutup kemungkinan untuk cakupan tenaga medis yang lebih luas bisa bergabung di Digihealth.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                  Apa keuntungan bergabung / mendaftar di Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="2">
                                  <Card.Body>Anda akan dipermudah dengan hanya memiliki satu akun yang dapat digunakan untuk berpartisipasi dalam berbagai webinar serta melamar pekerjaan melalui Digihealth.id, tentunya dilengkapi dengan dashboard pribadi untuk mengunduh materi dan sertifikat dari acara yang sudah diikuti.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                  Bagaimana cara mendaftar di Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="3">
                                  <Card.Body>Cara mendaftar di Digihealth sangat mudah, yaitu dengan.
                                    <ul>
                                      <li>1. Masuk ke digihealth.id lalu klik “Login” di pojok kanan atas layar dan pilih “Register”.</li>
                                      <li>2. Masukkan Email aktif yang akan didaftarkan dan masukkan password yang akan digunakan untuk akun Digihealth Anda.</li>
                                      <li>3. Pesan verifikasi akan masuk inbox ke Email yang didaftarkan (jangan lupa periksa Spam / Junk) dan ikuti langkah yang tertera untuk verifikasi</li>
                                      <li>4. Kembali ke halaman Login di digihealth.id dan masukkan Email dan Password yang sudah didaftarkan.</li>
                                    </ul>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                  Apakah kita perlu bayar untuk bergabung dalam Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="4">
                                  <Card.Body>Anda tidak perlu membayar biaya untuk menjadi anggota Digihealth. Sementara, untuk berpartisipasi dalam webinar atau pelatihan kesehatan akan dicantumkan pada deskripsi acara apakah acara tersebut gratis atau berbayar.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="5">
                                  Apakah kita bisa mengakses Digihealth melalui browser handphone atau aplikasi?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="5">
                                  <Card.Body>Anda bisa mengakses Digihealth menggunakan browser apapun baik di komputer, laptop, tablet, maupun handphone. Sekarang aplikasi Digihealth belum tersedia.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="6">
                                  Bagaimana bila saya lupa password akun saya?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="6">
                                  <Card.Body>Bila lupa password yang Anda buat saat membuat akun, silahkan ikuti langkah berikut :
                                    <ul>
                                      <li>1. Klik “Lupa Password” di halaman Login Digihealth</li>
                                      <li>2. Masukkan Email yang sudah didaftarkan untuk bergabung di Digihealth sebelumnya</li>
                                      <li>3. Pesan akan masuk inbox ke Email yang didaftarkan (jangan lupa periksa Spam / Junk) dan ikuti langkah yang tertera untuk “Reset Password”</li>
                                      <li>4. Buat password baru</li>
                                      <li>5. Kembali ke halaman Login di digihealth.id dan masukkan Email yang sudah terdaftar serta Password yang baru</li>
                                    </ul>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="7">
                                  Apakah saya wajib upload file / scan CV, STR, dan Ijazah saya?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="7">
                                  <Card.Body>Saat melengkapi data yang diperlukan di awal, Anda belum perlu upload file / scan CV, STR dan Ijazah. Anda bisa berpartisipasi dalam berbagai webinar dan pelatihan tanpa perlu upload file / scan CV, STR dan Ijazah sebelumnya. Namun, bila Anda berencana untuk melamar di pekerjaan yang diposting oleh Digihealth, Anda tidak akan bisa melamar sebelum Anda upload file / scan CV, STR dan Ijazah Anda.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="8">
                                  Apakah saya boleh ajak teman Anda saya untuk bergabung di Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="8">
                                  <Card.Body>Silahkan ajak teman Anda Anda sebanyak-banyaknya untuk bergabung di Digihealth dan mengikuti akun sosial media Digihealth yaitu Instagram : @digihealth_ID</Card.Body>
                                </Accordion.Collapse>
                              </Card>
                              
                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="9">
                                  Bagaimana cara verifikasi akun saya?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="9">
                                  <Card.Body>Klik “Kirim Email Verifikasi” di samping kolom “Email” pada halaman Akun Anda. Link verifikasi akan tekririm ke Email Anda (jangan lupa periksa Spam / Junk) dan ikuti langkah selanjutnya</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                            </Accordion>
                          </div>
                          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                          <strong>B. Keanggotaan</strong><br/><br/>
                          <Accordion defaultActiveKey="0">
                              <Card>
                                <Accordion.Toggle as={Card.Header} aria-expanded="true" aria-controls="panelsStayOpen-collapseOne" variant="link" eventKey="0">
                                    Apakah saya bisa berkomunikasi dengan tenaga kesehatan lainnya yang juga bergabung pada Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="0">
                                  <Card.Body>Setelah melakukan registrasi di Digihealth, Anda akan mendapatkan link untuk bergabung di grup Telegram Digihealth dimana Anda bisa saling bertukar informasi, menanyakan pendapat, membahas isu / jurnal kesehatan, tentunya dengan selalu menaati peraturan grup seperti tidak berbicara kasar dan menjaga sopan santun.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                    Apakah akan ada pemberitahuan kepada saya bila ada webinar atau job posting baru di Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="1">
                                  <Card.Body>Bila Anda sudah membuat akun di Digihealth, akan ada pesan pemberitahuan ke Email Anda (jangan lupa periksa Spam / Junk) setiap ada posting mengenai webinar atau pelatihan kesehatan, dan posting pekerjaan terbaru di Digihealth.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                    Apakah ada syarat untuk mempertahankan keanggotaan, misalnya harus mengikuti sekian jumlah webinar?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="2">
                                  <Card.Body>Tidak ada syarat harus mengikuti sekian jumlah webinar / pelatihan maupun melamar sekian jumlah pekerjaan yang diposting di Digihealth, namun sangat disarankan untuk Anda bisa aktif dan berpartisipasi dalam acara yang diselenggarakan dan melamar pekerjaan yang diposting di Digihealth.</Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                        
                          </div>
                          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                          <strong>C. “Learn” - Webinar, Workshop, Pelatihan Online</strong><br/><br/>
                          <Accordion defaultActiveKey="0">
                              <Card>
                                <Accordion.Toggle as={Card.Header} aria-expanded="true" aria-controls="panelsStayOpen-collapseOne" variant="link" eventKey="0">
                                    Bagaimana cara daftar webinar di Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="0">
                                  <Card.Body>Caranya sangat mudah yaitu membuat akun di Digihealth (lihat poin A.5), setelah itu ikuti langkah berikut
                                    <ul>
                                        <li>1. Klik “Event” di bagian paling atas dan akan muncul event yang sudah lewat maupun yang akan datang</li>
                                        <li>2. Anda bisa filter sesuai dengan tipe event atau langsung scroll dan lihat semua event yang ada</li>
                                        <li>3. Lihat detail di masing-masing event dan klik “Read More” untuk membaca keterangan lebih lanjut dan daftar di event tersebut.</li>
                                        <li>4. Klik “Daftar Event”</li>
                                        <li>5. Bila belum melengkapi profil sebelumnya Anda akan diminta untuk melengkapi profil terlebih dahulu, lalu kembali lagi ke halaman event dan klik ulang “Daftar Event”</li>
                                        <li>6. Bila sudah melengkapi profil sebelumnya akan muncul halaman bahwa Anda berhasil mendaftarkan diri Anda ke event yang diinginkan dan akan muncul nomor registrasi, Zoom Meeting ID dan Passcode. Jangan khawatir, Anda juga akan mendapatkan email bahwa Anda sudah berhasil daftar event sehingga Meeting ID dan Passcode juga bisa ditemukan di inbox email Anda (jangan lupa periksa Spam / Junk) </li>
                                    </ul>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                  Bagaimana cara mengunduh sertifikat dari webinar yang sudah saya ikuti?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="1">
                                  <Card.Body>
                                  Bagaimana cara mengunduh sertifikat dari webinar yang sudah saya ikuti?
                                  <ul>
                                    <li>1. Setelah Login, klik profil Anda di ujung kanan atas dan pilih “Activity”</li>
                                    <li>2. Klik judul webinar / pelatihan yang sudah diikuti</li>
                                    <li>3. Bila sertifikat sudah tersedia, akan muncul tombol “Download Sertifikat”</li>
                                    <li>4. Bila sertifikat belum tersedia, akan muncul tombol “Sertifikat Belum Tersedia”. Harap tunggu hingga 1-2 minggu setelah acara selesai. Hubungi pihak Digihealth bila sertifikat belum tersedia setelah 2 minggu setelah acara selesai atau di luar dari durasi yang disampaikan sebelumnya saat acara berlangsung.</li>
                                    <li>5. Info lebih lengkap silahkan ikuti video tutorial di link berikut : <br/>
                                          - Link : http://s.id/sertifikatdigihealth <br/>
                                          - Link Alternatif : https://drive.google.com/drive/folders/1oD0uFmz566R2Vzucg0dWpXls8Smy49IU?usp=sharing
                                    </li>
                                  </ul>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                    Apakah saya akan mendapatkan SKP dari webinar di Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="2">
                                  <Card.Body>Hal ini bervariasi tergantung dengan acara yang diselenggarakan. Beberapa acara mungkin terdapat SKP dan beberapa acara mungkin tidak. Keterangan ini bisa dilihat pada detail acara. Tentunya, kami yakin acara yang diselenggarakan akan tetap bermanfaat untuk Anda semua baik dengan SKP maupun tanpa SKP.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                Bagaimana bila saya tidak bisa ikut webinar dari awal hingga akhir?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="3">
                                  <Card.Body>Webinar dengan SKP biasanya memiliki syarat Post-Test dengan nilai minimal tertentu agar Anda bisa mendapatkan sertifikat ber-SKP, dan beberapa webinar / pelatihan juga memiliki persentase kehadiran minimal sebagai persyaratan. Hal ini bisa dinilai melalui kehadiran on-camera (peserta harus menyalakan kamera selama acara) maupun kehadiran off-camera yang bisa dibaca secara otomatis oleh sistem Zoom.<br/>Namun tentunya syarat-syarat ini semua tergantung dari setiap acara dan bisa bervariasi, dimana keterangannya dapat dilihat pada detail acara.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                Bagaimana bila saya tidak hadir webinar yang sudah saya daftarkan karena ada urusan mendadak?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="4">
                                  <Card.Body>Seperti poin C.4 di atas, bila tidak memenuhi syarat yang bervariasi tiap masing-masing acara termasuk kehadiran, tidak akan mendapatkan sertifikat.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="5">
                                Apakah akan ada reminder menjelang hari H webinar yang sudah saya daftarkan?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="5">
                                  <Card.Body>Kami akan mengirimkan email reminder untuk membantu mengingatkan Anda yang sudah daftar event webinar / pelatihan H-7, H-1, dan di pagi hari saat hari H event (jangan lupa periksa Spam / Junk).</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="6">
                                  Apakah saya bisa berbagi di Digihealth mengenai informasi webinar / workshop / pelatihan online yang diselenggarakan oleh pihak lain selain Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="6">
                                  <Card.Body>Saat ini Anda belum bisa posting di website digihealth.id mengenai informasi webinar / workshop / pelatihan online yang diselenggarakan oleh pihak lain, namun Anda bisa berbagi informasi mengenai acara tersebut di grup Telegram Digihealth untuk saling berbagi ke teman Anda lainnya, tentunya dengan selalu menaati peraturan grup seperti tidak berbicara kasar dan menjaga sopan santun.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="7">
                                  Mengapa sertifikat acara yang saya ikuti belum muncul? 
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="7">
                                  <Card.Body>
                                      Pastikan Anda sudah mengikuti langkah-langkah berikut ini :
                                      <ul>
                                        <li>1. Pastikan Anda sudah mengisi formulir absensi dan evaluasi pada akhir acara, termasuk post-test (bila ada). Sertifikat tidak disediakan untuk Anda yang tidak mengisi formular tersebut. Maksimal pengisian formulir pada pukul 23.59 di hari acara yang Anda ikuti.</li>
                                        <li>2. Pastikan Anda sudah mengisi profil Anda di DigiHealth.ID dengan lengkap dan melakukan verifikasi akun</li>
                                       </ul> 
                                       <br/>
                                       Setelah memastikan kedua poin tersebut silahkan ikuti langkah download sertifikat pada poin C.2.
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="8">
                                    Mengapa terdapat kesalahan pada gelar / nama saya pada sertifikat acara yang saya ikuti?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="8">
                                  <Card.Body>
                                    <ul>
                                        <li>1. Anda bisa kembali ke halaman profil Anda dan mengganti gelar dan nama lengkap Anda yang sesuai dan klik simpan.</li>
                                        <li>2. Setelah itu Anda bisa kembali ke halaman acara yang Anda ikuti dan lakukan Refresh pada halaman tersebut</li>
                                        <li>3. Klik kembali pada tombol “Download Sertifikat” dan sertifikat baru akan sesuai dengan gelar serta nama yang terakhir Anda edit</li>
                                        <li>4. Bila belum terganti, harap tunggu beberapa saat dan lakukan Refresh kembali pada halaman acara tersebut</li>
                                    </ul> 
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>

                            </Accordion>
                        
                          </div>
                          <div className="tab-pane fade" id="nav-earn" role="tabpanel" aria-labelledby="nav-earn-tab">
                          <strong>D. “Earn” - Melamar pekerjaan</strong><br/><br/>
                          <Accordion defaultActiveKey="0">
                              <Card>
                                <Accordion.Toggle as={Card.Header} aria-expanded="true" aria-controls="panelsStayOpen-collapseOne" variant="link" eventKey="0">
                                Bagaimana cara melamar pekerjaan yang diposting di Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="0">
                                  <Card.Body>Caranya sangat mudah yaitu membuat akun di Digihealth (lihat poin A.5), setelah itu ikuti langkah berikut :
                                    <ul>
                                      <li>1. Klik “Job” di bagian paling atas dan akan muncul pilihan pekerjaan dimana Anda bisa melakukan pelamaran kerja</li>
                                      <li>2. Lihat detail di masing-masing pekerjaan dan klik “Lihat Selengkapnya” untuk membaca keterangan lebih lanjut dan melamar pada pekerjaan tersebut</li>
                                      <li>3. Klik link bila tersedia dan ikuti psikotes untuk beberapa pekerjaan tertentu, atau bila tidak terdapat link, ikuti petunjuk pada masing-masing pekerjaan yang memiliki petunjuk yang bervariasi</li>
                                      <li>4. Bila belum melengkapi profil sebelumnya Anda akan diminta untuk melengkapi profil terlebih dahulu, dan khusus untuk melamar pekerjaan Anda wajib untuk upload file / scan CV, STR dan Ijazah</li>
                                      <li>5. Bila sudah berhasil melamar pekerjaan, Anda akan dihubungi oleh PIC dari Digihealth setelah periode pencarian lowongan pekerjaan ditutup bila Anda diterima pada pekerjaan tersebut</li>
                                    </ul>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                  Apakah saya bisa memilih lokasi pekerjaan yang ingin saya lamar?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="1">
                                  <Card.Body>Pada setiap detail pekerjaan akan ada keterangan apakah pekerjaan tersebut memerlukan tenaga kesehatan untuk langsung terjun ke lapangan atau bisa remote / dari lokasi masing-masing. Bila diperlukan terjun ke lapangan tentunya akan dicantumkan keterangan lokasinya.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                Apakah ada syarat tertentu untuk mendaftar pekerjaan yang diposting di Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="2">
                                  <Card.Body>Berbeda dengan mengikuti event pada bagian “Learn” dimana Anda hanya perlu mengisi data umum, untuk melamar pekerjaan pada bagian “Earn” Anda diwajibkan untuk upload file / scan CV, Ijazah, dan STR di profil Anda. Selebihnya syarat dapat bervariasi tergantung masing-masing pekerjaan, dimana akan dicantumkan pada detail pekerjaan tersebut.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                               <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                Berapa biaya yang akan saya dapatkan dari pekerjaan yang diposting di Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="3">
                                  <Card.Body>Biaya yang akan Anda dapatkan bervariasi tergantung jenis pekerjaan. Hal ini akan dicantumkan pada detail pekerjaan tersebut.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                Apakah saya akan mendapatkan SKP dari pekerjaan yang ada di Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="4">
                                  <Card.Body>Hal ini bervariasi tergantung jenis pekerjaan. Hal ini akan dicantumkan pada detail pekerjaan tersebut.</Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="5">
                                Apakah saya bisa berbagi di Digihealth mengenai informasi lowongan pekerjaan untuk tenaga kesehatan yang dibuat oleh pihak lain selain Digihealth?
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="5">
                                  <Card.Body>Sejauh ini belum bisa, namun sedang kami kembangkan agar pihak lain selain Digihealth bisa posting event webinar / pelatihan online maupun lowongan pekerjaan.</Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                        
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footers />
      </div>


    )
  }
}
