export const PROFESSION_LIST_REQUEST = "PROFESSION_LIST_REQUEST";
export const PROFESSION_LIST_SUCCESS = "PROFESSION_LIST_SUCCESS";
export const PROFESSION_LIST_FAIL = "PROFESSION_LIST_FAIL";

export const PROFESSION_UPDATE_REQUEST = "PROFESSION_UPDATE_REQUEST";
export const PROFESSION_UPDATE_SUCCESS = "PROFESSION_UPDATE_SUCCESS";
export const PROFESSION_UPDATE_FAIL = "PROFESSION_UPDATE_FAIL";

export const PROFESSION_CREATE_REQUEST = "PROFESSION_CREATE_REQUEST";
export const PROFESSION_CREATE_SUCCESS = "PROFESSION_CREATE_SUCCESS";
export const PROFESSION_CREATE_FAIL = "PROFESSION_CREATE_FAIL";

export const PROFESSION_DELETE_REQUEST = "PROFESSION_DELETE_REQUEST";
export const PROFESSION_DELETE_SUCCESS = "PROFESSION_DELETE_SUCCESS";
export const PROFESSION_DELETE_FAIL = "PROFESSION_DELETE_FAIL";