import React from "react";
import $ from 'jquery'; 
// import { NavLink } from 'react-router-dom';

function Header() {
    
    // var html = $('html'),
    var body = $('body');
    function menuClose() {
        body.removeClass('popup-mobile-menu-wrapper')
    };
    
    $('.popup-mobile-click').on('click', function (e) {
      
            
                body.addClass('popup-mobile-menu-wrapper')
          
    });
    
    
    $('.mobile-close').on('click', function (e) {
        e.preventDefault();
        menuClose();
    });
    $('.popup-mobile-visiable').on('click', function (e) {
        e.target === this && menuClose();
    });
    return (
<div className="popup-mobile-manu popup-mobile-visiable">
  <div className="inner">
    <div className="mobileheader">
      <div className="logo">
        <a href="/">
          <img src="assets/images/logo-digihealth-w.png" alt="DigihealthID" />
        </a>
      </div>
      <a className="mobile-close" href="#0"> </a>
    </div>
    <div className="menu-content">
      <ul className="menulist object-custom-menu">
        <li className="has-mega-menu">
          <a href="/"><span>Home</span></a>
        </li>
        <li className="has-mega-menu">
          <a href="/event"><span>Event</span></a>
        </li>
        <li className="has-mega-menu">
          <a href="/job"><span>Jobs</span></a>
        </li>
      </ul>
    </div>
  </div>
</div>

)
}
export default Header;
