import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Headermenu";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
// import Menudashboard from "../../components/MenuDashboard";
import { addToList, getList, deleteItem, updateItem } from "../../actions/professionAction";
import ReactPaginate from 'react-paginate';
import Popupmenu from "../../components/Popupmenu";

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            data: [],
            list: [],
            perPage: 25,
            level1: "",
            level2: "",
            level3: "",
            level: "",
            name: "",
            Parent: "-",
            code: "",
            order: "1",
            Active: "true",
            title: "",
            currentPage: 0,
            searchTitle: '',
            searchActive: '',
            isUpdate: false,

        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onCreate = this.onCreate.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.onEdit = this.onEdit.bind(this)

    }
    onChange(e) {

        this.setState({ [e.target.name]: e.target.value })

        if (e.target.name === 'level') {
            if (document.getElementById("level").value === 'level1') {
                document.getElementById("parent").disabled = true;
                this.setState({
                    level1: 'true',
                    level2: 'false',
                    level3: 'false',
                })
            }
            if (document.getElementById("level").value === 'level2') {
                document.getElementById("parent").disabled = false;
                this.setState({
                    level1: 'false',
                    level2: 'true',
                    level3: 'false',
                })
            }
            if (document.getElementById("level").value === 'level3') {
                document.getElementById("parent").disabled = false;
                this.setState({
                    level1: 'false',
                    level2: 'false',
                    level3: 'true',
                })
            }
        }
    }

    onCreate(e) {
        e.preventDefault()
        this.onReset();
        this.setState({
            title: "Add New Profession"
        });
    }


    onEdit = (item_id, name, Parent, code, level1, level2, level3, e) => {
        if (level1 === 'true') {
            document.getElementById("level1").selected = "true";
        } else if (level2 === 'true') {
            document.getElementById("level2").selected = "true";
        } else if (level3 === 'true') {
            document.getElementById("level3").selected = "true";
        }
        this.setState({
            id: item_id,
            title: "Edit Profession",
            name: name,
            Parent: Parent,
            level1: level1,
            level2: level2,
            level3: level3,
            code: code,
            isUpdate: true,
        })
    }

    onReset() {
        this.setState({
            id: "",
            title: "",
            name: "",
            Parent: "",
            level1: "",
            level2: "",
            level3: "",
            code: "",
            isUpdate: false,
        });
    }


    receivedData() {

        getList().then(res => {
            if (res.status !== 'success') {
                localStorage.removeItem('userInfo')
                this.props.history.push(`/login`)
            } else {
                const data = res['data'];
                const result = data.filter(item => (this.state.searchTitle ? item.name === this.state.searchTitle : item.name) && (this.state.searchActive ? item.active === this.state.searchActive : item.active))
                const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                const filterData = slice.filter(item => (this.state.searchTitle ? item.name === this.state.searchTitle : item.name) && (this.state.searchActive ? item.active === this.state.searchActive : item.active))
                this.setState(
                    {
                        list: [...data],
                        data: [...filterData]
                    }
                )

                this.setState({
                    pageCount: Math.ceil(result.length / this.state.perPage),

                    filterData
                })
            }
        })


    }
    Filter = (e) => {

        this.receivedData()
    }
    handlePageClick = (e) => {
        var selectedPage
        if (e.selected === undefined) {
            selectedPage = 0;
        } else {
            selectedPage = e.selected;

        }
        const offset = selectedPage * this.state.perPage;
        this.forceUpdate()
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    componentDidMount() {
        this.receivedData()
    }

    onSubmit = e => {
        e.preventDefault();
        const professionRequest = {
            level1: this.state.level1,
            level2: this.state.level2,
            level3: this.state.level3,
            name: this.state.name,
            Parent: this.state.Parent,
            code: this.state.code,
            order: this.state.order,
            Active: this.state.Active,
        }

        addToList(professionRequest).then(() => {
            this.receivedData()
        }).catch(err => {
            this.setState({ editDisabled: false, errorMessage: err.message })
        })
        this.setState({ editDisabled: false })

    }

    onUpdate = e => {
        e.preventDefault()
        const UpdateRequest = {
            id: this.state.id,
            level1: this.state.level1,
            level2: this.state.level2,
            level3: this.state.level3,
            name: this.state.name,
            Parent: this.state.Parent,
            code: this.state.code,
            order: this.state.order,
            Active: this.state.Active
        }
        updateItem(UpdateRequest).then(() => {
            this.receivedData();
        }).catch(err => {
            this.setState({ isUpdate: false, errorMessage: err.message })
        })

        this.setState({ isUpdate: false })
    }

    onDelete = (val, e) => {
        e.preventDefault()
        deleteItem(val).then(() => {
            // this.handlePageClick(1)
            window.location.reload();
        }).catch(err => {
            this.setState({ editDisabled: false, errorMessage: err.message })

            // this.setState({ errorMessage:err.data.message })
        })
    }



    render() {
        return (
            <div id="main-wrapper">
                <Headers />
                <Headermobile />
                <Menumobile />
                <Popupmenu />
                <BottomNavbarMobile />
                <BottomNavbarMobilePopup />
                <div className="dashboard-content-section section bg_color--5">
                    <div className="container-fluid p-0">
                        <div className="row no-gutters">
                            {/* <Menudashboard /> */}
                            
                            <div className="col-xl-12 col-lg-9">
                                <div className="dashboard-main-inner">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="page-breadcrumb-content mb-40">
                                                <h4>Profession Management</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashboard-overview">
                                        <div className="row">
                                            <div className="col-xl-12 col-12">
                                                <div className="submited-applications mb-50">
                                                    <div className="applications-heading">
                                                        <h3>List Profession</h3>
                                                    </div>
                                                    <div className="applications-heading">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="form-group row">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={this.state.searchTitle}
                                                                        name="searchTitle"
                                                                        placeholder="enter name"
                                                                        onChange={this.onChange}
                                                                    />
                                                                </div></div>
                                                            <div className="col-md-4">
                                                                <div className="form-group row">
                                                                    <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Status</label>
                                                                    <div className="col-sm-9">
                                                                        <select className="form-control" name="searchActive" value={this.state.searchActive} onChange={this.onChange}>
                                                                            <option>All</option>
                                                                            <option value="true">Aktif</option>
                                                                            <option value="false">Non Aktif</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group row">
                                                                    <button className="btn btn-primary btn-sm btn-block" data-dismiss="modal" aria-label="Close" onClick={this.Filter.bind(this)}><i className="fas fa-search"></i> Filter</button>
                                                                </div>
                                                            </div>
                                                            &nbsp;&nbsp;
                                                            <div className="col-md-2">
                                                                <div className="form-group row">
                                                                    <button className="btn btn-success btn-sm btn-block" data-toggle="modal" data-target="#paycard" onClick={this.onCreate.bind(this)}><i className="fas fa-plus"></i> Add</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="job-applications-main-block">
                                                        <div className="job-applications-table">
                                                            <table className="table" id="tree-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Type</th>
                                                                        <th>Status</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.data
                                                                        .map((one, index) => (

                                                                            <tr className="application-item" key={index}>
                                                                                <td>{one.name}</td>
                                                                                <td> {one.level1 === "true" ?
                                                                                    <div className="productText">
                                                                                        Level 1
                                                                                    </div>
                                                                                    : null}
                                                                                    {one.level2 === "true" ?
                                                                                        <div className="productText">
                                                                                            Level 2
                                                                                        </div>
                                                                                        : null}
                                                                                    {one.level3 === "true" ?
                                                                                        <div className="productText">
                                                                                            Level 3
                                                                                        </div>
                                                                                        : null}</td>
                                                                                <td>
                                                                                    {one.active === "true" ?
                                                                                        <div className="productText">
                                                                                            Active
                                                                                        </div>
                                                                                        : null}
                                                                                    {one.active === "false" ?
                                                                                        <div className="productText">
                                                                                            Nonactive
                                                                                        </div>
                                                                                        : null}</td>
                                                                                <td><button className="btn btn-danger btn-sm btn-block" style={{width:"30px"}} onClick={this.onDelete.bind(this, one.id)} ><i className="fas fa-trash" ></i></button>
                                                                                    <button className="btn btn-warning btn-sm btn-block" style={{width:"30px"}} data-toggle="modal" data-target="#paycard" onClick={this.onEdit.bind(this, one.id, one.name, one.parent, one.code, one.level1, one.level2, one.level3)}><i className="fas fa-edit" ></i></button></td>
                                                                            </tr>
                                                                        ))}
                                                                </tbody>
                                                            </table>
                                                            <ReactPaginate
                                                                previousLabel={<i className="fa fa-angle-left"></i>}
                                                                nextLabel={<i className="fa fa-angle-right"></i>}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={this.state.pageCount}
                                                                marginPagesDisplayed={1}
                                                                onPageChange={this.handlePageClick}
                                                                containerClassName={"page-pagination justify-content-center"}
                                                                subContainerClassName={"pages pagination"}
                                                                activeClassName={"active"} />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footers />


                <div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="title">Level Profession</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <select className="form-control" name="level" id="level" value={this.state.level} onChange={this.onChange}>
                                                    <option value="" disabled>Pilih</option>
                                                    <option value="level1" id="level1">Level 1</option>
                                                    <option value="level2" id="level2">Level 2</option>
                                                    <option value="level3" id="level3">Level 3</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">Nama Profession</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.name}
                                                    name="name"
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="link">Parent</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <select className="form-control" name="Parent" id="parent" value={this.state.parent} onChange={this.onChange}>
                                                    <option value="-">No Parent</option>
                                                    {this.state.list.map((item, index) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">Kode Profession</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.code}
                                                    name="code"
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">Kode Profession</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" name="Active" defaultChecked={this.state.Active} onChange={this.onChange} type="checkbox" id="autoSizingCheck" />
                                                    <label className="form-check-label" htmlFor="autoSizingCheck">
                                                        Active
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button className="btn btn-primary btn-block" data-dismiss="modal" aria-label="Close" onClick={this.onUpdate.bind(this)}
                                        style={this.state.isUpdate ? {} : { display: 'none' }}
                                    >
                                        Update
                                    </button>
                                    <button type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-success btn-block" data-dismiss="modal" aria-label="Close"
                                        style={this.state.isUpdate ? { display: 'none' } : {}}
                                    >
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}
