import {
    PROFESSION_UPDATE_FAIL,
    PROFESSION_UPDATE_REQUEST,
    PROFESSION_UPDATE_SUCCESS,
    PROFESSION_CREATE_FAIL,
    PROFESSION_CREATE_REQUEST,
    PROFESSION_CREATE_SUCCESS,
    PROFESSION_LIST_FAIL,
    PROFESSION_LIST_REQUEST,
    PROFESSION_LIST_SUCCESS,
    PROFESSION_DELETE_FAIL,
    PROFESSION_DELETE_REQUEST,
    PROFESSION_DELETE_SUCCESS,
  } from "../constants/professionConstants";

  export const professionListReducer = (state = { professions : [] }, action) => {
    switch (action.type) {
      case PROFESSION_LIST_REQUEST:
        return { loading: true };
      case PROFESSION_LIST_SUCCESS:
        return { loading: false, professions: action.payload };
      case PROFESSION_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const professionCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case PROFESSION_CREATE_REQUEST:
        return { loading: true };
      case PROFESSION_CREATE_SUCCESS:
        return { loading: false, success: true };
      case PROFESSION_CREATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const professionUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case PROFESSION_UPDATE_REQUEST:
        return { loading: true };
      case PROFESSION_UPDATE_SUCCESS:
        return { loading: false, userInfo: action.payload, success: true };
      case PROFESSION_UPDATE_FAIL:
        return { loading: false, error: action.payload, success: false };
      default:
        return state;
    }
  };

  export const professionDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case PROFESSION_DELETE_REQUEST:
        return { loading: true };
      case PROFESSION_DELETE_SUCCESS:
        return { loading: false, success: true };
      case PROFESSION_DELETE_FAIL:
        return { loading: false, error: action.payload, success: false };
      default:
        return state;
    }
  };
