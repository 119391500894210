import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { setTitle, setDescription } from "../../actions/userActions";


export default class App extends Component {
    componentDidMount() {
        setTitle('Syarat & Ketentuan Penggunaan DigiHealth')
        setDescription('Kami berkomitmen untuk membangun komunitas tenaga kesehatan dengan selalu meningkatkan kualitas dan membantu tenaga kesehatan menemukan kesempatan kerja yang spesifik di dunia kesehatan.')
        }
    render() {
        return (
            <div id="main-wrapper">
                <Headers />
                <Headermobile />
                <Menumobile />
                <BottomNavbarMobile />
                <BottomNavbarMobilePopup />
                <div className="breadcrumb-section section bg_color--5 pt-60 pt-sm-50 pt-xs-40 pb-30 pb-sm-50 pb-xs-40">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-breadcrumb-content">
                                    {/* <ul className="page-breadcrumb">
                                        <li><a href="/">Beranda</a></li>
                                        <li>police </li>
                                    </ul> */}
                                    <br/><br/>
                                    <h1>Syarat & Ketentuan Penggunaan DigiHealth</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="job-listing-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-lg-12 order-lg-2 order-1">
                                <div className="tab-content">
                                    <div id="grid" className="tab-pane fade  show active">
                                        <div className="row">
                                            <div className="single-job-grid col-md-12">
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}><strong >Pendahuluan</strong></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span>DigiHealth (“DigiHealth”) adalah produk yang dikembangkan, dimiliki dan dikelola oleh PT Atoma Medical (“Atoma”). DigiHealth adalah layanan swalayan kesehatan yang tersedia dalam bentuk situs web dan dalam bentuk aplikasi mobile (“Platform”) dan/atau kanal media digital DigiHealth.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Pengguna DigiHealth adalah semua masyarakat baik masyarakat awam maupun masyarakat tenaga kesehatan yang hendak menggunakan layanan produk dan jasa DigiHealth untuk membantu dalam mendapatkan produk kesehatan, yang berupa namun tidak terbatas pada sediaan farmasi seperti obat, vitamin, suplemen herbal, alat medis, barang medis habis pakai, barang kecantikan, produk kesehatan lainnya yang bukan obat, dengan mudah dan terjangkau baik melalui Platform ataupun kanal media digital DigiHealth lainnya.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Dalam menjalankan pelayanannya DigiHealth akan didukung oleh Mitra Pihak Ketiga (“Mitra”) yang berupa namun tidak terbatas pada: apotek yang akan membantu penyediaan produk kesehatan (“Produk”), tenaga kesehatan seperti dokter (“Dokter”), perawat (“Perawat”), bidan (“Bidan”), laboratorium klinik (“Lab”), fasilitas kesehatan lainnya seperti klinik (“Klinik”) dan rumah sakit (“RS”).</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Semua Pengguna tunduk pada semua syarat dan ketentuan penggunaan DigiHealth.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Semua Pengguna DigiHealth dengan menggunakan layanan DigiHealth secara otomatis dianggap sudah membaca semua Syarat dan Ketentuan DigiHealth dan dengan sadar dan tanpa paksaan memberikan persetujuannya untuk tunduk pada semua Syarat dan Ketentuan DigiHealth serta Kebijakan Privasi DigiHealth. Bagi Pengguna yang tidak menyetujui syarat dan ketentuan yang tertulis dalam dokumen ini maka Pengguna diharapkan untuk tidak menggunakan DigiHealth dan fitur-fiturnya.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Syarat dan ketentuan yang tertulis dalam dokumen ini dapat diubah atau diperbaharui oleh DigiHealth tanpa sebelumnya memberitahukan kepada Pengguna. Pada saat terdapat pembaharuan atau perubahan atas syarat dan ketentuan, maka syarat dan ketentuan yang berlaku adalah yang semua syarat dan ketentuan yang tertulis pada dokumen terkini. Oleh karena itu, Pengguna berkewajiban untuk terus memperbaharui informasi mengenai syarat dan ketentuan yang berlaku dengan secara rutin membuka laman dokumen Syarat dan Ketentuan dan Kebijakan Privasi pada DigiHealth.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >&nbsp;</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}><strong >Situs Web DigiHealth dan Aplikasi Mobile DigiHealth</strong></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}><span >Situs web DigiHealth,&nbsp;</span><a href="http://www.prosehat.com/" style={{ textDecoration: 'none' }}><span style={{ fontSize: '12pt', fontFamily: '"Times New Roman"', color: '#333333', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>www.DigiHealth.com</span></a><span >, dan aplikasi mobile DigiHealth baik berbasiskan sistem Android atau sistem iOS atau sistem perangkat mobile lainnya (“DigiHealth App”) dikelola oleh Atoma.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Situs web DigiHealth dan DigiHealth App berisikan konten informasi produk kesehatan (“Produk”) yang berupa, namun tidak terbatas pada sediaan farmasi seperti obat, vitamin, suplemen herbal, alat medis, barang medis habis pakai, barang kecantikan, produk kesehatan lainnya yang bukan obat.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Konten Produk ini dapat berupa teks, gambar, diagram, audio ataupun video atau bentuk lain.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Konten Produk dikembangkan berdasarkan sumber informasi pada bungkus atau kemasan Produk atau lembar informasi Produk atau sumber dari badan otoritas nasional, yaitu Badan Pusat Obat dan Makanan (“BPOM”) atau sumber bebas lainnya yang sudah disebarkan ke khalayak publik baik melalui pengunggahan digital maupun melalui media cetak lainnya. DigiHealth tidak memberikan pendapat terpisah terhadap manfaat, keakuratan dan keandalan dari tiap-tiap Produk terhadap diri Pengguna. Dengan demikian setiap keputusan yang dilakukan oleh Pengguna adalah interpretasi dari Pengguna sendiri. DigiHealth tidak bertanggung jawab atas adanya kesalahan interpretasi dari Pengguna atas konten Produk ini.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Konten Produk akan berisikan informasi semata dan tidak dimaksudkan dan tidak dapat dianggap memberikan konsultasi profesional tentang kesehatan atau manfaat medis dari Produk. Setiap pernyataan dan konten ajakan yang tertera atau terkandung dalam Produk adalah dibuat oleh pembuat pernyataan tersebut dan tidak dapat dianggap sebagai pernyataan dari DigiHealth. Oleh karena itu, adanya masalah di kemudian hari yang terkait dengan konten Produk ini DigiHealth dan Atoma berhak untuk dilepaskan dari segala tuntutan dan dari kewajiban ganti rugi.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Situs web DigiHealth juga berisikan konten Kesehatan yang dapat berupa namun tidak terbatas pada artikel, gambar, audio, atau video. Konten kesehatan ini bersifat edukatif dan informatif. Pengguna bebas untuk melakukan interpretasi mandiri atas konten kesehatan ini sehingga adanya masalah baik berupa kesalahan atau kecacatan atau bentuk kerugian lainnya yang timbul akibat kesalahan interpretasi Pengguna bukan menjadi tanggung jawab DigiHealth dan Atoma sehingga DigiHealth dan Atoma berhak untuk dibebaskan dari segala tuntutan dan kewajiban ganti rugi yang muncul akibat hal ini.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}><span >Pengembangan konten Kesehatan dilakukan oleh Atoma dan dikembangkan berdasarkan konten yang sudah tayang di situs web TanyaDok.com dan sumber bebas lainnya yang dapat diakses secara daring (</span><span style={{ fontSize: '12pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>online</span><span >) oleh khalayak umum lainnya.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Seluruh konten Kesehatan DigiHealth adalah untuk keperluan informasi dan edukasi, BUKAN sebagai pengganti dari pemeriksaan dan anamnesa tatap muka dengan tenaga kesehatan profesional, baik dalam hal penegakan diagnosis maupun penanganan kondisi penyakit yang ada. Oleh karenanya DigiHealth dan Atoma berhak untuk dibebaskan dari segala tuntutan ataupun ganti rugi yang mungkin terjadi di masa mendatang oleh karena Pengguna yang tidak mengindahkan ketentuan ini.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Semua konten Produk dan konten Kesehatan dalam DigiHealth adalah milik DigiHealth dan Atoma kecuali dinyatakan sebaliknya oleh DigiHealth.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Pengguna DILARANG untuk menyalin-tempel, mengubah sebagian atau seluruhnya, menayangkan, menyebarluaskan dalam bentuk media digital atau media lainnya, semua konten Produk dan konten Kesehatan yang ada di DigiHealth tanpa ijin atau kesepakatan tertulis sebelumnya dari pihak DigiHealth atau Atoma. Adanya pelanggaran terhadap ketentuan ini, DigiHealth atau Atoma berhak untuk menuntut ganti rugi terhadap Pengguna yang melanggar ketentuan ini dan akses Pengguna ke DigiHealth akan dibekukan secara permanen.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Koreksi atau ralat atau Konten Produk atau Konten Kesehatan bisa dilaporkan Pengguna kepada DigiHealth melalui surat elektronik kepada tim DigiHealth yang menuliskan secara jelas judul konten atau laman konten, jenis kesalahan, letak kesalahan pada konten tersebut, nama Pengguna yang melaporkan, email Pengguna yang melaporkan dan nomor kontak Pengguna yang melaporkan. Alamat surat elektronik DigiHealth adalah cs@DigiHealth.com.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >&nbsp;</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}><strong >Transaksi DigiHealth</strong></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Transaksi DigiHealth adalah transaksi pemesanan dan pembelian Produk melalui Platform dan/atau kanal media digital DigiHealth lainnya.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >&nbsp;</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}><strong >Ketentuan Umum Transaksi DigiHealth:</strong></p>
                                                <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengguna dengan menggunakan layanan Transaksi DigiHealth ini setuju untuk tunduk pada semua ketentuan harga Produk, ketentuan pembelian Produk, ketentuan pembayaran Produk, ketentuan pengiriman Produk dan ketentuan penerimaan Produk.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Harga yang ditampilkan pada laman Produk &nbsp;dapat berubah sewaktu-waktu. Harga yang akan dikenakan pada Pengguna adalah harga yang tertera pada invoice.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengguna wajib memastikan ketepatan, keakuratan dan kelengkapan perintah Transaksi (termasuk memastikan bahwa semua data yang diperlukan telah diisi secara lengkap dan benar)</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Persetujuan atas pelaksanaan Transaksi dilakukan apabila DigiHealth telah melakukan konfirmasi kebenaran dan kelengkapan data yang diisi Pengguna. Konfirmasi akan dilakukan oleh sistem DigiHealth dan Customer Service DigiHealth (“CS DigiHealth”).</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >DigiHealth menerima dan menjalankan setiap perintah Transaksi dari Pengguna sebagai perintah yang sah berdasarkan penggunaan login dari Pengguna dan untuk itu DigiHealth tidak memiliki kewajiban apapun untuk meneliti dan memastikan keabsahan atau kewenangan penggunaan login tersebut dan oleh karena itu perintah tersebut sah mengikat Pengguna.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Segala transaksi yang telah dikirimkan dan/atau diperintahkan kepada DigiHealth tidak dapat dibatalkan</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Setiap perintah yang telah dikirimkan dan/atau disetujui berasal dari Pengguna yang tersimpan pada pusat data DigiHealth merupakan data yang benar yang diterima sebagai bukti perintah dari Pengguna kepada DigiHealth untuk melaksanakan Transaksi yang dimaksud. Pengguna setuju dan mengakui bahwa catatan, print out komputer, salinan atau bentuk penyimpanan informasi atau data lain yang berasal dari pusat data DigiHealth adalah satu-satunya bukti yang sah atas instruksi dan Transaksi yang berasal dari Pengguna.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '12pt' }}><span >Informasi dan data mengenai waktu Transaksi adalah tanggal efektif yang tercatat di pusat data DigiHealth.</span></p>
                                                    </li>
                                                </ol>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >&nbsp;</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Alamat email yang didaftarkan oleh Pengguna kepada DigiHealth merupakan alamat email yang akan digunakan oleh DigiHealth untuk mengirimkan informasi Transaksi yang telah dilakukan oleh Pengguna. DigiHealth hanya mengirimkan informasi kepada alamat email yang telah dikonfirmasikan kebenarannya oleh Pengguna kepada DigiHealth melalui registrasi.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >DigiHealth tidak bertanggung jawab atas kebenaran dan keabsahan alamat email tersebut. DigiHealth tidak menjamin keamanan informasi dan data yang dikirim kepada DigiHealth oleh Pengguna melalui email yang tidak dalam format yang telah disetujui.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >DigiHealth berhak menghentikan Transaksi untuk sementara waktu dengan pemberitahuan sebelumnya untuk kepentingan pembaharuan, pemeliharaan atau untuk tujuan lain yang dianggap perlu oleh DigiHealth.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Setiap kegiatan pemilihan, penentuan dan pemberian Produk (termasuk yang bersumber dari perintah dokter dalam resep) dalam proses Transaksi dilakukan oleh tenaga professional yang berwenang dan memiliki kompetensi professional baik yang terdapat dalam tim DigiHealth maupun dalam tim Mitra.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >&nbsp;</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}><strong >Ketentuan Penjualan DigiHealth</strong></p>
                                                <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengguna setuju untuk mematuhi setiap dan semua pedoman, pemberitahuan, aturan operasional dan kebijakan dan instruksi yang berkaitan dengan pembelian produk melalui DigiHealth, serta setiap amandemen tersebut, yang dikeluarkan oleh DigiHealth (baik sebagai bagian dari penggunaan layanan pada Platform atau dalam kaitannya dengan pembelian produk, atas nama Mitra), dari waktu ke waktu.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >DigiHealth berhak untuk merevisi pedoman, pemberitahuan, aturan operasional ini dan kebijakan serta instruksinya sewaktu-waktu, Pengguna dianggap mengetahui dan terikat oleh setiap perubahan tersebut di atas setelah dipublikasi di Platform atau kandal media sosial DigiHealth.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >DigiHealth dan Mitra berupaya untuk memberikan gambaran yang akurat dari Produk, DigiHealth tidak menjamin bahwa deskripsi tersebut akurat, terkini atau bebas dari kesalahan.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Semua Produk adalah semua produk Mitra yang kemudian dijual di Platform DigiHealth.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Semua Mitra DigiHealth wajib tunduk terhadap semua syarat dan ketentuan Mitra DigiHealth dan wajib untuk memenuhi persyaratan dan standar operasional yang ditetapkan oleh DigiHealth kepada Mitra. Penetapan standar ini adalah untuk meningkatkan kepuasan Pengguna.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengguna dapat memesan Produk dengan formulir Order pada Platform dan mengklik pada tombol “Konfirmasi Pesanan”. Pengguna bertanggung jawab untuk memastikan keakuratan Pesanan Pengguna.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Semua Pesanan tidak dapat ditarik kembali setelah transmisi melalui Platform dan DigiHealth berhak (namun tidak berkewajiban) untuk memproses Pesanan tersebut tanpa konfirmasi lebih lanjut dan tanpa pemberitahuan lebih lanjut kepada Pengguna.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Garansi Produk dilakukan oleh Mitra dan menjadi tanggung jawab Mitra. DigiHealth hanya akan melakukan seleksi awal keabsahan dan kebenaran dari Produk Mitra di awal kerjasama namun, setelahnya DigiHealth tidak bertanggung jawab atas Produk Mitra. Produk menjadi garansi dan tanggung jawab Mitra. Oleh karenanya bila didapatkan kecacatan pada Produk maka DigiHealth dan Atoma berhak untuk dibebaskan dari segala tuntutan dan tanggung jawab ganti rugi. DigiHealth akan membantu namun tidak berkewajiban dalam pengawasan Produk.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >DigiHealth dan Mitra tidak akan bertanggungjawab atas setiap cacat yang timbul dari ketidaksesuaian pemakaian atau penggunaan yang salah atau Produk, instalasi atau pemasangan Produk yang salah yang dilakukan Pelanggan atau pihak ketiga, keausan wajar, kerusakan yang disengaja, kelalaian, pemakaian yang tidak wajar, penanganan atau pemakaian yang salah, perawatan yang salah, beban yang berlebihan, materi operasi dan materi pengganti yang tidak cocok, pondasi tidak cocok, kimia atau pengaruh elektronik atau listrik elektro-teknis, kegagalan atau kelalaian atau kesalahan Pengguna atau pihak ketiga untuk mengikuti instruksi atau deskripsi atau manual Produk (baik secara lisan atau tertulis), penyalahgunaan atau perubahan atau perbaikan Produk tanpa persetujuan.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '12pt' }}><span >DigiHealth dan Mitra tidak bertanggung jawab atas Kerugian yang diderita oleh Pengguna dan/atau pihak ketiga secara langsung atau tidak langsung disebabkan oleh karena cacat Produk. Pengguna membebaskan DigiHealth dan Mitra terhadap semua Kerugian yang timbul.</span></p>
                                                    </li>
                                                </ol>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >&nbsp;</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}><strong >Ketentuan Pengiriman Produk</strong></p>
                                                <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengiriman Produk akan dilakukan oleh DigiHealth dan/atau Mitra baik dengan menggunakan sumber daya dan aset DigiHealth atau Mitra atau dengan bekerja sama dengan pihak ketiga.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengiriman Produk hanya akan dilakukan setelah Pengguna melakukan pembayaran kecuali Pengguna menyatakan akan melakukan pembayaran dengan metode&nbsp;</span><span style={{ fontSize: '12pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Cash on Delivery</span><span >.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Dalam hal pengiriman yang dilakukan oleh Mitra atau pihak ketiga, maka DigiHealth tidak bertanggung jawab atas kecacatan dari Produk yang terjadi saat proses pengiriman.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Semua pengiriman akan dikirimkan kepada alamat yang sudah dimasukkan oleh Pengguna.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Biaya pengiriman dan pengepakan sebagaimana tercantum dalam Pesanan.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengguna dapat melacak status pengiriman dengan menghubungi CS DigiHealth pada nomor yang tertera di Platform.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengguna mengakui bahwa pengiriman produk tergantung pada ketersediaan dari Produk. Mitra dan DigiHealth Penjual akan melakukan segala upaya yang wajar untuk memberikan Produk kepada Pengguna dalam jangka waktu pengiriman yang disepakati secara standar operasional yaitu dalam waktu selambat-lambatnya 4 jam dari konfirmasi pemesanan.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Bilamana terjadi jangka waktu pengiriman yang lebih lama dari standar operasional DigiHealth maka CS DigiHealth akan menghubungi Pengguna untuk melakukan konfirmasi ulang pemesanan. Pengiriman akan dilakukan setelah Pengguna memberikan konfirmasinya.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >DigiHealth dan Mitra tidak menjamin ketersediaan Produk.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Dalam hal Pengguna tidak menerima Produk dengan jangka waktu pengiriman yang diproyeksikan dan disediakan pada Platform, maka Pengguna wajib memberitahukan DigiHealth atas hal tersebut paling lambat 3 (tiga) jam segera dari estimasi waktu pengiriman, agar DigiHealth dapat dengan itikad baik dan usaha terbaik mencari dan memberikan Produk. Jika DigiHealth tidak menerima pemberitahuan Pengguna dalam waktu 3 (tiga) jam tersebut, maka DigiHealth akan secara otomatis menganggap Pengguna sudah menerima Produk.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Jika Pengguna gagal menerima pengiriman Produk dikarenakan kesalahan dan/atau kelalaian-nya sendiri (selain karena alasan sebab apapun di luar kendali yang wajar Pengguna atau Mitra/DigiHealth/pihak ketiga yang mengirimkan Produk) maka tanpa mengurangi hak Mitra untuk melakukan pengiriman ulang atau tindakan perbaikan lainnya, Mitra dapat saja memutuskan untuk mengakhiri atau membatalkan pengiriman Produk kepada Pengguna.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Bilamana terjadi hal di atas maka CS DigiHealth akan mengkonfirmasi ulang kepada Pengguna untuk pengiriman ulang dengan kalkulasi waktu pengiriman baru.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Harga Produk yang harus dibayar oleh Pengguna harus merupakan harga yang didaftarkan pada saat Pesanan ditempatkan oleh Pengguna dan ditransmisikan kepada DigiHealth melalui Platform.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '12pt' }}><span >Semua harga Produk yang didaftarkan tunduk pada pajak, kecuali dinyatakan lain. DigiHealth berhak untuk mengubah Daftar Harga sewaktu-waktu tanpa memberikan alasan apapun atau pemberitahuan sebelumnya kepada Pelanggan.</span></p>
                                                    </li>
                                                </ol>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >&nbsp;</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}><strong >Ketentuan Pembayaran</strong></p>
                                                <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengguna dapat membayar Produk menggunakan salah satu metode pembayaran yang ditentukan oleh DigiHealth.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Semua pembayaran harus dilakukan kepada DigiHealth atau Mitra. Pengguna mengakui bahwa DigiHealth berhak untuk mengumpulkan pembayaran dari Pengguna atas nama Mitra.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Metode pembayaran dapat ditambahkan dan dikenakan persyaratan tambahan seperti yang ditentukan oleh DigiHealth dari waktu ke waktu.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengguna setuju bahwa Pengguna tunduk pada perjanjian yang berlaku atas metode pembayaran dan DigiHealth berhak sewaktu-waktu untuk mengubah atau menghentikan, untuk sementara atau seterusnya atas suatu metode pembayaran tanpa pemberitahuan kepada Pengguna.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Jika Pengguna menggunakan Voucher, Syarat &amp; Ketentuan Voucher sebagaimana dimaksud pada Platform akan berlaku atau sebagaimana tercantum pada Platform. Masing-masing voucher yang dikeluarkan oleh Pengguna bisa memiliki syarat dan ketentuan penggunaan yang berbeda-beda.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Jika Pengguna gagal melakukan pembayaran sesuai dengan syarat dan ketentuan dari metode pembayaran yang dipilih atau pembayaran dibatalkan untuk alasan apapun, maka DigiHealth berhak untuk membatalkan pesanan Pengguna atau menangguhkan pengiriman Produk hingga pembayaran dilakukan secara penuh.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengembalian Dana Pembayaran hanya akan dilakukan bila DigiHealth dan/atau Mitra gagal untuk memenuhi pesanan Pelanggan sesuai dengan kesepakatan waktu pengiriman Produk dan Pengguna memutuskan untuk membatalkan namun sudah terjadi pembayaran lengkap kepada DigiHealth. Dalam hal ini, maka DigiHealth akan mengembalikan dana Pengguna via transfer bank rekening.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Dalam hal pembayaran sudah dilakukan oleh Pengguna kepada Mitra, maka pengembalian dana akan tetap dilakukan oleh DigiHealth dengan sebelumnya DigiHealth berhubungan dengan Mitra untuk meminta kembali dana pembayaran atas nama Pengguna. Kondisi ini akan mengakibatkan pengembalian dana pembayaran yang lebih lama karena adanya waktu tambahan untuk penelusuran data pembayaran dan verifikasi.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '12pt' }}><span >DigiHealth tidak memberikan jaminan apapun dalam akurasi waktu masuknya pengembalian dana ke rekening Pengguna. Proses pembayaran mungkin memerlukan waktu dan tunduk pada masing-masing bank dan/atau waktu dari proses internal penyedia sistem pembayaran.</span></p>
                                                    </li>
                                                </ol>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >&nbsp;</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}><strong >Ketentuan Pengembalian atau Perbaikan atau Penggantian Produk</strong></p>
                                                <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengembalian atau perbaikan atau penggantian produk hanya bisa dilakukan bila Pengguna menemukan adanya cacat pada Produk yang dikirimkan pada waktu penerimaan Produk atau selambat-lambatnya 1×24 jam setelah penerimaan Produk hanya khusus untuk Produk yang bukan sediaan</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengembalian atau perbaikan atau penggantian Produk sediaan farmasi TIDAK bisa dilakukan bisa segel kemasan telah dibuka.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Dalam hal terjadi pengembalian atau perbaikan atau penggantian Produk, Pengguna bisa menghubungi CS DigiHealth atau berbicara langsung dengan petugas pengiriman Produk. Petugas pengiriman Produk akan langsung menghubungi CS DigiHealth. CS DigiHealth akan melakukan konfirmasi ulang kepada Mitra yang menyediakan dan menyiapkan Produk. CS DigiHealth akan melakukan konfirmasi ulang kepada Pengguna atas pengiriman Produk pengganti dengan estimasi waktu pengiriman baru.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengguna yang melakukan pengembalian atau perbaikan atau penggantian Produk wajib untuk mengisi formulir pengembalian atau perbaikan atau penggantian Produk dan wajib mencantumkan pernyataan penolakan penerimaan Produk. Produk yang ditolak diterima HARUS dibawa kembali oleh petugas pengiriman dalam keadaan seperti sebelumnya.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Risiko kerusakan atau hilangnya Produk menjadi tanggung jawab Pengguna setelah Produk telah dikirim/telah diterima berdasarkan tanda terima, walaupun Pengguna lalai atau gagal dalam menerima pengiriman Produk yang telah terkirim atau telah sampai di alamat Pengguna berdasarkan tanda terima.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pembatalan pemesanan hanya bisa dilakukan oleh Pengguna bila belum terjadi proses pengiriman Produk. Bila pada saat dilakukan pembatalan pemesanan dan Pengguna sudah melakukan pembayaran maka dana pembayaran akan dikembalikan kepada Pengguna. Namun, bila proses pengiriman sudah dilakukan maka tidak bisa dilakukan pembatalan pemesanan dan dana pembayaran tidak bisa dikembalikan. Dalam hal terjadi pembatalan pemesanan pada saat proses pengiriman sudah dilakukan maka Pengguna tetap harus membayarkan minimal 50% dari total harga pemesanan.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pembatalan pemesanan bisa dilakukan sepihak oleh DigiHealth bila:</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Produk tidak tersedia dengan alasan tertentu / apapun;</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengguna melanggar kewajiban berdasarkan Kontrak Pelanggan, Syarat &amp; Ketentuan Penggunaan, Syarat &amp; Ketentuan Penjualan, atau ketentuan lain yang berlaku di Platform;</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengguna ditetapkan pailit oleh pengadilan atau badan yang berwenang;</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Kecurigaan atas adanya&nbsp;</span><span style={{ fontSize: '12pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>fraud</span><span >atau kecurangan dalam bentuk apapun berdasarkan kebijakan atau investigasi DigiHealth ataupun Mitra;</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Para Pihak sepakat untuk mengesampingkan Pasal 1266 KUHPerdata Indonesia sehingga pemutusan Kontrak Pelanggan baik yang dibuat oleh Pengguna atau Mitra akan dilakukan tanpa memerlukan persetujuan atau keputusan pengadilan atau lembaga lain di dalam wilayah Republik Indonesia.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Resiko kerusakan atau kehilangan barang akan ditanggung oleh Pengguna pada saat pengiriman atau jika Pengguna lalai atau gagal dalam menerima pengiriman Produk yang telah terkirim atau telah sampai di alamat Pengguna.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Kepemilikan Produk tidak akan berpindah ke Pengguna sampai pembayaran atas Produk diterima DigiHealth atau Mitra secara penuh atas harga Produk.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Sebelum kewajiban pelunasan Produk dipenuhi, Pengguna hanya bertindak sebagai agen fidusia DigiHealth (atau&nbsp;</span><span style={{ fontSize: '12pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Bailee</span><span >) bukan pemilik atas Produk.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >DigiHealth berhak untuk meminta Pengguna untuk menyerahkan barang kembali kepada DigiHealth bilamana tidak terjadi pembayaran atas Produk dan apabila terjadi ketidakpatuhan, DigiHealth memiliki hak untuk mengambil tindakan hukum terhadap pembeli untuk mendapatkan kembali Produk atau Barang tersebut, dan juga memiliki hak untuk meminta pertanggungjawabab atas seluruh kerugian dan biaya lainnya termasuk namun tidak terbatas atas biaya upaya hukum.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengguna wajib membebaskan DigiHealth dan Mitra, semua petugas, karyawan, direktur, agen, kontraktor, atas semua kerusakan, kerugian, biaya, beban dan biaya hukum yang dikeluarkan oleh Pengguna sehubungan dengan pernyataan dan penegakan hak DigiHealth dan Mitra di bawah Syarat &amp; Ketentuan Penjualan ini.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Pengguna tidak berhak untuk mentransfer, mengalihkan, melepaskan, menjaminkan atau dengan apapun membebankan Barang atau Produk yang masih belum terlunasi, yang merupakan milik DigiHealth atau Mitra, jika Pengguna melanggarnya maka sehingga semua hutang atau sisa pelunasan atau pembayaran atas Barang atau Produk menjadi jatuh tempo dan dapat ditagih.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" >
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '12pt' }}><span >Pengguna setuju dengan DigiHealth bahwa Pengguna harus segera memberitahukan DigiHealth materi apapun dari waktu ke waktu yang mempengaruhi nama DigiHealth atas Produk dan Pembeli harus memberikan informasi kepada DigiHealth yang berkaitan dengan Produk yang mungkin diperlukan DigiHealth dari waktu ke waktu.</span></p>
                                                    </li>
                                                </ol>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}><span >&nbsp;</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}><strong >Pernyataan Penyanggahan mengenai Produk</strong></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Semua harga Produk adalah bersumber dari Mitra dan harga bisa berubah sewaktu-waktu tanpa adanya pemberitahuan sebelumnya.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >DigiHealth tidak bertanggung jawab kepada Anda untuk setiap kerugian apapun atau apapun penyebabnya (apapun bentuknya) yang timbul langsung maupun tidak langsung berhubungan dengan: (i) piutang Pengguna lain dalam platform yang berkaitan dengan pembelian produk apapun; (ii) penjualan produk oleh Mitra untuk Pengguna, atau penggunaan produk, atau penyalahgunaan Produk atau penjualan kembali produk oleh Pengguna; dan (iii) setiap cacat timbul dari keausan, kerusakan yang disengaja, penyalahgunaan, kelalaian, kecelakaan, penyimpanan abnormal dan atau kondisi penggunaan, perubahan atau modifikasi Produk atau kegagalan mematuhi instruksi (atau intruksi di deskiripsi produk atau di manual) terhadap penggunaan produk (baik lisan maupun tertulis).</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Dengan ini Pengguna membebaskan DigiHealth, semua petugas, karyawan, direktur, agen, kontraktor, atas semua kerusakan, kerugian, biaya, beban dan biaya hukum yang dikeluarkan oleh Pengguna sehubungan dengan pernyataan dan penegakan hak DigiHealth di bawah Syarat &amp; Ketentuan ini.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >&nbsp;</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}><strong >Pranala Luar</strong></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Pemasangan pranala luar milik pihak ketiga yang berupa IKLAN dalam DigiHealth harus mendapatkan ijin atau kesepakatan tertulis sebelumnya dari DigiHealth.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Pengguna yang mengakses pranala luar milik pihak ketiga adalah sepenuhnya tanggung jawab Pengguna. DigiHealth tidak bertanggung jawab atas keamanan, isi dan segala sesuatu terkait pranala luar milik pihak ketiga tersebut. Adanya musibah di masa mendatang yang disebabkan oleh karena Pengguna mengakses pranala luar milik pihak ketiga itu maka DigiHealth berhak untuk dibebaskan dari segala tuntutan dan ganti rugi yang diakibatkan oleh pranala luar milik pihak ketiga itu.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >&nbsp;</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}><strong >Ganti Rugi</strong></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Pengguna sepenuhnya bertanggung jawab atas semua aktivitas yang dilakukan dalam DigiHealth.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Semua Pengguna setuju untuk tidak menuntut ganti rugi atau menuntut dalam bentuk apa pun terhadap semua karyawan yang bekerja di DigiHealth dan Atoma baik tim konten, tim manajemen, tim direksi maupun tim dokter apabila terjadi tuntutan atau musibah yang terjadi pada Pengguna oleh karena kelalaian Pengguna dan/atau pelanggaran yang dilakukan oleh Pengguna.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Bilamana terjadi kerugian materi atau nonmateri atas perbuatan yang tidak terpuji yang dilakukan oleh Pengguna maka semua karyawan yang bekerja di DigiHealth dan Atoma baik tim Editorial, tim manajemen, tim direksi maupun tim dokter berhak untuk dibebaskan sepenuhnya dari segala tuntutan dan permintaan ganti rugi yang timbul oleh karena perbuatan Pengguna tersebut.</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >&nbsp;</span></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '0pt' }}><strong >Konflik Hak Cipta Konten</strong></p>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Apabila ada Pengguna yang mendapatkan adanya konten DigiHealth yang menyalahi hak cipta, maka Pengguna dapat melakukan langkah-langkah berikut ini:</span></p>
                                                <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                                                    <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc'}}>
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Lakukan identifikasi terhadap konten yang dimaksudkan. Sangat diharapkan Pengguna yang melaporkan juga menyerahkan pranala yang merupakan pranala asli dari konten tersebut.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc'}}>
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Cantumkan secara jelas identitas Pengguna yang melaporkan. Identitas yang dimaksud adalah nama lengkap, nomor kontak (nomor handphone) dan alamat email.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc'}}>
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Cantumkan juga dengan jelas pernyataan bahwa pelaporan adanya konflik terhadap hak cipta ini dilakukan dengan itikad baik dan bukan merupakan ancaman atau suatu pemerasan.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc'}}>
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Cantumkan secara jelas bahwa informasi terkait konflik hak cipta ini adalah sebenar-benarnya dan bukan merupakan hasil rekayasa. Selain itu, Pengguna wajib mencantumkan hubungan Pengguna terhadap konten tersebut, apakah merupakan pemilik asli konten tersebut atau adalah orang yang diberikan kuasa oleh pemilik asli konten tersebut untuk melakukan pelaporan ini.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc'}}>
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '0pt' }}><span >Lampirkan juga pada bukti elektronik yang berisikan identitas asli Pengguna sebagai pemilik konten dan bila Pengguna bertindak sebagai orang yang diberikan kuasa maka juga perlu dilampirkan surat asli pemberian kuasa ini.</span></p>
                                                    </li>
                                                    <li aria-level={1} dir="ltr" style={{ listStyleType: 'disc'}}>
                                                        <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '0pt', marginBottom: '12pt' }}><span >Kirimkan semua berkas-berkas ini ke cs@DigiHealth.com.</span></p>
                                                    </li>
                                                </ul>
                                                <p dir="ltr" style={{ lineHeight: '1.38', marginTop: '12pt', marginBottom: '11pt' }}><span >Berkas yang dikirimkan Pengguna akan diproses oleh DigiHealth dan setelah dilakukan investigasi maka DigiHealth akan menghubungi Pengguna segera setelah proses identifikasi dan verifikasi laporan.</span></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footers />
            </div>


        )
    }
}
