import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  FORGET_PASSWORD_FAIL,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
} from "../constants/userConstants";
import axios from "axios";

export const listUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `${userInfo.data.token}`,
      },
    }; 

    const {data} = await axios.get(`/api/v1/users/list`, config);
    
    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data['data'],
    });

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: USER_LIST_FAIL,
      payload: message,
    });
  }
};

export const getList = () => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
      .get('/api/v1/users/list', {
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      })
      .then(res => {
          res.data.status = 'success'
          return res.data
      }).catch(err => {
          return {
              error:'Please login again!',
              status:'failed',
              message:err.message
          }
      })
}

export const verifikasi = (id) => {
  return axios
  .get(`/api/v1/auth/verifikasi/${id}`, {
    headers: { 
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
          return res.data
      }).catch(err => {
          return {
              error:'Please login again!',
              status:'failed',
              message:err.message
          }
      })
}

export const deleteUserAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `${userInfo.data.token}`,
      },
    };

    const { data } = await axios.delete(`/api/v1/users/delete/${id}`, config);

    dispatch({
      type: USER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: USER_DELETE_FAIL,
      payload: message,
    });
  }
};


export const forgetPasswordAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: FORGET_PASSWORD_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/auth/forgetpassword/${id}`);
    if(data.code !== 200){
      dispatch({
        type: FORGET_PASSWORD_FAIL,
        payload: "email tidak terdaftar",
      });
    }else{
      dispatch({
        type: FORGET_PASSWORD_SUCCESS,
        payload: "Mohon cek email untuk link Perbaharui Kata Sandi",
      });
    }
    
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: FORGET_PASSWORD_FAIL,
      payload: message,
    });
  }
};

export const resetPasswordAction = (id, password, konfirmasi) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });
    if(password !== konfirmasi){
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: "Konfirmasi password salah",
      });
    }else{
      const { data } = await axios.put(
        `/api/v1/auth/resetpassword/${id}`,
        { password }
      );
      if(data.code !== 200){
        dispatch({
          type: RESET_PASSWORD_FAIL,
          payload: "Link anda sudah expaired",
        });
      }else{
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: "Perbaharui Kata Sandi Berhasil Silahkan Login",
        });
      }
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: FORGET_PASSWORD_FAIL,
      payload: message,
    });
  }
};

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/v1/auth/login",
      { email, password },
      config
    );
    if(data.code !== 200){
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: "Username Atau Password Anda Salah",
      });
    }else{
      dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
      localStorage.setItem("info", JSON.stringify(data['data']['user']));

    }

  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("info");
  dispatch({ type: USER_LOGOUT });
};

export const register = (name, email, password, tlp) => async (dispatch) => {
  try {
    dispatch({ type: USER_REGISTER_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/v1/auth/register",
      { name, email, password, tlp },
      config
    );

    if (data.code !== 200) {
      
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: data.error_description 
      });

      
    }else{
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: "Register sukses mohon check email verifikasi",
      });
      dispatch(login(email, password));

    }


  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const updateUserAction = (name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs, strfile, bank, cv, sertifikat, profile) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userInfo.data.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/v1/users`,
      {name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs, strfile, bank, cv, sertifikat, profile},
      config
    );
    localStorage.setItem("info", JSON.stringify(data['data']));
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const upload = member => {
   var token = JSON.parse(localStorage.getItem('userInfo'));
   const data = new FormData() 
         data.append('myFile', member.myFile)
  return axios
    .post(
      `/api/v1/events/upload?path=${member.path}`,
      data,
      {
        headers: { 
            'Content-Type': 'multipart/form-data',
            'Authorization': token['data']['token']
        }
      }
    )
    .then(function(response) {
        return response.data;
    })
}

 export const deletecontent = user => {
    var token = JSON.parse(localStorage.getItem('userInfo'));
    return axios
      .delete(
        `/api/v1/events/deletecontent/${user.file}?path=${user.path}`,
        {
          headers: { 
              'Content-Type': 'application/json', 
              'Authorization': token['data']['token']
          }
        }
      )
      .then(function(response) {
          return response.data;
      })
}
  
export const resendverifikasi = () => {
    var token = JSON.parse(localStorage.getItem('userInfo'));
    return axios
      .post(
        `/api/v1/users/sendverifikasi`,
        {
          email:token['data']['user']['email']
        },
        {
          headers: { 
              'Content-Type': 'application/json', 
              'Authorization': token['data']['token']
          }
        }
      )
      .then(function(response) {
          return response.data;
      })
}

export const updateProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post("/api/users/profile", user, config);

    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setTitle = (title) => {
  const el = document.querySelector('title');
  el.innerText = `DigiHealth | ${title}`;
};

export const setDescription = (desc) => {
  const el = document.querySelector("meta[name='description']");
  el.setAttribute('content',desc)
}