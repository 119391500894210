
import axios from "axios";

export const getListfooterclient = () => {
    return axios
        .get('/api/v1/auth/footer/list', {
        headers: { 
            'Content-Type': 'application/json',
          }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
  }

  export const getListcmsclient = () => {
    return axios
        .get('/api/v1/auth/cms/list', {
        headers: { 
            'Content-Type': 'application/json',
          }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
  }