import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { setTitle, setDescription } from "../../actions/userActions";
import { getListcmsclient } from "../../actions/footermanajemenAction";
import Parser from 'html-react-parser';



export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            master:'',
            title:'',
            desc:''
        };
    }
  componentDidMount() {
      setDescription('Kami berkomitmen untuk membangun komunitas tenaga kesehatan dengan selalu meningkatkan kualitas dan membantu tenaga kesehatan menemukan kesempatan kerja yang spesifik di dunia kesehatan.')
      let params = window.location.pathname
      const myArray = params.split("/");
      let query = myArray[1] ? myArray[1] : []
      console.log(query)
      if(query !== null )
      {
            setTitle(query)
            this.setState({master:query})
            this.receivedData()
      }else{
            this.props.history.push(`/`)
      }   
    }

    receivedData() {
        getListcmsclient().then(res => {
            const data = res['data'] ? res['data'] : [];
            const result = data.filter(item => (this.state.master ? item.url === this.state.master : []))
            if(result.length !== 0){
              this.setState({title:result[0]['title'], desc:result[0]['description']})
            }else{
              this.props.history.push(`/`)
            }

        })

    }

  render() {
    return (
      <div id="main-wrapper">
        <Headers />
        <Headermobile />
        <Menumobile />
        <BottomNavbarMobile />
        <BottomNavbarMobilePopup />
        <div className="breadcrumb-section section bg_color--5 pt-60 pt-sm-50 pt-xs-40 pb-30 pb-sm-50 pb-xs-40">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-breadcrumb-content">
                  {/* <ul className="page-breadcrumb">
                    <li><a href="/">Beranda</a></li>
                    <li>police </li>
                  </ul> */}
                  <br/><br/>
                  <h1>
                      {this.state.title}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="job-listing-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-12 order-lg-2 order-1">
                <div className="tab-content">
                  <div id="grid" className="tab-pane fade  show active">
                    <div className="row">
                      <div className="single-job-grid col-lg-12">
                            {Parser(this.state.desc)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footers />
      </div>


    )
  }
}
