import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import Popupmenu from "../../components/Popupmenu";
import Modallogin from "../../components/Modallogin";
import { getListclient, updateItem, Sendmailregister } from "../../actions/eventActions";
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { setTitle, setDescription } from "../../actions/userActions";
import Moment from 'react-moment';
import 'moment/locale/id';
import $ from 'jquery';
const bucket = process.env.REACT_APP_API_KEY

const MySwal = withReactContent(Swal)

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            data: [],
            perPage: 25,
            currentPage: 0,
            searchTitle: '',
            searchActive: '',
            alert: false,
            message: '',
            speakers: [{ name: "", materi: "" }],
            paticipans: [{ idmember: "", nomorpendaftaran: "", nama: "", gelarbelakang: "", gelardepan: "", email: "", tlp: "", sertificate: "", skp: "" }],
            id: '',
            ready: '',
            datetime: ''

        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.onChange = this.onChange.bind(this)
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
        this.receivedData()
    }


    receivedData() {

        getListclient().then(res => {
            const data = res['data'] ? res['data'] : [];
            const servertime = res['message'] ? res['message'] : [];
            const result = data.filter(item => (this.state.searchTitle ? item.type === this.state.searchTitle : item.type))
            const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
            const filterData = slice.filter(item => (this.state.searchTitle ? item.type === this.state.searchTitle : item.type))
            // sortselesai event
            this.setState({ datetime : servertime })
            const newdata = []
            for (let index = 0; index < filterData.length; index++) {
                if(this.calculateTimeLeft(filterData[index]['date'], filterData[index]['time'], filterData[index]['timeend']) === "Selesai" )
                {
                    var newpriority = Math.floor(Math.random() * 100000);
                    newdata.push({
                        "id":filterData[index]['id'],
                        "ishome":filterData[index]['ishome'],
                        "active":filterData[index]['active'],
                        "banner":filterData[index]['banner'],
                        "date":filterData[index]['date'],
                        "desc":filterData[index]['desc'],
                        "isapply":filterData[index]['isapply'],
                        "skp":filterData[index]['skp'],
                        "linkmeet":filterData[index]['linkmeet'],
                        "meetid":filterData[index]['meetid'],
                        "moderator":filterData[index]['moderator'],
                        "passcode":filterData[index]['passcode'],
                        "paticipans":filterData[index]['paticipans'],
                        "priority":newpriority,
                        "sertificate":filterData[index]['sertificate'],
                        "slug":filterData[index]['slug'],
                        "speakers":filterData[index]['speakers'],
                        "time":filterData[index]['time'],
                        "timeend":filterData[index]['timeend'],
                        "title":filterData[index]['title'],
                        "type":filterData[index]['type'],
                    })
                }else{
                    newdata.push({
                    "id":filterData[index]['id'],
                    "ishome":filterData[index]['ishome'],
                    "active":filterData[index]['active'],
                    "banner":filterData[index]['banner'],
                    "date":filterData[index]['date'],
                    "desc":filterData[index]['desc'],
                    "isapply":filterData[index]['isapply'],
                    "skp":filterData[index]['skp'],
                    "linkmeet":filterData[index]['linkmeet'],
                    "meetid":filterData[index]['meetid'],
                    "moderator":filterData[index]['moderator'],
                    "passcode":filterData[index]['passcode'],
                    "paticipans":filterData[index]['paticipans'],
                    "priority":filterData[index]['priority'],
                    "sertificate":filterData[index]['sertificate'],
                    "slug":filterData[index]['slug'],
                    "speakers":filterData[index]['speakers'],
                    "time":filterData[index]['time'],
                    "timeend":filterData[index]['timeend'],
                    "title":filterData[index]['title'],
                    "type":filterData[index]['type'],
                })
                }
                
            }

            this.setState({data: [...newdata]})


            if (data.length !== 0) {
                this.setState({
                    pageCount: Math.ceil(result.length / this.state.perPage),

                    filterData
                })
            } else {
                this.setState({
                    pageCount: 0,

                    filterData
                })
            }

        })
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    onDetail(id, e) {
        let recent = [];
        recent.push({ 'id': id });
        localStorage.setItem('recent', JSON.stringify(recent));
        this.props.history.push('/events/' + id)

    }

    calculateTimeLeft = (dateevent, timestart, timeend ) => {
        let str = this.state.datetime;
        const server = str.split(" ");
        let datenow = server[0]
        let timenow = server[1]
        const difference = +new Date(`${dateevent}`) - +new Date(`${datenow}`);
        var timeLeft
        if (datenow > dateevent) {
            timeLeft = "Selesai"
            return timeLeft;
        } else if (datenow === dateevent && timenow === timestart) {
            timeLeft = "Sedang Berlangsung"
            return timeLeft;   
        } else if (datenow === dateevent && timenow > timestart && timenow < timeend) {
            timeLeft = "Sedang Berlangsung"
            return timeLeft;     
        } else if (datenow === dateevent && timenow > timeend | timenow === timeend) {
             timeLeft = "Selesai"
            return timeLeft;     
        }else {
            timeLeft = Math.floor(difference / (1000 * 60 * 60 * 24))
            return timeLeft +' Hari';
        }

    };

    onUpdate = (paticipans, speakers, id, e) => {
      
        e.preventDefault()
        var user = JSON.parse(localStorage.getItem('userInfo'));
        const nomorpendaftaran = Math.random().toString().substr(2, 10);
        var info = JSON.parse(localStorage.getItem('info'));
       
        
        this.setState({
                paticipans: paticipans,
                speakers: speakers,
                id: id
            })
        var filter = 0
        if (info !== null) {
            filter = paticipans.filter(one => (info.id ? one.idmember === info.id : 0))

        }
        if (info === null) {
             let eventregis = [];
            eventregis.push({ 'paticipans': paticipans, 'speakers': speakers, 'id':id });
            localStorage.setItem('eventregis', JSON.stringify(eventregis));

            $("#login").trigger("click");

        }else if (info.name === "User" || info.name === "user") {
           
             MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkapi nama Anda terlebih dahulu!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.props.history.push(`/memberdetailprofile`)
                }
            })
        } else if (filter.length !== 0) {

            MySwal.fire({
                icon: 'info',
                text: 'Terima Kasih Akun Anda Sudah Terdaftar',
            })
            localStorage.removeItem('recent')
            localStorage.removeItem('eventregis')

        } else {
            let recent = [];
            recent.push({ 'id': id });
            localStorage.setItem('recent', JSON.stringify(recent));
            

             const UpdateRequest = {
                id: id,
                idmember: user['data']['user']['id'],
                nomorpendaftaran: nomorpendaftaran,
                nama: user['data']['user']['name'],
                gelarbelakang: user['data']['user']['gelarbelakang'],
                gelardepan: user['data']['user']['gelardepan'],
                email: user['data']['user']['email'],
                tlp: user['data']['user']['tlp'],
                sertificate: "-",
                skp: "-"
            }
            updateItem(UpdateRequest).then(() => {
                Sendmailregister(nomorpendaftaran);
                this.props.history.push(`/event_register`)
            }).catch(err => {
                // this.setState({ isUpdate: false, errorMessage: err.message })
            })
        }
    }


    componentDidMount() {
        this.receivedData()
        localStorage.removeItem('recent')
        localStorage.removeItem('eventregis')
        this.setState({
            paticipans: [],
            speakers: [],
            id: ""
        })
        setTitle('Event')
        setDescription('Kami berkomitmen untuk membangun komunitas tenaga kesehatan dengan selalu meningkatkan kualitas dan membantu tenaga kesehatan menemukan kesempatan kerja yang spesifik di dunia kesehatan.')
    }


    render() {
        return (
            <div id="main-wrapper">
                <Headers />
                <Headermobile />
                <Menumobile />
                <Popupmenu />
                <BottomNavbarMobile menu="event" />
                <BottomNavbarMobilePopup />
                <div className="breadcrumb-section section bg_color--5 pt-100 pt-sm-100 pt-xs-100 pb-30 pb-sm-50 pb-xs-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-breadcrumb-content">
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Beranda</a></li>
                                        <li>Kegiatan</li>
                                    </ul>
                                    <h1>Kegiatan</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="job-listing-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-lg-12 order-lg-2 order-1">
                                <div className="filter-form">
                                    <div className="result-sorting">
                                        <div className="form-left">
                                            <div className="sort-by">
                                                <form action="#">
                                                    <label className="text-sortby">Filter menurut tipe :</label>
                                                    <select className="nice-select" name="searchTitle" value={this.state.searchTitle} onChange={this.onChange}>
                                                        <option value="">Semua</option>
                                                        <option value="Seminar">Seminar</option>
                                                        <option value="Webinar">Webinar</option>
                                                        <option value="Workshop">Workshop</option>
                                                    </select>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div id="list" className="tab-pane fade show active">
                                        <div className="row">
                                            {this.state.data
                                                .sort((a, b) => (a.priority > b.priority ? 1 : -1))
                                                .filter(one => (this.state.searchTitle ? one.type === this.state.searchTitle : one.type) && (one.active === "true"))
                                                .map((one, index) => (
                                                    <div className="col-lg-4 col-md-6 mb-30" key={index}>
                                                        {/* Event Blog Start */}
                                                        <div className="event-blog">
                                                            <div className="event-image">
                                                                <a href={'/events/' + one.slug} >
                                                                    <img src={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/event/"+one.banner} alt="banner" />
                                                                </a>
                                                                <div className="event-badge">
                                                                    <span>{this.calculateTimeLeft(one.date, one.time, one.timeend)}</span>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="event-content">
                                                                <div className="event-type">
                                                                    <span>{one.type}</span>
                                                                    {(() => {
                                                                        if (one.isapply === "true" && this.calculateTimeLeft(one.date,  one.time, one.timeend) === "Selesai" | this.calculateTimeLeft(one.date, one.time, one.timeend) === "Sedang Berlangsung" ) {
                                                                            return (
                                                                                <></>
                                                                            )
                                                                        }else if (one.isapply === "false" && this.calculateTimeLeft(one.date,  one.time, one.timeend) === "Selesai" | this.calculateTimeLeft(one.date, one.time, one.timeend) === "Sedang Berlangsung" ) {
                                                                            return (
                                                                                <></>
                                                                            )
                                                                        } else if (one.isapply === "true") {
                                                                           return (
                                                                                <><button className="btn btn-sm btn-success" onClick={this.onUpdate.bind(this, one.paticipans, one.speakers, one.id)}>Daftar Sekarang</button></>
                                                                            )  
                                                                        }
                                                                    })()}
                                                                </div>
                                                                <div className="event-meta">
                                                                    <p className="event-date">
                                                                        <i className="lnr lnr-calendar-full" />
                                                                        <span className="text"></span>
                                                                        <span className="author"><Moment locale="id" format="DD MMM YYYY">{one.date}</Moment></span>
                                                                    </p>
                                                                    <p className="event-time">
                                                                        <i className="lnr lnr-clock" />
                                                                        <span className="text"></span>
                                                                        <span className="author">{one.time} <strong>-</strong> {one.timeend}</span>
                                                                    </p>
                                                                    <p className="event-skp">
                                                                        <i className="lnr lnr-check" />
                                                                        <span className="text">SKP : </span>
                                                                        <span className="author"><b>{one.skp}</b></span>
                                                                    </p>
                                                                </div>
                                                                <h4 className="event-title">
                                                                    <a href={'/events/' + one.slug}>{one.title}</a>
                                                                </h4>
                                                                <div className="event-info">
                                                                    <div className="info-title">Pembicara</div>
                                                                    <div className="info-value">
                                                                        {one.speakers.map((value, key) => <span key={key}>{value.name}, </span>)}
                                                                    </div>
                                                                </div>
                                                                <div className="event-info">
                                                                    <div className="info-title">Moderator</div>
                                                                    <div className="info-value">
                                                                        {one.moderator}
                                                                    </div>
                                                                </div>
                                                                <div className="read-more">
                                                                    <a href={'/events/' + one.slug}>Lihat Detail <i className="lnr lnr-chevron-right" /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Event Blog End */}
                                                    </div>


                                                ))}


                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <ReactPaginate
                                                    previousLabel={<i className="fa fa-angle-left"></i>}
                                                    nextLabel={<i className="fa fa-angle-right"></i>}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={1}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={"page-pagination justify-content-center"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <Footers />
                <button type="button" className="btn btn-primary" style={{ display: "none" }} id="login" data-toggle="modal" data-target="#exampleModalCenter">
                    register
                </button>
                <Modallogin/>
                

            </div>


        )
    }
}
