import React, {  useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { logout } from "../../actions/userActions";
import { NavLink } from 'react-router-dom';

const style = {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '400px',
    height: '100%',
}
function Forgetpassword({ history }) {
    
    const dispatch = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    useEffect(() => {
        if (userInfo) {
            history.push("/profile");
        }else{
            history.push("/login");
        }
    }, [history, userInfo]);

    const logoutHandler = () => {
        dispatch(logout());
        history.push("/login");

    };

    return (
        <div id="main-wrapper" >
            <Headers />
            <Headermobile />
            <Menumobile />
            <BottomNavbarMobile menu="account" />
            <BottomNavbarMobilePopup />
            <div className="breadcrumb-section section bg_color--5 pt-60 pt-sm-50 pt-xs-40 pb-60 pb-sm-50 pb-xs-40">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-breadcrumb-content">
                                <ul className="page-breadcrumb">
                                    <li><a href="/">Beranda</a></li>
                                    <li>Profile</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="login-register-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-12">
                            <div className="login-register-form-area" style={style}>
                                
                                <div className="tab-content">
                                    <div id="login" className="tab-pane fade show active">
                                        <div className="login-register-form">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="bg-white profile-details">
                                                            <NavLink to="memberdetailprofile" className="d-flex w-100 align-items-center border-bottom px-3 py-3">
                                                                <div className="left mr-3">
                                                                    <h6 className="font-weight-bold m-0 text-dark">
                                                                        <i className="lnr lnr-user bg-danger text-white p-2 rounded-circle mr-2" />Profile</h6></div><div className="right ml-auto">
                                                                    <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                                                </div>
                                                            </NavLink>
                                                            <NavLink to="/daftar_activity_admin" className="d-flex w-100 align-items-center border-bottom px-3 py-3">
                                                                <div className="left mr-3">
                                                                    <h6 className="font-weight-bold m-0 text-dark">
                                                                        <i className="lnr lnr-laptop bg-primary text-white p-2 rounded-circle mr-2" /> Daftar Aktifitas
                                                                    </h6>
                                                                </div>
                                                                <div className="right ml-auto">
                                                                    <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                                                </div>
                                                            </NavLink>
                                                            <NavLink to="/listaddress" className="d-flex w-100 align-items-center border-bottom px-3 py-3">
                                                                <div className="left mr-3">
                                                                    <h6 className="font-weight-bold m-0 text-dark">
                                                                        <i className="lnr lnr-home bg-success text-white p-2 rounded-circle mr-2" /> Daftar Alamat
                                                                    </h6>
                                                                </div>
                                                                <div className="right ml-auto">
                                                                    <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                                                </div>
                                                            </NavLink>
                                                            <NavLink to="/listpendidikan" className="d-flex w-100 align-items-center border-bottom px-3 py-3">
                                                                <div className="left mr-3">
                                                                    <h6 className="font-weight-bold m-0 text-dark">
                                                                        <i className="lnr lnr-graduation-hat bg-warning text-white p-2 rounded-circle mr-2" /> Riwayat Pendidikan
                                                                    </h6>
                                                                </div>
                                                                <div className="right ml-auto">
                                                                    <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6></div>
                                                            </NavLink>
                                                            <NavLink to="/listpekerjaan" className="d-flex w-100 align-items-center border-bottom px-3 py-3">
                                                                <div className="left mr-3">
                                                                    <h6 className="font-weight-bold m-0 text-dark">
                                                                        <i className="lnr lnr-apartment bg-info text-white p-2 rounded-circle mr-2" /> Riwayat Pekerjaan
                                                                    </h6>
                                                                </div>
                                                                <div className="right ml-auto">
                                                                    <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6></div>
                                                            </NavLink>
                                                            <NavLink to="/riwayat-lamaran" className="d-flex w-100 align-items-center border-bottom px-3 py-3">
                                                                <div className="left mr-3">
                                                                    <h6 className="font-weight-bold m-0 text-dark">
                                                                        <i className="lnr  lnr-license bg-primary text-white p-2 rounded-circle mr-2" /> Riwayat Lamaran
                                                                    </h6>
                                                                </div>
                                                                <div className="right ml-auto">
                                                                    <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6></div>
                                                            </NavLink>
                                                             <NavLink to="/attachmentFile" className="d-flex w-100 align-items-center border-bottom px-3 py-3">
                                                                <div className="left mr-3">
                                                                    <h6 className="font-weight-bold m-0 text-dark">
                                                                        <i className="lnr lnr-file-add bg-success text-white p-2 rounded-circle mr-2" /> Lampiran
                                                                    </h6>
                                                                </div>
                                                                <div className="right ml-auto">
                                                                    <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6></div>
                                                            </NavLink>
                                                            <NavLink to="/login" onClick={logoutHandler} data-toggle="modal" data-target="#term" className="d-flex w-100 align-items-center border-bottom px-3 py-3">
                                                                <div className="left mr-3">
                                                                    <h6 className="font-weight-bold m-0 text-dark">
                                                                        <i className="lnr lnr-exit bg-secondary text-white p-2 rounded-circle mr-2" /> Keluar
                                                                    </h6>
                                                                </div>
                                                                <div className="right ml-auto">
                                                                    <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6></div>
                                                            </NavLink>
                                                        </div>
                                                    </div>

                                                </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footers />
        </div>
    )
}
export default Forgetpassword;
