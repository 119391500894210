import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Headermenu";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
// import Menudashboard from "../../components/MenuDashboard";
// import axios from 'axios'
import ReactPaginate from 'react-paginate';
import { NavLink } from 'react-router-dom';
import { getList } from "../../actions/eventActions";

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            data: [],
            perPage: 25,
            currentPage: 0,
            searchTitle: '',
            searchActive: '',

        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.onChange = this.onChange.bind(this)
        this.onEdit = this.onEdit.bind(this)

    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    onEdit(id, type, title, date, time, moderator, banner, sertificate, skp, linkmeet, meetid, passcode, priority, active, ishome, speakers, e)
    {
        let recent = [];
        recent.push({ 'id': id, 'type': type, 'title': title, 'date': date, 'time': time, 'moderator':moderator, 'banner':banner, 'sertificate':sertificate, 'skp':skp, 'linkmeet':linkmeet, 'meetid':meetid, 'passcode':passcode, 'priority':priority, 'active':active, 'ishome':ishome, 'speakers':speakers    });
        localStorage.setItem('recent', JSON.stringify(recent));
        this.props.history.push(`/eventeditview`)

    }


    receivedData() {

        getList().then(res => {
            if (res.status !== 'success') {
                localStorage.removeItem('userInfo')
                this.props.history.push(`/login`)
            } else {

                const data = res['data'];
                const result = data.filter(item => (this.state.searchTitle ? item.title === this.state.searchTitle : item.title) && (this.state.searchActive ? item.active === this.state.searchActive : item.active) && (this.state.searchTitle ? item.title === this.state.searchTitle : item.title))
                const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                const filterData = slice.filter(item => (this.state.searchTitle ? item.title === this.state.searchTitle : item.title) && (this.state.searchActive ? item.active === this.state.searchActive : item.active) && (this.state.searchTitle ? item.title === this.state.searchTitle : item.title))
                const postData = filterData
                    .map(one => <React.Fragment>
                        <tr className="application-item">
                            <td>{one.type}</td>
                            <td>{one.title}</td>
                            <td>{one.date}</td>
                            <td>{one.moderator}</td>
                            <td>
                                {one.speakers.map((value, key) =>
                                    <div>{value.name}<br /></div>
                                )}
                            </td>
                            <td>{one.speakers.length}</td>
                            <td>x</td>
                            <td>  <button onClick={this.onEdit.bind(this, one.id, one.type, one.title, one.date, one.time, one.moderator, one.banner, one.sertificate, one.skp, one.linkmeet, one.meetid, one.passcode, one.priority, one.active, one.ishome, one.speakers)} className="btn btn-warning btn-sm btn-block" style={{width: '30px'}}><i className="fas fa-edit"></i></button></td>
                        </tr>
                    </React.Fragment>)

                this.setState({
                    pageCount: Math.ceil(result.length / this.state.perPage),

                    postData
                })
            }
        })
    }
    Filter = (e) => {

        this.receivedData()
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    componentDidMount() {
        this.receivedData()
    }
    render() {
        return (
            <div id="main-wrapper">
                <Headers />
                <Headermobile />
                <Menumobile />
                <BottomNavbarMobile />
                <BottomNavbarMobilePopup />
                <div className="dashboard-content-section section bg_color--5">
                    <div className="container-fluid p-0">
                        <div className="row no-gutters">
                            {/* <Menudashboard /> */}
                            <div className="col-xl-12 col-lg-9">
                                <div className="dashboard-main-inner">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="page-breadcrumb-content mb-40">
                                                <h4>Event Management</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashboard-overview">
                                        <div className="row">
                                            <div className="col-xl-12 col-12">
                                                <div className="submited-applications mb-50">
                                                    <div className="applications-heading">
                                                        <h3>List Event</h3>
                                                        
                                                    </div>
                                                    <div className="row applications-heading">
                                                        <div className="col-md-2">
                                                            <div className="form-group row">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={this.state.searchTitle}
                                                                    name="searchTitle"
                                                                    placeholder="enter name event"
                                                                    onChange={this.onChange}
                                                                />
                                                            </div></div>
                                                        <div className="col-md-3">
                                                            <div className="form-group row">
                                                                <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Status</label>
                                                                <div className="col-sm-9">
                                                                    <select className="form-control" name="searchActive" value={this.state.searchActive} onChange={this.onChange}>
                                                                        <option value="">Semua</option>
                                                                        <option value="true">Aktif</option>
                                                                        <option value="false">Non Aktif</option>

                                                                    </select>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group row">
                                                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Tipe</label>
                                                                <div className="col-sm-10">
                                                                    <select className="form-control" name="searchTitle" value={this.state.searchTitle} onChange={this.onChange}>
                                                                        <option value="">Semua</option>
                                                                        <option value="seminar">Seminar</option>
                                                                        <option value="webinar">Webinar</option>

                                                                    </select>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-1">
                                                            <div className="form-group row">
                                                                <button className="btn btn-primary btn-sm btn-block" data-dismiss="modal" aria-label="Close" onClick={this.Filter.bind(this)}><i className="fas fa-search"></i> Filter</button>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <div className="form-group row">
                                                            <NavLink to="/eventaddview" className="btn btn-success btn-sm btn-block" ><i className="fas fa-plus"></i> Add</NavLink>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="job-applications-main-block">
                                                        <div className="job-applications-table">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th >Event Type</th>
                                                                        <th>Nama Event</th>
                                                                        <th>Waktu Event</th>
                                                                        <th>Moderator Event</th>
                                                                        <th>Speaker</th>
                                                                        <th >Materi</th>
                                                                        <th>Participant</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.postData}
                                                                </tbody>
                                                            </table>
                                                            <ReactPaginate
                                                                previousLabel={<i className="fa fa-angle-left"></i>}
                                                                nextLabel={<i className="fa fa-angle-right"></i>}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={this.state.pageCount}
                                                                marginPagesDisplayed={1}
                                                                onPageChange={this.handlePageClick}
                                                                containerClassName={"page-pagination justify-content-center"}
                                                                subContainerClassName={"pages pagination"}
                                                                activeClassName={"active"} />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footers />
            </div>


        )
    }
}
