import React, { useEffect, useState } from "react";
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { useSelector, useDispatch } from "react-redux";
import { updateUserAction } from "../../actions/userActions";
import ErrorMessage from "../../components/ErrorMessage";
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const font = {
    fontSize: '12px',
}
const margin = {
    marginRight: "20px"
}

function Edit({ history }) {
    const dispatch = useDispatch();
    const [title, setTitle] = useState("");
    const [index, setIndex] = useState(0);
    const [name, setName] = useState("");
    const [gelardepan, setGelardepan] = useState("");
    const [gelarbelakang, setGelarbelakang] = useState("");
    const [tlp, setTlp] = useState("");
    const [nip, setNip] = useState("");
    const [email, setEmail] = useState("");
    const [str, setStr] = useState("");
    const [npkidi, setNpkidi] = useState("");
    const [nik, setNik] = useState("");
    const [tempatlahir, setTempatlahir] = useState("");
    const [tanggallahir, setTanggallahir] = useState("");
    const [profesi, setProfesi] = useState("");
    const [spesialisasi, setSpesialisasi] = useState("");
    const [consltant, setConsltant] = useState("true");
    const [address, setAddress] = useState([]);
    const [addresslist, setAddresslist] = useState([]);
    const [education, setEducation] = useState([]);
    const [jobs, setJobs] = useState([]);
    const userUpdate = useSelector((state) => state.userUpdate);
    const userLogin = useSelector((state) => state.userLogin);
    const [profile, setProfile] = useState("");
    const [domisili, setDomisili] = useState("");
    const [alamat, setAlamat] = useState("");
    const [provinsi, setProvinsi] = useState("");
    const [kabupaten, setKabupaten] = useState("");
    const [kecamatan, setKecamatan] = useState("");
    const [kelurahan, setKelurahan] = useState("");
    const [utama, setUtama] = useState("");
    const [tipe, setTipe] = useState("");
    const [sertifikat, setSertifikat] = useState([{ name: "", file: "-" }]);
    const [cv, setCv] = useState("");
    const [strfile, setStrfile] = useState("");
    const [bank, setBank] = useState("");

    const {
        // loading: loadingUpdate,
        error: errorUpdate,
        success: successUpdate,
    } = userUpdate;
    const { userInfo } = userLogin;
    useEffect(() => {

        if (userInfo) {
            history.push("/listaddress");
            var prod = JSON.parse(localStorage.getItem('info'));
            setName(prod['name']);
            setGelardepan(prod['gelardepan']);
            setGelarbelakang(prod['gelarbelakang']);
            setTlp(prod['tlp']);
            setEmail(prod['email']);
            setNip(prod['nip']);
            setStr(prod['str']);
            setNpkidi(prod['npkidi']);
            setNik(prod['nik']);
            setTempatlahir(prod['tempatlahir']);
            setTanggallahir(prod['tanggallahir']);
            setProfesi(prod['profesi']);
            setSpesialisasi(prod['spesialisasi']);
            setProfile(prod['profile']);
            setConsltant(prod['consltant']);
            if (prod['address'].length !== 0) {
                setAddress(prod['address']);
                setAddresslist(prod['address']);
            }
            if (prod['education'].length !== 0) {
                setEducation(prod['education']);
            }
            if (prod['jobs'].length !== 0) {
                setJobs(prod['jobs']);
            }
            if(prod['cv'] !== undefined){
                setCv(prod['cv']);
            }
            if(prod['strfile'] !== undefined){
                setStrfile(prod['strfile']);
            }
            if(prod['bank'] !== undefined){
                setBank(prod['bank']);
            }
            if(prod['sertifikat'] !== null ){
                setSertifikat(prod['sertifikat']);
            }
        } else {
            history.push("/login");

        }
    }, [
        history,
        userInfo,
        successUpdate,
    ]);

    const resetHandler = () => {
        setAlamat("");
        setDomisili("");
        setProvinsi("");
        setKabupaten("");
        setKecamatan("");
        setKelurahan("");
        setUtama("");
        setTipe();
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateUserAction(name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs, strfile, bank, cv, sertifikat, profile));
        if (!name) return;
        resetHandler();
        history.push("/listaddress");
        // window.location.reload()
    };

    const updateHandler = (e) => {
        e.preventDefault();
        const list = [...address];
        list[index]['domisili'] = domisili;
        list[index]['alamat'] = alamat;
        list[index]['provinsi'] = provinsi;
        list[index]['kabupaten'] = kabupaten;
        list[index]['kecamatan'] = kecamatan;
        list[index]['kelurahan'] = kelurahan;
        list[index]['utama'] = utama;
        list[index]['tipe'] = tipe;
        setAddress(list);
        dispatch(updateUserAction(name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs));
        if (!name) return;
        resetHandler();
        history.push("/listaddress");
    };

    const utamaHandler = (key) => {
        const list = [...address];
        for (let start = 0; start < address.length; start++) {
            list[start]['utama'] = utama;
            setAddress(list);
        }
        list[key]['utama'] = "true";
        setAddress(list);
        dispatch(updateUserAction(name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs));
        if (!name) return;
        resetHandler();
        history.push("/listaddress");
    };

    var x = index
    // handle click event of the Add button
    const handleAddClick = () => {
        setTitle("Add New Address")
        document.getElementById('alamat').value = '';
        document.getElementById('domisili').value = '';
        document.getElementById('tipe').value = '';
        document.getElementById('provinsi').value = '';
        document.getElementById('kabupaten').value = '';
        document.getElementById('kecamatan').value = '';
        document.getElementById('kelurahan').value = '';

        var awal = address.length
        setIndex(awal)
        setAddress([...address, { domisili: "", alamat: "", provinsi: "", kabupaten: "", kecamatan: "", kelurahan: "", utama: "", tipe: "" }]);
    };

    const handleEditClick = (id, domisili, alamat, provinsi, kabupaten, kecamatan, kelurahan, utama, e) => {
        setTitle("Edit Address")
        var awal = id
        setIndex(awal)
        setDomisili(domisili);
        setAlamat(alamat);
        setProvinsi(provinsi);
        setKabupaten(kabupaten);
        setKecamatan(kecamatan);
        setKelurahan(kelurahan);
        setUtama(utama);
        setTipe(tipe);
    };

    const handleTipeClick = (e) => {
        var value = e
        setTipe(e)
        const lis = [...address];
        var current = 0
        if (value === "Alamat Sesuai KTP / Passport") {
            for (let start = 0; start < address.length; start++) {
                if (lis[start]['tipe'] === "Alamat Sesuai KTP / Passport") {
                    current = start
                }
                lis[start]['tipe'] = "Alamat Domisili";
                setAddress(lis)
            }
            lis[index]['tipe'] = value;
            setAddress(lis)

        } else {
            if (current !== 0) {
                lis[index]['tipe'] = value;
                setAddress(lis)
            } else {
                lis[index]['tipe'] = value;
                lis[current]['tipe'] = "Alamat Sesuai KTP / Passport";
                setAddress(lis)

            }

        }
    };

    const handleReset = () => {
        setTitle("Add New Address")
        const list = [...address];
        list.splice(index, 1);
        setAddress(list);
        setIndex(0)
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        var check = 0
        var list

        if (name === "tipe" && value === "Alamat Sesuai KTP / Passport") {
            for (let index = 0; index < address.length; index++) {
                if (address[index]['tipe'] === "Alamat Sesuai KTP / Passport") {
                    check = 1
                    break;

                }
            }
            list = [...address];
            if (check === 1) {
                MySwal.fire('Mohon Maaf untuk alamat sesuai KTP hanya bisa satu')
                list[index][name] = "Alamat Domisili";
                document.getElementById('tipe').selectedIndex = -1;

            } else {
                list[index][name] = value;
            }
        } else {
            list = [...address];
            list[index][name] = value;
        }
        setAddress(list);
    };



    // handle click event of the Remove button
    const handleRemoveClick = key => {
        const list = [...address];
        list.splice(key, 1);
        setAddress(list);
        dispatch(updateUserAction(name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, list, education, jobs));
        window.location.reload('/listaddress')
    };


    return (
        <div id="main-wrapper">
            <Headers />
            <Headermobile />
            <Menumobile />
            <BottomNavbarMobile />
            <BottomNavbarMobilePopup />

            <div className="dashboard-content-section section bg_color--5">
                <div className="container-fluid p-0">
                    <div className="row no-gutters">
                        {/* <Menudashboard /> */}
                        <div className="col-xl-12 col-lg-9">
                            <div className="dashboard-main-inner">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-breadcrumb-content mb-40">
                                            <h4>&nbsp;</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-overview">
                                    <div className="row">
                                        <div className="col-xl-12 col-12">
                                            <div className="profile-applications mb-50">
                                                <div className="profile-applications-heading">
                                                    <ul className="nav">
                                                        <li key="1" style={margin}><NavLink to="/memberdetailprofile" style={font}>Profile</NavLink></li>
                                                        <li key="2" style={margin}><NavLink to="/daftar_activity_admin" style={font}>Aktivitas</NavLink></li>
                                                        <li key="3" style={margin}><NavLink to="/listaddress" style={font}>Alamat</NavLink></li>
                                                        <li key="4" style={margin}><NavLink to="/listpendidikan" style={font}>Pendidikan</NavLink></li>
                                                        <li key="5" style={margin}><NavLink to="/listpekerjaan" style={font}>Pekerjaan</NavLink></li>
                                                        <li key="6" style={margin}><NavLink to="/attachmentFile" style={font}>Lampiran</NavLink></li>
                                                    </ul>
                                                </div>


                                                {errorUpdate && <ErrorMessage variant="danger">{errorUpdate}</ErrorMessage>}
                                                <div className="profile-applications-main-block">

                                                    <div className="profile-applications-form">
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <strong>Daftar Alamat</strong>
                                                            </div>
                                                            <div className="col-md-2 text-right">
                                                                <button className="btn btn-primary " data-toggle="modal" data-target="#paycard" onClick={handleAddClick} ><i className="fas fa-plus"></i> Tambah </button>
                                                            </div>
                                                            <br></br><br></br>
                                                            {addresslist.map((y, l) => {
                                                                const check = y.utama;

                                                                return (
                                                                    <div className="col-md-6 mb-3" key={l}>
                                                                        <div className="shadow p-3 mb-5 bg-white rounded">
                                                                            <div className="container">
                                                                                <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <strong className="input-group mb-0">{y.alamat}</strong>
                                                                                        <label className="input-group mb-0">{y.domisili}</label>
                                                                                        <label className="input-group mb-0">{y.kelurahan}, {y.kecamatan}</label>
                                                                                        <label>{y.kabupaten}, {y.provinsi}</label>
                                                                                    </div>
                                                                                    {check ? (
                                                                                        <div className="row align-right text-right float-right">
                                                                                            <div className="col-md-12" >
                                                                                                <button className="btn btn-sm btn-success " ><i className="fas fa-check-circle"></i> Utama</button>&nbsp;
                                                                                                <button className="btn btn-sm btn-info " ><i className="fas fa-home"></i> {y.tipe}</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="row">
                                                                                            <div className="col-md-12" >
                                                                                                <button className="btn btn-sm btn-warning mb-2" data-toggle="modal" data-target="#edit" onClick={() => handleEditClick(l, y.domisili, y.alamat, y.provinsi, y.kabupaten, y.kecamatan, y.kelurahan, y.utama)} ><i className="fas fa-edit"></i> Edit</button>
                                                                                                &nbsp;
                                                                                                <button className="btn btn-sm btn-danger mb-2" onClick={() => handleRemoveClick(l)} ><i className="fas fa-trash"></i> Hapus</button>
                                                                                                &nbsp;
                                                                                                <button className="btn btn-sm btn-success mb-2" onClick={() => utamaHandler(l)} ><i className="fas fa-check-circle"></i> Utama</button>
                                                                                                &nbsp;
                                                                                                <button className="btn btn-sm btn-info mb-2" ><i className="fas fa-home"></i> {y.tipe}</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footers />

            <div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => handleReset()}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Nama Alamat</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="alamat"
                                                id="alamat"
                                                className="form-control"
                                                placeholder="Nama Alamat"
                                                value={x.alamat}
                                                onChange={e => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Tipe Alamat</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <select name="tipe" value={x.tipe} id="tipe" className="form-control" onChange={e => handleInputChange(e, index)}>
                                                <option value="Alamat Domisili">Alamat Domisili</option>
                                                <option value="Alamat Sesuai KTP / Passport">Alamat Sesuai KTP / Passport </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Alamat</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="domisili"
                                                id="domisili"
                                                className="form-control"
                                                placeholder="jl.abc"
                                                value={x.domisili}
                                                onChange={e => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Provinsi</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="provinsi"
                                                id="provinsi"
                                                className="form-control"
                                                placeholder="DKI Jakarta"
                                                value={x.provinsi}
                                                onChange={e => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Kota</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="kabupaten"
                                                id="kabupaten"
                                                className="form-control"
                                                placeholder="Jaksel"
                                                value={x.kabupaten}
                                                onChange={e => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Kecamatan</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="kecamatan"
                                                id="kecamatan"
                                                className="form-control"
                                                placeholder="cilandak"
                                                value={x.kecamatan}
                                                onChange={e => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Kelurahan</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="kelurahan"
                                                id="kelurahan"
                                                className="form-control"
                                                placeholder="jl.abc"
                                                value={x.kelurahan}
                                                onChange={e => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>


                                <button type="submit" onClick={submitHandler.bind(this)} className="btn btn-success btn-block" data-dismiss="modal" aria-label="Close"
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="edit" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Nama Alamat</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={alamat}
                                                name="alamat"
                                                onChange={(e) => setAlamat(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Tipe Alamat</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <select name="tipe" value={tipe} className="form-control" onChange={(e) => handleTipeClick(e.target.value)}>
                                                <option value="Alamat Domisili">Alamat Domisili</option>
                                                <option value="Alamat Sesuai KTP / Passport">Alamat Sesuai KTP / Passport </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Alamat</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="domisili"
                                                className="form-control"
                                                placeholder="jl.abc"
                                                value={domisili}
                                                onChange={(e) => setDomisili(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Provinsi</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="provinsi"
                                                className="form-control"
                                                placeholder="DKI Jakarta"
                                                value={provinsi}
                                                onChange={(e) => setProvinsi(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Kota</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="kabupaten"
                                                className="form-control"
                                                placeholder="Jaksel"
                                                value={kabupaten}
                                                onChange={(e) => setKabupaten(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Kecamatan</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="kecamatan"
                                                className="form-control"
                                                placeholder="cilandak"
                                                value={kecamatan}
                                                onChange={(e) => setKecamatan(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Kelurahan</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="kelurahan"
                                                className="form-control"
                                                placeholder="jl.abc"
                                                value={kelurahan}
                                                onChange={(e) => setKelurahan(e.target.value)}
                                            />
                                            <input
                                                name="utama"
                                                type="hidden"
                                                className="form-control"
                                                placeholder="jl.abc"
                                                value={utama}
                                                onChange={(e) => setUtama(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" onClick={updateHandler.bind(this)} className="btn btn-success btn-block" data-dismiss="modal" aria-label="Close"
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}
export default Edit;
