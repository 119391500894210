import React from "react";

function BottomNavbarMobile() {

    return (
        <div className="mobile-popup">
            <div className="job-list-mobile" id="job-list-mobile-id">
                <div className="heading">
                    <div className="title">
                        <i className="lnr lnr-list" />
                        <h3>All Jobs list</h3>
                    </div>
                    <a className="view-all" href="!#">See all jobs</a>
                </div>
                <div className="content-popup-scroll">
                    <ul className="list-item">
                        <li><a href="#0"><i className="lnr lnr-printer" />Accounting </a></li>
                        <li><a href="#0"><i className="lnr lnr-film-play" />Broadcasting </a></li>
                        <li><a href="#0"><i className="lnr lnr-phone" />Customer Service </a></li>
                        <li><a href="#0"><i className="lnr lnr-bullhorn" />Digital Marketing </a></li>
                        <li><a href="#0"><i className="lnr lnr-chart-bars" />Finance &amp; Accounting </a></li>
                        <li><a href="#0"><i className="lnr lnr-smartphone" />Game Mobile </a></li>
                        <li><a href="#0"><i className="lnr lnr-picture" />Graphics &amp; Design </a></li>
                        <li><a href="#0"><i className="lnr lnr-home" />Graphics &amp; Design </a></li>
                        <li><a href="#0"><i className="lnr lnr-database" />Medical Doctor </a></li>
                        <li><a href="#0"><i className="lnr lnr-dinner" />Restaurant </a></li>
                    </ul>
                </div>
            </div>
            <div className="notifications-mobile" id="notifications-mobile-id">
                <div className="heading">
                    <div className="title">
                        <i className="lnr lnr-list" />
                        <h3>All Notifications</h3>
                    </div>
                    <a className="view-all" href="!#">See all jobs</a>
                </div>
                <div className="content-popup-scroll">
                    <ul className="list-item">
                        <li><a href="login-register.html"><i className="lnr lnr-book" /><span><b className="highlight">Register now</b> to reach dream jobs easier.</span> </a></li>
                        <li><a href="job-with-map.html"><i className="lnr lnr-book" /><span><b className="highlight">Job suggestion</b> you might be interested based on your profile.</span> </a></li>
                    </ul>
                </div>
            </div>
        </div>

    )
}
export default BottomNavbarMobile;
