import React from "react";

function Headermobile() {

    return (
        <header className="header-mobile bg_color--6 d-block d-lg-none is-sticky sticky-mobile">
            <div className="header-bottom menu-right">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="header-mobile-navigation d-block d-lg-none">
                                <div className="row align-items-center">
                                    <div className="col-3 col-md-3">
                                        {/* <div className="mobile-navigation text-right">
                                            <div className="header-icon-wrapper">
                                                <ul className="icon-list justify-content-start">
                                                    <li className="popup-mobile-click">
                                                        <a href="javascript:"><i className="lnr lnr-menu"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="col-6 col-md-6">
                                        <div className="header-logo text-center">
                                            <a href="/">
                                                <img src="/assets/images/logo-digihealth.png" width="150px" className="img-fluid" alt="DigihealthID" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-3 col-md-3">
                                        {/* <div className="mobile-navigation text-right">
                                            <div className="header-icon-wrapper">
                                                <ul className="icon-list justify-content-end">
                                                    <li>
                                                        <div className="header-cart-icon">
                                                            <a href="!#" className="header-search-toggle"><i className="lnr lnr-magnifier"></i></a>
                                                        </div>
                                                        <div className="header-search-form">
                                                            <form action="#">
                                                                <input type="text" placeholder="Type and hit enter" />
                                                                <button><i className="lnr lnr-magnifier" /></button>
                                                            </form>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    )
}
export default Headermobile;
