import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { getDetail, getListclient, updateItem, Sendmailregister } from "../../actions/eventActions";
import { setTitle, setDescription } from "../../actions/userActions";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Parser from 'html-react-parser';
import Modallogin from "../../components/Modallogin";
import $ from 'jquery';
import Moment from 'react-moment';
import 'moment/locale/id';
const bucket = process.env.REACT_APP_API_KEY

const MySwal = withReactContent(Swal)

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            banner: '',
            title: '',
            type: '',
            date: '',
            time: '',
            timeend:'',
            moderator: '',
            skp: '',
            alert: false,
            message: '',
            jmlhmateri: '',
            speakers: [{ name: "", materi: "" }],
            paticipans: [{ idmember: "", nomorpendaftaran: "", nama: "", gelarbelakang: "", gelardepan: "", email: "", tlp: "", sertificate: "", skp: "" }],
            desc: '',
            master: '',
            isapply: '',
            datetime:''
        };
    }
    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let query = params.get('kegiatan');
        if(query !== null )
        {
            this.setState({master:query})
            this.receivedData()
        }else{
            this.props.history.push(`/event`)
        }
    }
    receivedData() {
        getListclient().then(res => {
            const data = res['data'] ? res['data'] : [];
            const servertime = res['message'] ? res['message'] : [];
            const result = data.filter(item => (this.state.master ? item.slug === this.state.master : []))
            if(result.length === 0)
            {
                this.props.history.push(`/event`)

            }else{

                let recent = [];
                recent.push({ 'id': result[0]['id'] });
                localStorage.setItem('recent', JSON.stringify(recent));
                getDetail(result[0]['id']).then(res => {
                    if (res.status !== 'success') {
                        localStorage.removeItem('recent')
                        this.props.history.push(`/`)
                    } else {
                        this.setState({
                            id: res['data']['id'],
                            banner: res['data']['banner'],
                            title: res['data']['title'],
                            type: res['data']['type'],
                            date: res['data']['date'],
                            time: res['data']['time'],
                            timeend: res['data']['timeend'],
                            moderator: res['data']['moderator'],
                            skp: res['data']['skp'],
                            jmlhmateri: res['data']['jmlhmateri'],
                            speakers: res['data']['speakers'],
                            paticipans: res['data']['paticipans'],
                            desc: res['data']['desc'],
                            isapply: res['data']['isapply'],
                            datetime: servertime
        
                        })
                        setTitle(res['data']['title'])
                        setDescription(res['data']['title'])
                    }
                })
            }
             
        })
       

    }

    onUpdate = e => {
         e.preventDefault()
        var user = JSON.parse(localStorage.getItem('userInfo'));
        const nomorpendaftaran = Math.random().toString().substr(2, 10);
        var info = JSON.parse(localStorage.getItem('info'));
        localStorage.removeItem('eventregis')

        var filter = 0
        if(info !== null){
             filter = this.state.paticipans.filter(one => (info.id ? one.idmember === info.id : 0))   
        }
        if (info === null) {
            let eventregis = [];
            eventregis.push({ 'paticipans': this.state.paticipans, 'speakers': this.state.speakers, 'id':this.state.id });
            localStorage.setItem('eventregis', JSON.stringify(eventregis));

            $("#login").trigger("click");
        }else if (info.name === "User" || info.name === "user") {
             MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkapi nama Anda terlebih dahulu!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                     this.props.history.push(`/memberdetailprofile`)
                }
            })

        }else if(info !== null && filter.length !== 0){

            MySwal.fire({
                icon: 'info',
                text: 'Terima Kasih Akun Anda Sudah Terdaftar',
              })
    
        } else if(info !== null && filter.length === 0) {

            const UpdateRequest = {
                id: this.state.id,
                idmember: user['data']['user']['id'],
                nomorpendaftaran: nomorpendaftaran,
                nama: user['data']['user']['name'],
                gelarbelakang: user['data']['user']['gelarbelakang'],
                gelardepan: user['data']['user']['gelardepan'],
                email: user['data']['user']['email'],
                tlp: user['data']['user']['tlp'],
                sertificate: "-",
                skp: "-"
            }
            updateItem(UpdateRequest).then(() => {
                Sendmailregister(nomorpendaftaran);
                this.props.history.push(`/event_register`)
            }).catch(err => {
                // this.setState({ isUpdate: false, errorMessage: err.message })
            })
        }

        // this.setState({ isUpdate: false })
    }

    calculateTimeLeft = (dateevent, timestart, timeend ) => {
        
        let str = this.state.datetime;
        const server = str.split(" ");
        let datenow = server[0]
        let timenow = server[1]
        const difference = +new Date(`${dateevent}`) - +new Date(`${datenow}`);
        var timeLeft
        if (datenow > dateevent) {
            timeLeft = "Selesai"
            return timeLeft;
        } else if (datenow === dateevent && timenow === timestart) {
            timeLeft = "Sedang Berlangsung"
            return timeLeft;   
        } else if (datenow === dateevent && timenow > timestart && timenow < timeend) {
            timeLeft = "Sedang Berlangsung"
            return timeLeft;     
        } else if (datenow === dateevent && timenow > timeend | timenow === timeend) {
             timeLeft = "Selesai"
            return timeLeft;     
        }else {
            timeLeft = Math.floor(difference / (1000 * 60 * 60 * 24))
            return timeLeft +' Hari';
        }

    };

    render() {
        return (
            <div id="main-wrapper">
                <Headers />
                <Headermobile />
                <Menumobile />
                <BottomNavbarMobile menu="event" />
                <BottomNavbarMobilePopup />
                {/* Page Banner Section Start */}
                <div className="page-banner-section section bg_color--5 pt-120 pt-lg-100 pt-md-100 pt-sm-100 pt-xs-100">
                    <div className="container p-0">
                        <div className="row no-gutters single-blog">
                            <div className="blog-image">
                                <img src={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/event/"+this.state.banner} alt="banner" />
                                <div className="blog-cat">
                                    <a href="#0" rel="category tag">{this.calculateTimeLeft(this.state.date, this.state.time, this.state.timeend)}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Page Banner Section End */}
                {/* Breadcrumb Section Start */}
                <div className="breadcrumb-section section bg_color--5">
                    <div className="container job-content-box">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-breadcrumb-content">
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Beranda</a></li>
                                        <li><a href="/event">Kegiatan</a></li>
                                        <li>Detail Kegiatan</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="job-head-content">
                                    <h1 className="title">{this.state.title}</h1>
                                    <p className="employer-name"></p>
                                    <div className="job-meta-detail">
                                        <ul>
                                            <li className="posted">
                                                <i className="lnr lnr-calendar-full" />
                                                <span className="text"> Tanggal: </span>
                                                <span className="time"><Moment locale="id" format="DD MMM YYYY">{this.state.date}</Moment> </span>
                                            </li>
                                            <li className="expries">
                                                <i className="lnr lnr-clock" />
                                                <span className="text"> Waktu: </span>
                                                <span className="date theme-color"> {this.state.time} <strong>-</strong> {this.state.timeend} </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb Section Start */}
                {/* Job Details Section Start */}
                <div className="job-details-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
                    <div className="container job-content-box st-border">
                        <div className="row">
                            <div className="col-lg-4 order-lg-2 order-2 mt-sm-60 mt-xs-50">
                                <div className="sidebar-wrapper-three">
                                    <div className="common-sidebar-widget sidebar-three">
                                        <h2 className="sidebar-title">Informasi Kegiatan</h2>
                                        <div className="sidebar-meta">
                                            <div className="row no-gutters">
                                                <div className="col-lg-12">
                                                    {/* Single Meta Field Start */}
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-tag" />
                                                            <span>Tipe: </span>
                                                        </div>
                                                        <div className="field-value"><strong>{this.state.type}</strong></div>
                                                    </div>
                                                    {/* Single Meta Field Start */}
                                                </div>
                                                <div className="col-lg-12">
                                                    {/* Single Meta Field Start */}
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-user" />
                                                            <span>Moderator: </span>
                                                        </div>
                                                        <div className="field-value"><strong>{this.state.moderator}</strong></div>
                                                    </div>
                                                    {/* Single Meta Field Start */}
                                                </div>
                                                <div className="col-lg-12">
                                                    {/* Single Meta Field Start */}
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-gift" />
                                                            <span> Nilai SKP</span>
                                                        </div>
                                                        <div className="field-value"><strong>{this.state.skp}</strong></div>
                                                    </div>
                                                    {/* Single Meta Field Start */}
                                                </div>

                                                <div className="col-lg-12">
                                                    {/* Single Meta Field Start */}
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-mic" />
                                                            <span>Pembicara </span>
                                                        </div>
                                                        <div className="field-value">
                                                            <div className="job-skill-tag">
                                                                {this.state.speakers.map((value, key) =>
                                                                    <a href="#0" className="fw-600"><strong>{value.name}</strong></a>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Single Meta Field Start */}
                                                </div>
                                                <div className="col-lg-12">
                                                    {/* Single Meta Field Start */}
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-layers" />
                                                            <span>Materi </span>
                                                        </div>
                                                        <div className="field-value">
                                                            <div className="job-skill-tag">
                                                                {this.state.speakers.map((value, key) =>
                                                                    <a href="#0" className="fw-600"><strong>{value.materi}</strong></a>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Single Meta Field Start */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 order-lg-1 order-1 pr-55 pr-md-15 pr-sm-15 pr-xs-15">
                                <div className="job-detail-content">
                                {(() => {
                                            if (this.state.isapply === "true" && this.calculateTimeLeft(this.state.date,  this.state.time, this.state.timeend) === "Selesai" | this.calculateTimeLeft(this.state.date, this.state.time, this.state.timeend) === "Sedang Berlangsung" ) {
                                                return (
                                                    <></>
                                                )
                                            }else if (this.state.isapply === "false" && this.calculateTimeLeft(this.state.date,  this.state.time, this.state.timeend) === "Selesai" | this.calculateTimeLeft(this.state.date, this.state.time, this.state.timeend) === "Sedang Berlangsung" ) {
                                                return (
                                                    <></>
                                                )
                                            }else if (this.state.isapply === "true") {
                                                return (
                                                    <><a className=" btn btn-sm btn-info text-center mb-4" style={{width:"150px"}} href="#0" onClick={this.onUpdate.bind(this)}>Daftar Sekarang</a></>
                                                )  
                                            }
                                        })()}
                                    <div className="field-descriptions mt-xs-20 mb-60 mb-sm-30 mb-xs-30">
                                        <h2 className="sidebar-title">Deskripsi</h2>
                                        <p>{Parser(this.state.desc)}</p>
                                    </div>
                                    <div className="job-apply">
                                        <div style={this.state.alert ? {} : { display: 'none' }} className="alert alert-danger" id="success-alert">
                                            {/* <button type="button" className="close" data-dismiss="alert" style={{fontSize:'12px'}}>x</button> */}
                                            <strong>Warning! </strong> {this.state.message}.
                                        </div>
                                        {(() => {
                                            if (this.state.isapply === "true" && this.calculateTimeLeft(this.state.date,  this.state.time, this.state.timeend) === "Selesai" | this.calculateTimeLeft(this.state.date, this.state.time, this.state.timeend) === "Sedang Berlangsung" ) {
                                                return (
                                                    <></>
                                                )
                                            }else if (this.state.isapply === "false" && this.calculateTimeLeft(this.state.date,  this.state.time, this.state.timeend) === "Selesai" | this.calculateTimeLeft(this.state.date, this.state.time, this.state.timeend) === "Sedang Berlangsung" ) {
                                                return (
                                                    <></>
                                                )
                                            }else if (this.state.isapply === "true") {
                                                return (
                                                    <><a className="btn btn-lg btn-info btn-block text-center" href="#0" onClick={this.onUpdate.bind(this)}>Daftar Sekarang <i className="ml-10 mr-0 fa fa-paper-plane" /></a></>
                                                )  
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Job Details Section End */}

                <Footers />
                 <button type="button" className="btn btn-primary" style={{ display: "none" }} id="login" data-toggle="modal" data-target="#exampleModalCenter">
                    register
                </button>
                <Modallogin/>
            </div>


        )
    }
}