import {
    EVENT_UPDATE_FAIL,
    EVENT_UPDATE_REQUEST,
    EVENT_UPDATE_SUCCESS,
    EVENT_CREATE_FAIL,
    EVENT_CREATE_REQUEST,
    EVENT_CREATE_SUCCESS,
    EVENT_LIST_FAIL,
    EVENT_LIST_REQUEST,
    EVENT_LIST_SUCCESS,
  } from "../constants/eventConstants";
  import axios from "axios";
  const bucket = process.env.REACT_APP_API_KEY

  export const listEvents = () => async (dispatch) => {
    try {
      dispatch({
        type: EVENT_LIST_REQUEST,
      });
  
     
  
      const {data} = await axios.get(`/api/v1/eventsclient/list`);
      
      dispatch({
        type: EVENT_LIST_SUCCESS,
        payload: data['data'],
      });
  
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: EVENT_LIST_FAIL,
        payload: message,
      });
    }
  };

  export const getListclient = () => {
    return axios
        .get('/api/v1/eventsclient/list', {
        headers: { 
            'Content-Type': 'application/json',
          }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
  }

  export const getList = () => {
    var token = JSON.parse(localStorage.getItem('userInfo'));
    return axios
        .get('/api/v1/events/list', {
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': token['data']['token']
          }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
  }

  export const getListbyuser = () => {
    var token = JSON.parse(localStorage.getItem('userInfo'));
    var id = token['data']['user']['id']

    return axios
        .get(`/api/v1/events/findbyuser/${id}`, {
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': token['data']['token']
          }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
  }

  export const getListparticipant = () => {
    var token = JSON.parse(localStorage.getItem('userInfo'));
    var event = JSON.parse(localStorage.getItem('recent'));
    var id = event[0]['id'];
    return axios
        .get(`/api/v1/events/findid/${id}`, {
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': token['data']['token']
          }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
  }

  export const getDetail = (id) => {
  
    return axios
        .get(`/api/v1/eventsclient/findid/${id}`, {
        headers: { 
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}

 export const getDetails = () => {
    var event = JSON.parse(localStorage.getItem('recent'));
    var id = event[0]['id'];
    return axios
        .get(`/api/v1/eventsclient/findid/${id}`, {
        headers: { 
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
}
  


  export const createEventAction = (type, title, date, time, moderator, banner, sertificate, skp, linkmeet, meetid, passcode, priority, active, ishome, speakers, paticipans, desc) => async (
    dispatch,
    getState
  ) => {
    try {
      dispatch({
        type: EVENT_CREATE_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userInfo.data.token}`,
        },
      };
  
      const { data } = await axios.post(
        `/api/v1/events/add`,
        { type, title, date, time, moderator, banner, sertificate, skp, linkmeet, meetid, passcode, priority, active, ishome, speakers, paticipans, desc},
        config
      );
  
      dispatch({
        type: EVENT_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: EVENT_CREATE_FAIL,
        payload: message,
      });
    }
  };

  
  export const updateEventAction = (id, type, title, date, time, moderator, banner, sertificate, skp, linkmeet, meetid, passcode, priority, active, ishome, speakers) => async (
    dispatch,
    getState
  ) => {
    try {
      dispatch({
        type: EVENT_UPDATE_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userInfo.data.token}`,
        },
      };
  
      const { data } = await axios.put(
        `/api/v1/events/update/${id}`,
        { type, title, date, time, moderator, banner, sertificate, skp, linkmeet, meetid, passcode, priority, active, ishome, speakers},
        config
      );
  
      dispatch({
        type: EVENT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: EVENT_UPDATE_FAIL,
        payload: message,
      });
    }
  };

  export const updateItem = event => {
    var token = JSON.parse(localStorage.getItem('userInfo'));
    return axios
      .put(
        `/api/v1/events/addparticipant/${event.id}`,
        {
          paticipans:event.paticipans,
          speakers: event.speakers,
          idmember: event.idmember, 
          nomorpendaftaran: event.nomorpendaftaran,
          nama: event.nama,
          gelarbelakang: event.gelarbelakang,
          gelardepan: event.gelardepan,
          email: event.email,
          tlp: event.tlp,
          sertificate:  event.sertificate,
          skp: event.skp
        },
        {
          headers: { 
              'Content-Type': 'application/json', 
              'Authorization': token['data']['token']
          }
        }
      )
      .then(function(response) {
          return response.data;
      })
  }
 export const updateProfileparticipant = event => {
    var token = JSON.parse(localStorage.getItem('userInfo'));
    return axios
      .put(
        `/api/v1/events/updateparticipantevent/${event.id}`,
        {
          nama: event.nama,
          gelarbelakang: event.gelarbelakang,
          gelardepan: event.gelardepan,
          email: event.email,
          tlp: event.tlp
        },
        {
          headers: { 
              'Content-Type': 'application/json', 
              'Authorization': token['data']['token']
          }
        }
      )
      .then(function(response) {
          return response.data;
      })
  }
  export const Sendmailregister = (nomorpendaftaran) => {
    var token = JSON.parse(localStorage.getItem('userInfo'));
    var event = JSON.parse(localStorage.getItem('recent'));
    var id = event[0]['id'];
     axios
    .get(`/api/v1/events/findid/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(function (response) {
      var sepakers = response.data.data['speakers'] ? response.data.data['speakers'] : []
      const output = sepakers.map(user => user.name);
      const materi = sepakers.filter(one => (one.file !== "") && (one.file !== '-'))
      if(materi.length !== 0) {
          axios.post(
            `/api/v1/events/sendmail`,
            {
              email:token['data']['user']['email'],
              subject: "Event Reminder",
              
              body:`<center><img width='50%' src='https://res.cloudinary.com/syiham-group/image/upload/v1634292073/digihealth/logo-digihealth_y4dgtt.png'><img/><br/><br/><br/><h3>Pendaftaran Event Berhasil <br> Nomor Pendaftaran : ${nomorpendaftaran} <br><br> Hai ${token['data']['user']['gelardepan'] ? token['data']['user']['gelardepan'] : ''}, ${token['data']['user']['name'] ? token['data']['user']['name'] : ''}. ${token['data']['user']['gelarbelakang'] ? token['data']['user']['gelarbelakang'] : ''}</h3><p>Tidak terasa Event yang Anda daftarkan sebentar lagi akan dilaksanakan. Sebagai bentuk kepedulian DigiHealth kepada Anda agar kita dapat LEARN AND EARN TOGETHER melalui email ini kami mengingatkan Kegiatan yang sudah Anda daftarkan.</p><br><img width='50%' src='${"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/event/"+response.data.data['banner']}'><img/><p><strong>${response.data.data['title']}</strong> <br><strong> Event Date : ${response.data.data['date']}, ${response.data.data['time']}.<br> Moderator : ${response.data.data['moderator']} <br> Narasumber :  ${output} </strong> <br><br> Silahkan menggunakan Event Room berikut <br> <strong>Event Room : ${response.data.data['linkmeet']} <br>  Meeting ID : ${response.data.data['meetid']} <br> Passcode : ${response.data.data['passcode']} </strong><br><br> Mohon untuk mempersiapkan diri paling tidak 30 menit sebelum Event dimulai sesuai dengan waktu yang telah ditentukan.</p><a href="${window.location.origin}/downloadlink?kegiatan=${id}" style='background-color: #008CBA; /* Green */ border: none;color: white;padding: 15px 32px;text-align: center;text-decoration: none;display: inline-block;font-size: 16px;' target='_blank' >Unduh Materi</a><h3>DigiHealth <br> LEARN AND EARN TOGETHER</h3></center>`
            },
            {
              headers: { 
                  'Content-Type': 'application/json', 
                  'Authorization': token['data']['token']
              }
            }
          )       
      } else {
         axios.post(
            `/api/v1/events/sendmail`,
            {
              email:token['data']['user']['email'],
              subject: "Event Reminder",
              
              body:`<center><img width='50%' src='https://res.cloudinary.com/syiham-group/image/upload/v1634292073/digihealth/logo-digihealth_y4dgtt.png'><img/><br/><br/><br/><h3>Pendaftaran Event Berhasil <br> Nomor Pendaftaran : ${nomorpendaftaran} <br><br> Hai ${token['data']['user']['gelardepan'] ? token['data']['user']['gelardepan'] : ''}, ${token['data']['user']['name'] ? token['data']['user']['name'] : ''}. ${token['data']['user']['gelarbelakang'] ? token['data']['user']['gelarbelakang'] : ''}</h3><p>Tidak terasa Event yang Anda daftarkan sebentar lagi akan dilaksanakan. Sebagai bentuk kepedulian DigiHealth kepada Anda agar kita dapat LEARN AND EARN TOGETHER melalui email ini kami mengingatkan Kegiatan yang sudah Anda daftarkan.</p><br><img width='50%' src='${"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/event/"+response.data.data['banner']}'><img/><p><strong>${response.data.data['title']}</strong> <br><strong> Event Date : ${response.data.data['date']}, ${response.data.data['time']}.<br> Moderator : ${response.data.data['moderator']} <br> Narasumber :  ${output} </strong> <br><br> Silahkan menggunakan Event Room berikut <br> <strong>Event Room : ${response.data.data['linkmeet']} <br>  Meeting ID : ${response.data.data['meetid']} <br> Passcode : ${response.data.data['passcode']} </strong><br><br> Mohon untuk mempersiapkan diri paling tidak 30 menit sebelum Event dimulai sesuai dengan waktu yang telah ditentukan.</p><h3>DigiHealth <br> LEARN AND EARN TOGETHER</h3></center>`
            },
            {
              headers: { 
                  'Content-Type': 'application/json', 
                  'Authorization': token['data']['token']
              }
            }
          )
      }
       
      })
    
    
}
  
export const Sendmailregisters = (nomorpendaftaran) => {
    var token = JSON.parse(localStorage.getItem('userInfo'));
    var event = JSON.parse(localStorage.getItem('recent'));
    var id = event[0]['id'];
     axios
    .get(`/api/v1/events/findid/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(function (response) {
      var sepakers = response.data.data['speakers'] ? response.data.data['speakers'] : []
      const output = sepakers.map(user => user.name);
        axios
          .post(
            `/api/v1/events/sendmail`,
            {
              email:token['data']['user']['email'],
              subject:"Event Reminder",
              body:`<center><img width='50%' src='https://res.cloudinary.com/syiham-group/image/upload/v1634292073/digihealth/logo-digihealth_y4dgtt.png'><img/><br/><br/><br/><h3>Pendaftaran Event Berhasil <br> Nomor Pendaftaran : ${nomorpendaftaran} <br><br> Hai ${token['data']['user']['gelardepan'] ? token['data']['user']['gelardepan'] : ''}, ${token['data']['user']['name'] ? token['data']['user']['name'] : ''}. ${token['data']['user']['gelarbelakang'] ? token['data']['user']['gelarbelakang'] : ''}</h3><p>Tidak terasa Event yang Anda daftarkan sebentar lagi akan dilaksanakan. Sebagai bentuk kepedulian DigiHealth kepada Anda agar kita dapat LEARN AND EARN TOGETHER melalui email ini kami mengingatkan Kegiatan yang sudah Anda daftarkan.</p><br><img width='50%' src='${"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/event/"+response.data.data['banner']}'><img/><p><strong>${response.data.data['title']}</strong> <br><strong> Event Date : ${response.data.data['date']}, ${response.data.data['time']}.<br> Moderator : ${response.data.data['moderator']} <br> Narasumber :  ${output} </strong> <br><br> Silahkan menggunakan Event Room berikut <br> <strong>Event Room : ${response.data.data['linkmeet']} <br>  Meeting ID : ${response.data.data['meetid']} <br> Passcode : ${response.data.data['passcode']} </strong><br><br> Mohon untuk mempersiapkan diri paling tidak 30 menit sebelum Event dimulai sesuai dengan waktu yang telah ditentukan.</p><h3>DigiHealth <br> LEARN AND EARN TOGETHER</h3></center>`
            },
            {
              headers: { 
                  'Content-Type': 'application/json', 
                  'Authorization': token['data']['token']
              }
            }
      )
      window.location.href = '/event_register';
      })
    
    
  }
  