import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import Loading from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";
import { login, register } from "../actions/userActions";
import { updateItem, Sendmailregisters } from "../actions/eventActions";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import $ from 'jquery';
const MySwal = withReactContent(Swal)


function Modallogin({ history }) {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailregis, setEmailregis] = useState("");
    const [passwordregis, setPasswordregis] = useState("");
    const [name, setName] = useState("");
    const [tlp, setTlp] = useState("");
    const dispatch = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const { loading, error, userInfo } = userLogin;
    const userRegister = useSelector((state) => state.userRegister);
    const {
        loading: loadingRegister,
        error: errorRegister,
        success: successRegister,
    } = userRegister;

    useEffect(() => {
        var eventregis = JSON.parse(localStorage.getItem('eventregis'));
        var check = eventregis ? eventregis !== null : 0
        if (userInfo && check !== 0) {
            onUpdate()
        }
    }, [history, userInfo]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(login(email, password));
    };

    const registerHandler = (e) => {
        e.preventDefault();
        dispatch(register(name, emailregis, passwordregis, tlp));

        setEmailregis('');
        setPasswordregis('');
    };

    const onUpdate = () => {
        var user = JSON.parse(localStorage.getItem('userInfo'));
        const nomorpendaftaran = Math.random().toString().substr(2, 10);
        var info = JSON.parse(localStorage.getItem('info'));
        var eventregis = JSON.parse(localStorage.getItem('eventregis'));
       

        var filter = 0
        if (info !== null) {
            filter = eventregis[0]['paticipans'].filter(one => (info.id ? one.idmember === info.id : 0))

        }
       if (info.name === "User" || info.name === "user") {
            $(".close").trigger("click");
            MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkap lengkapi nama anda',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = '/memberdetailprofile';
                }
            })
        } else if (filter.length !== 0) {
            $(".close").trigger("click");
            MySwal.fire({
                icon: 'info',
                text: 'Terima Kasih Akun Anda Sudah Terdaftar',
            })

        } else {
            let recent = [];
            recent.push({ 'id': eventregis[0]['id'] });
            localStorage.setItem('recent', JSON.stringify(recent));

           
            const UpdateRequest = {
                id: eventregis[0]['id'],
                idmember: user['data']['user']['id'],
                nomorpendaftaran: nomorpendaftaran,
                nama: user['data']['user']['name'],
                gelarbelakang: user['data']['user']['gelarbelakang'],
                gelardepan: user['data']['user']['gelardepan'],
                email: user['data']['user']['email'],
                tlp: user['data']['user']['tlp'],
                sertificate: "-",
                skp: "-"
            }
            updateItem(UpdateRequest).then(() => {
                Sendmailregisters(nomorpendaftaran);
                localStorage.removeItem('eventregis')
                // window.location.href = '/event_register';
            }).catch(err => {
                // this.setState({ isUpdate: false, errorMessage: err.message })
            })
        }
    }


    return (
        <>
            <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <center><h5>Belum Punya Akun? <br /> Silahkan Registrasi</h5></center>
                            <form onSubmit={registerHandler}>
                                {errorRegister && <ErrorMessage variant="danger">{errorRegister}</ErrorMessage>}
                                {successRegister && <ErrorMessage variant="success">{successRegister}</ErrorMessage>}
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email</label>
                                    <input name="emailregis"
                                        type="email"
                                        className="form-control" 
                                        value={emailregis}
                                        placeholder="Masukkan email"
                                        onChange={(e) => setEmailregis(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Nomor Telepon</label>
                                    <input name="emailregis"
                                        type="number"
                                        className="form-control" 
                                        value={tlp}
                                        placeholder="Masukkan Nomor Telepon"
                                        onChange={(e) => setTlp(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Nama</label>
                                    <input name="name"
                                        className="form-control" 
                                        value={name}
                                        placeholder="Masukkan Nama"
                                        onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Kata Sandi</label>
                                    <input placeholder="Masukkan kata sandi" name="passwordregis"
                                        type="password"
                                        className="form-control" 
                                        value={passwordregis}
                                        onChange={(e) => setPasswordregis(e.target.value)} />
                                </div>
                                <div className="form-check">
                                    <div className="register-account">
                                        <input id="register-terms-conditions" type="checkbox" className="checkbox" defaultChecked required />
                                        <label htmlFor="register-terms-conditions">Saya sudah membaca dan menyetujui <a href="/termsandconditions">Syarat &amp; Ketentuan</a> dan <a href="/policypivacy">Kebijakan Privasi</a></label>
                                    </div>
                                </div>
                                <div className="col-12 mb-25">
                                    <button className="btn btn-primary btn-block">DAFTAR</button><br />
                                    {loadingRegister && <Loading />}
                                    <center><strong>Saya sudah memiliki Akun</strong></center>
                                    <button className="btn btn-success btn-block" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#exampleModalCenterlogin">MASUK</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade" id="exampleModalCenterlogin" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <center><h5>Login</h5></center>
                            <form onSubmit={submitHandler}>
                                {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email</label>
                                    <input name="email"
                                        type="email"
                                        value={email}
                                        className="form-control" 
                                        placeholder="Masukkan email"
                                        onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Kata Sandi</label>
                                    <input placeholder="Masukkan kata sandi" name="password"
                                        type="password"
                                        className="form-control" 
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <div className="col-12 text-right">
                                        <label htmlFor="register-terms-conditions">
                                            <NavLink to="/forgetpassword">Lupa Kata Sandi</NavLink>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 mb-25">
                                    <button className="btn btn-primary btn-block">MASUK</button><br />
                                </div>
                            </form>
                            {loading && <Loading />}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default Modallogin;
