import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/ErrorMessage";
import { login, register } from "../../actions/userActions";
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { NavLink } from 'react-router-dom';
const style = {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '400px',
    height: '90%',
}

function Login({ history }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailregis, setEmailregis] = useState("");
    const [passwordregis, setPasswordregis] = useState("");
    const [name, setName] = useState("");
    const [tlp, setTlp] = useState("");
    const dispatch = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const { loading, error, userInfo } = userLogin;
    const userRegister = useSelector((state) => state.userRegister);
    const {
      loading: loadingRegister,
      error: errorRegister,
      success: successRegister,
    } = userRegister;

    useEffect(() => {
        if (userInfo) {
            history.push("/");
            // window.location.href = '/';
        }
    }, [history, userInfo]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(login(email, password));
    };

    const registerHandler = (e) => {
        e.preventDefault();
        dispatch(register(name, emailregis, passwordregis, tlp));
        
        setEmailregis('');
        setPasswordregis('');
    };

    return (
        <div id="main-wrapper" className="bg_image-hv2 section">
            <Headers />
            <Headermobile />
            <Menumobile />
            <BottomNavbarMobile menu="account" />
            <BottomNavbarMobilePopup />

            <div className="breadcrumb-section section bg_color--5 pt-60 pt-sm-50 pt-xs-40 pb-60 pb-sm-50 pb-xs-40">
                &nbsp;
            </div>

            <div className="login-register-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-12">
                            <div className="login-register-form-area" style={style}>
                                <div className="login-tab-menu">
                                    <ul className="nav">
                                        <li><a className="active show" data-toggle="tab" href="#login">Login</a></li>
                                        <li><a data-toggle="tab" href="#register">Registrasi</a></li>
                                    </ul>
                                </div>
                                <div className="tab-content">
                                    <div id="login" className="tab-pane fade show active">
                                        <div className="login-register-form">
                                            <form onSubmit={submitHandler}>
                                                {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="single-input">
                                                            <input name="email"
                                                                type="email"
                                                                value={email}
                                                                placeholder="Masukkan email"
                                                                onChange={(e) => setEmail(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="single-input">
                                                            <input placeholder="Masukkan kata sandi" name="password"
                                                                type="password"
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 text-right">
                                                    <label htmlFor="register-terms-conditions">
                                                    <NavLink to="/forgetpassword">Lupa Kata Sandi</NavLink>
                                                    </label>
                                                    </div>
                                                    <div className="col-12 mb-25"><button className="ht-btn">MASUK</button></div>
                                                </div>
                                            </form>
                                            {loading && <Loading />}

                                        </div>
                                    </div>
                                    <div id="register" className="tab-pane fade">
                                        <div className="login-register-form">
                                            <form onSubmit={registerHandler}>
                                            {errorRegister && <ErrorMessage variant="danger">{errorRegister}</ErrorMessage>}
                                            {successRegister && <ErrorMessage variant="success">{successRegister}</ErrorMessage>}
                                                <div className="row row-5">
                                                    <div className="col-12">
                                                        <div className="single-input">
                                                            <input name="name"
                                                                type="text"
                                                                value={name}
                                                                placeholder="Masukkan Nama"
                                                                onChange={(e) => setName(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="single-input">
                                                            <input name="emailregis"
                                                                type="number"
                                                                value={tlp}
                                                                placeholder="Masukkan Nomor Telepon"
                                                                onChange={(e) => setTlp(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="single-input">
                                                            <input name="emailregis"
                                                                type="email"
                                                                value={emailregis}
                                                                placeholder="Masukkan email"
                                                                onChange={(e) => setEmailregis(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="single-input">
                                                            <input placeholder="Masukkan kata sandi" name="passwordregis"
                                                                type="password"
                                                                value={passwordregis}
                                                                onChange={(e) => setPasswordregis(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="register-account">
                                                            <input id="register-newsletter" type="checkbox" className="checkbox" defaultChecked required />
                                                            <label htmlFor="register-newsletter">Saya setuju menerima informasi kegiatan acara dan promosi melalui E-mail / WhatsApp</label>
                                                        </div>
                                                    </div>                                                    
                                                    <div className="col-12">
                                                        <div className="register-account">
                                                            <input id="register-terms-conditions" type="checkbox" className="checkbox" defaultChecked required />
                                                            <label htmlFor="register-terms-conditions">Saya sudah membaca dan menyetujui <a href="/termsandconditions">Syarat &amp; Ketentuan</a> dan <a href="/policypivacy">Kebijakan Privasi</a></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-25"><button className="ht-btn">DAFTAR</button></div>
                                                </div>
                                            </form>
                                            {loadingRegister  && <Loading />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footers />
        </div>
    )
}
export default Login;
