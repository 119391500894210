import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { getjobListclient, addCandidate, CheckCandidate, SendmailLamaranditinjau } from "../../actions/jobAction";
import { setTitle, setDescription } from "../../actions/userActions";
import Moment from 'react-moment';
import 'moment/locale/id';
import Parser from 'html-react-parser';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            id:'' 
        };
    }
    componentDidMount() {
        
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let query = params.get('job');
        if(query !== null )
        {
            this.setState({id:query})
        }else{
            this.props.history.push(`/job`)
        }
        this.receivedData()
    
    }
    calculateTime = (key) => {
        const difference = +new Date(`${key}`) - +new Date();
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-"
        let datenow = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`
        console.log('now'+datenow);
        console.log('new'+key);

        var timeLeft
        if (datenow > key) {
            timeLeft = "Expired"
            return timeLeft;
        } else {
            timeLeft = Math.floor(difference / (1000 * 60 * 60 * 24))
            return timeLeft + 1 + ' Day';
        }

    }

    receivedData() {

        getjobListclient({"ishome": false}).then(res => {
            const data = res['data'] ? res['data'] : [];
            var filterData = data.filter(one => (one.slug === this.state.id))
            if(filterData.length === 0)
            {
                this.props.history.push(`/job`)
                
            }else{
                setTitle(filterData[0]['jobtitle'])
                setDescription(filterData[0]['jobtitle'])
                this.setState(
                    {
                        data: [...filterData]
                    }
                )
            }
        })

    }

    onLamar = (id, jobname, company, location, type, psychotest, e) => {
        
        e.preventDefault()
        var info = JSON.parse(localStorage.getItem('info'));
        if (info === null) {
             MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap Login terlebih dahulu!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                e.preventDefault()
                if (result.isConfirmed) {
                    window.location.href = '/login'
                }
            })
        }else if (info.gelardepan === undefined || info.gelarbelakang === undefined || info.tlp === undefined) {
            
            MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkapi Profil Umum dan Pribadi, Alamat, Pendidikan dan upload CV Anda terlebih dahulu.!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = '/memberdetailprofile'
                }
            })
            
        } else if (info.nip === undefined || info.str === undefined || info.npkidi === undefined || info.nik === undefined || info.tempatlahir === undefined || info.tanggallahir === undefined || info.profesi === "-") {

            MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkapi Profile Pribadi Anda terlebih dahulu!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                       window.location.href = '/memberdetailprofile'

                }
            })
            
        } else if (info.address.length === 0) {

            MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkapi informasi Alamat Anda terlebih dahulu!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = '/listaddress'
                }
            })
            
        } else if (info.education.length === 0) {

            MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkapi informasi Pendidikan Anda terlebih dahulu!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = '/listpendidikan'
                }
            })
            
        } else {
            CheckCandidate(id).then(res => {
                const data = res['data'] ? res['data'] : [];
                var filterbyuser = data.filter(item => (info.email ? item.email === info.email : []))
                var today = new Date();
                var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                if (filterbyuser.length === 0) {
                    const add = {
                        applydate:date,
                        email:info.email,
                        gelardepan:info.gelardepan,
                        gelarbelakang:info.gelarbelakang,
                        phone:info.tlp,
                        status:'Lamaran Ditinjau',
                        name:info.name,
                        jobid:id,
                        jobtitle:jobname
                    }
                    let payloadjob = [];
                    payloadjob.push({ 'id': id, 'name': jobname, 'company':company, 'location':location, 'type':type, 'psychotest':psychotest });
                    localStorage.setItem('payloadjob', JSON.stringify(payloadjob));
                    addCandidate(add)
                    SendmailLamaranditinjau()
                    this.props.history.push(`/success-apply`)

                }else{
                    MySwal.fire({
                        icon: 'info',
                        title: 'Oops...',
                        text: 'Mohon maaf anda telah melamar pekerjaan ini !'
                    })
                }
            })
        }


    }




    render() {
        return (
            <div id="main-wrapper">
                <Headers />
                <Headermobile />
                <Menumobile />
                <BottomNavbarMobile menu="job" />
                <BottomNavbarMobilePopup />
                <br/>
                {this.state.data
                .map((one, index) => (
                <>
                <div className="breadcrumb-section section bg_color--5 pt-120 pt-lg-100 pt-md-100 pt-sm-100 pt-xs-100" key={index}>
                    <div className="container job-content-box">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-breadcrumb-content">
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Beranda</a></li>
                                        <li><a href="job">Pekerjaan</a></li>
                                        <li>Detail Pekerjaan</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="job-head-content">
                                    <h1 className="title">{one.jobtitle}</h1>
                                    <p className="employer-name"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className="job-details-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
                    <div className="container job-content-box st-border">
                        <div className="row">
                            <div className="col-lg-4 order-lg-2 order-2 mt-sm-60 mt-xs-50">
                                <div className="sidebar-wrapper-three">
                                    <div className="common-sidebar-widget sidebar-three">
                                        <h2 className="sidebar-title">Detail Pekerjaan</h2>
                                        <div className="sidebar-meta">
                                            <div className="row no-gutters">
                                                <div className="col-lg-12">
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-user" />
                                                            <span>TIPE: </span>
                                                        </div>
                                                        <div className="field-value"><strong>{one.jobtype}</strong></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-apartment" />
                                                            <span>Nama Perusahaan </span>
                                                        </div>
                                                        <div className="field-value"><strong>{one.companyname}</strong></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-map-marker" />
                                                            <span>Lokasi Pekerjaan </span>
                                                        </div>
                                                        <div className="field-value"><strong>{one.joblocation}</strong></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-calendar-full" />
                                                            <span>Tanggal Posting </span>
                                                        </div>
                                                        <div className="field-value"><strong><Moment locale="id" format="dddd, DD MMM YYYY">{one.posdate}</Moment></strong></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-calendar-full" />
                                                            <span>Lamar Sebelum </span>
                                                        </div>
                                                        <div className="field-value"><strong><Moment locale="id" format="dddd, DD MMM YYYY">{one.expiredate}</Moment></strong></div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 order-lg-1 order-1 pr-55 pr-md-15 pr-sm-15 pr-xs-15">
                                <div className="job-detail-content">
                                    <div className="field-descriptions mt-xs-20 mb-60 mb-sm-30 mb-xs-30">
                                        <h2 className="sidebar-title">Deskripsi</h2>
                                        <p>{Parser(one.jobdescription)}</p>
                                    </div>
                                    <div className="job-apply">
                                    {this.calculateTime(one.expiredate) !== "Expired" && one.isapply === true ? <button className="ht-btn transparent-btn-two w-100" onClick={this.onLamar.bind(this, one.id,  one.jobtitle, one.companyname, one.joblocation, one.jobtype, one.psychotest)}>Lamar Sekarang</button> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
                ))}
                <Footers />
            </div>


        )
    }
}
