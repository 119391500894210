import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/ErrorMessage";
import { forgetPasswordAction } from "../../actions/userActions";
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
const style = {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '400px',
    height: '90%',
}
function Forgetpassword({ history }) {
    const [email, setEmail] = useState("");

    const dispatch = useDispatch();

    const forgetPassword = useSelector((state) => state.forgetPassword);
    const { loading, error, success, userInfo } = forgetPassword;
    useEffect(() => {
        if (userInfo) {
            history.push("/");
            // window.location.href = '/';
        }
    }, [history, userInfo]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(forgetPasswordAction(email));
    };

    return (
        <div id="main-wrapper" >
            <Headers />
            <Headermobile />
            <Menumobile />
            <BottomNavbarMobile menu="account" />
            <BottomNavbarMobilePopup />

            <div className="breadcrumb-section section bg_color--5 pt-60 pt-sm-50 pt-xs-40 pb-60 pb-sm-50 pb-xs-40">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-breadcrumb-content">
                                
                                <h1>&nbsp;</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="login-register-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-12">
                            <div className="login-register-form-area" style={style}>
                                <div className="login-tab-menu">
                                   <center><h4>Lupa Kata Sandi</h4></center>
                                </div>
                                <div className="tab-content">
                                    <div id="login" className="tab-pane fade show active">
                                        <div className="login-register-form">
                                            <form onSubmit={submitHandler}>
                                                {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
                                                {success && <ErrorMessage variant="warning">{success}</ErrorMessage>}

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="single-input" >
                                                            <input name="email"
                                                                type="email"
                                                                value={email}
                                                                placeholder="Masukkan email"                                                                
                                                                onChange={(e) => setEmail(e.target.value)} />
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="col-12 mb-25"><button className="ht-btn">KIRIM</button></div>
                                                </div>
                                            </form>
                                            {loading && <Loading />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footers />
        </div>
    )
}
export default Forgetpassword;
