import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment/locale/id';
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import Popupmenu from "../../components/Popupmenu";
import { getListclient, updateItem, Sendmailregister } from "../../actions/eventActions";
import { getjobListclient, addCandidate, CheckCandidate, SendmailLamaranditinjau } from "../../actions/jobAction";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { setTitle, setDescription } from "../../actions/userActions";
import ModalSepuluh from '../../components/ModalSepuluh';
import Modallogin from "../../components/Modallogin";
import $ from 'jquery';
const MySwal = withReactContent(Swal)
const bucket = process.env.REACT_APP_API_KEY


export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEvent: [],
            dataJob: [],
            title: '',
            searchTitle: '',
            searchActive: '',
            speakers: [{ name: "", materi: "" }],
            paticipans: [{ idmember: "", nomorpendaftaran: "", nama: "", gelarbelakang: "", gelardepan: "", email: "", tlp: "", sertificate: "", skp: "" }],
            id: '',
            datetime: '',
            modalState: true
        }
        this.onChange = this.onChange.bind(this)
        this.filterHandler = this.filterHandler.bind(this)
        this.handleShow = this.handleShow.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
        this.receivedData()

    }

    filterHandler(e) {
        e.preventDefault();
        if (this.state.title !== "") {
            window.location.replace("/job?title=" + this.state.title);
        }
    }

    onDetail(id, e) {
        let recent = [];
        recent.push({ 'id': id });
        localStorage.setItem('recent', JSON.stringify(recent));
        this.props.history.push(`/event_detail`)
    }

    receivedData() {
        getListclient().then(res => {
            const data = res['data'] ? res['data'] : [];
            const servertime = res['message'] ? res['message'] : [];
            this.setState({ datetime : servertime })
            const newdata = []
            for (let index = 0; index < data.length; index++) {
                if(this.calculateTime(data[index]['date'], data[index]['time'], data[index]['timeend']) === "Selesai" )
                {
                    var newpriority = Math.floor(Math.random() * 100000);
                    newdata.push({
                        "id":data[index]['id'],
                        "ishome":data[index]['ishome'],
                        "active":data[index]['active'],
                        "banner":data[index]['banner'],
                        "date":data[index]['date'],
                        "desc":data[index]['desc'],
                        "isapply":data[index]['isapply'],
                        "skp":data[index]['skp'],
                        "linkmeet":data[index]['linkmeet'],
                        "meetid":data[index]['meetid'],
                        "moderator":data[index]['moderator'],
                        "passcode":data[index]['passcode'],
                        "paticipans":data[index]['paticipans'],
                        "priority":newpriority,
                        "sertificate":data[index]['sertificate'],
                        "slug":data[index]['slug'],
                        "speakers":data[index]['speakers'],
                        "time":data[index]['time'],
                        "timeend":data[index]['timeend'],
                        "title":data[index]['title'],
                        "type":data[index]['type'],
                    })
                }else{
                    newdata.push({
                    "id":data[index]['id'],
                    "ishome":data[index]['ishome'],
                    "active":data[index]['active'],
                    "banner":data[index]['banner'],
                    "date":data[index]['date'],
                    "desc":data[index]['desc'],
                    "isapply":data[index]['isapply'],
                    "skp":data[index]['skp'],
                    "linkmeet":data[index]['linkmeet'],
                    "meetid":data[index]['meetid'],
                    "moderator":data[index]['moderator'],
                    "passcode":data[index]['passcode'],
                    "paticipans":data[index]['paticipans'],
                    "priority":data[index]['priority'],
                    "sertificate":data[index]['sertificate'],
                    "slug":data[index]['slug'],
                    "speakers":data[index]['speakers'],
                    "time":data[index]['time'],
                    "timeend":data[index]['timeend'],
                    "title":data[index]['title'],
                    "type":data[index]['type'],
                })
              }
              this.setState({dataEvent: [...newdata]})

                
            }
        })
    }

    receivedjobData() {
        getjobListclient({ "ishome": true }).then(res => {
            const data = res['data'] ? res['data'] : [];
            this.setState(
                {
                    dataJob: [...data]
                }
            )

            
        })
    }

    calculateTimeLeftjob = (key) => {
        const difference = +new Date(`${key}`) - +new Date();
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-"
        let datenow = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`
        var timeLeft
        if (datenow > key) {
            timeLeft = "Selesai"
            return timeLeft;
        } else {
            timeLeft = Math.floor(difference / (1000 * 60 * 60 * 24))
            return timeLeft + 1 + ' Day';
        }

    };

    calculateTime = (dateevent, timestart, timeend ) => {
         let str = this.state.datetime;
        const server = str.split(" ");
        let datenow = server[0]
        let timenow = server[1]
        const difference = +new Date(`${dateevent}`) - +new Date(`${datenow}`);
        var timeLeft
        if (datenow > dateevent) {
            timeLeft = "Selesai"
            return timeLeft;
        } else if (datenow === dateevent && timenow === timestart) {
            timeLeft = "Sedang Berlangsung"
            return timeLeft;   
        } else if (datenow === dateevent && timenow > timestart && timenow < timeend) {
            timeLeft = "Sedang Berlangsung"
            return timeLeft;     
        } else if (datenow === dateevent && timenow > timeend | timenow === timeend) {
             timeLeft = "Selesai"
            return timeLeft;     
        }else {
            timeLeft = Math.floor(difference / (1000 * 60 * 60 * 24))
            return timeLeft +' Hari';
        }

    }

    onLamar = (id, jobname, company, location, type, psychotest, e) => {

        e.preventDefault()
        var info = JSON.parse(localStorage.getItem('info'));
        if (info === null) {
             MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap Login terlebih dahulu!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                e.preventDefault()
                if (result.isConfirmed) {
                    window.location.href = '/login'
                }
            })
        }else if (info.gelardepan === undefined || info.gelarbelakang === undefined || info.tlp === undefined) {
            
            MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkapi Profil Umum dan Pribadi, Alamat, Pendidikan dan upload CV Anda terlebih dahulu.!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = '/memberdetailprofile'
                }
            })
            
        } else if (info.nip === undefined || info.str === undefined || info.npkidi === undefined || info.nik === undefined || info.tempatlahir === undefined || info.tanggallahir === undefined || info.profesi === "-") {

            MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkapi Profile Pribadi Anda terlebih dahulu!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                       window.location.href = '/memberdetailprofile'

                }
            })
            
        } else if (info.address.length === 0) {

            MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkapi informasi Alamat Anda terlebih dahulu!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = '/listaddress'
                }
            })
            
        } else if (info.education.length === 0) {

            MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkapi informasi Pendidikan Anda terlebih dahulu!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = '/listpendidikan'
                }
            })
            
        } else {
            CheckCandidate(id).then(res => {
                const data = res['data'] ? res['data'] : [];
                var filterbyuser = data.filter(item => (info.email ? item.email === info.email : []))
                var today = new Date();
                var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                if (filterbyuser.length === 0) {
                    const add = {
                        applydate:date,
                        email:info.email,
                        gelardepan:info.gelardepan,
                        gelarbelakang:info.gelarbelakang,
                        phone:info.tlp,
                        status:'Lamaran Ditinjau',
                        name:info.name,
                        jobid:id,
                        jobtitle:jobname

                    }
                    let payloadjob = [];
                    payloadjob.push({ 'id': id, 'name': jobname, 'company':company, 'location':location, 'type':type, 'psychotest':psychotest });
                    localStorage.setItem('payloadjob', JSON.stringify(payloadjob));
                    addCandidate(add)
                    SendmailLamaranditinjau()
                    this.props.history.push(`/success-apply`)

                }else{
                    MySwal.fire({
                        icon: 'info',
                        title: 'Oops...',
                        text: 'Mohon maaf anda telah melamar pekerjaan ini !'
                    })
                }
            })
        }


    }

    onUpdate = (paticipans, speakers, id, e) => {
        e.preventDefault()
        var user = JSON.parse(localStorage.getItem('userInfo'));
        const nomorpendaftaran = Math.random().toString().substr(2, 10);
        var info = JSON.parse(localStorage.getItem('info'));
        
        this.setState({
            paticipans: [],
            speakers: [],
            id: ""
        })
        var filter = 0
        if (info !== null) {
            filter = paticipans.filter(one => (info.id ? one.idmember === info.id : 0))

        }
        if (info === null) {
            let eventregis = [];
            eventregis.push({ 'paticipans': paticipans, 'speakers': speakers, 'id': id });
            localStorage.setItem('eventregis', JSON.stringify(eventregis));
            $("#login").trigger("click");
        }
        else if (info.name === "User" || info.name === "user") {

            MySwal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'Harap lengkapi nama Anda terlebih dahulu!',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK',
                denyButtonText: `Don't save`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.props.history.push(`/memberdetailprofile`)
                }
            })

        } else if (filter.length !== 0) {

            MySwal.fire({
                icon: 'info',
                text: 'Terima Kasih Akun Anda Sudah Terdaftar',
            })
            localStorage.removeItem('recent')
            localStorage.removeItem('eventregis')

        } else {
            let recent = [];
            recent.push({ 'id': id });
            localStorage.setItem('recent', JSON.stringify(recent));
            this.setState({
                paticipans: paticipans,
                speakers: speakers,
                id: id
            })

            const UpdateRequest = {
                id: id,
                idmember: user['data']['user']['id'],
                nomorpendaftaran: nomorpendaftaran,
                nama: user['data']['user']['name'],
                gelarbelakang: user['data']['user']['gelarbelakang'],
                gelardepan: user['data']['user']['gelardepan'],
                email: user['data']['user']['email'],
                tlp: user['data']['user']['tlp'],
                sertificate: "-",
                skp: "-"
            }
            updateItem(UpdateRequest).then(() => {
                Sendmailregister(nomorpendaftaran);
                this.props.history.push(`/event_register`)
            }).catch(err => {
                // this.setState({ isUpdate: false, errorMessage: err.message })
            })
        }
    }


    componentDidMount() {
        this.receivedData()
        this.receivedjobData()
        localStorage.removeItem('recent')
        localStorage.removeItem('eventregis')
        localStorage.removeItem('payloadjob')
        this.setState({
            paticipans: [],
            speakers: [],
            id: ""
        })
        setTitle('Home')
        setDescription('Kami berkomitmen untuk membangun komunitas tenaga kesehatan dengan selalu meningkatkan kualitas dan membantu tenaga kesehatan menemukan kesempatan kerja yang spesifik di dunia kesehatan.')
    }

    handleShow() {
        this.setState({ modalState: !this.state.modalState });
    }

    render() {
        return (
            <div id="main-wrapper" className="bg_image-hv2 section">
                <Headers />
                <Headermobile />
                <Menumobile />
                <Popupmenu />
                <BottomNavbarMobile menu="home" />
                <BottomNavbarMobilePopup />

                {/*slider section start*/}
                <div className="hero-section section position-relative">
                    {/*Hero Item start*/}
                    <div className="hero-item hero-item-two bg_image-v2">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    {/*Hero Content start*/}
                                    <div className="hero-content-3 left">
                                        <h2 className="title">LEARN AND EARN</h2>
                                        <h3 className="sub-title">LOVE WHAT YOU DO</h3>
                                        <p>Kami berkomitmen untuk membangun komunitas tenaga kesehatan dengan selalu meningkatkan kualitas dan membantu tenaga kesehatan menemukan kesempatan kerja yang spesifik di dunia kesehatan.</p>
                                        <div className="job-search-wrap-two mt-50">
                                            <div className="job-search-form">
                                                <form onSubmit={() => this.filterHandler()}>
                                                    {/* Single Field Item Start  */}
                                                    <div className="single-field-item keyword">
                                                        <label htmlFor="keyword">Cari lowongan pekerjaan</label>
                                                        <input placeholder="Apa pekerjaan yang kamu inginkan?" value={this.state.title} name="title" onChange={this.onChange} type="text" />
                                                    </div>
                                                    {/* Single Field Item End  */}
                                                    <div className="submit-btn">
                                                        <button className="ht-btn" onClick={this.filterHandler.bind(this)} >Cari</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    {/*Hero Content end*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Hero Item end*/}
                </div>
                {/*slider section end*/}

                {/* Work Section Start  */}
                <div className="work-section section pb-100 pb-lg-80 pb-md-60 pb-sm-40 pb-xs-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title-wrap mb-45 mb-sm-30 mb-xs-20">
                                    <div className="section-title">
                                        <h3 className="title text-uppercase">BAGAIMANA KAMI BEKERJA</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                {/* Single Work Start  */}
                                <div className="single-work">
                                    <div className="work-image">
                                        <img src="assets/images/icon_apply_learn.png" alt="k" />
                                    </div>
                                    <div className="work-content">
                                        <h3 className="title">LEARN</h3>
                                        <p>Berkoneksi dengan tenaga kesehatan lainnya yang memiliki ketertarikan serupa, menemukan hal baru untuk dipelajari, dan meningkatkan pengetahuan dengan konten daring.</p>
                                    </div>
                                </div>
                                {/* Single Work End  */}
                            </div>
                            <div className="col-lg-6 col-md-6">
                                {/* Single Work Start  */}
                                <div className="single-work">
                                    <div className="work-image">
                                        <img src="assets/images/icon_apply.png" alt="l" />
                                    </div>
                                    <div className="work-content">
                                        <h3 className="title">EARN</h3>
                                        <p>Mulai mendapatkan penghasilan dari kesehatan digital, berpeluang besar sebagai penulis dan peninjau konten medis, penerjemah, pembuat konten, telemedisin, layanan ke rumah, vaksinator, dll.</p>
                                    </div>
                                </div>
                                {/* Single Work End  */}
                            </div>

                        </div>
                    </div>
                </div>
                {/* Work Section End  */}
                {/* Event Section Start  */}
                {(!this.state.dataEvent) ? "" :
                    <div className="event-section section pt-30 pt-lg-30 pt-md-30 pt-sm-30 pt-xs-30 pb-90 pb-lg-70 pb-md-50 pb-sm-30 pb-xs-20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title-wrap mb-20">
                                        <div className="section-title">
                                            <h3 className="title"> Kegiatan Terbaru</h3>
                                        </div>
                                        <div className="jetapo-link">
                                            <a href="/event"> Lihat Semua Kegiatan <i className="lnr lnr-chevron-right" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {this.state.dataEvent 
                                    .sort((a, b) => a.priority > b.priority ? 1 : -1)
                                    .filter(one => (one.ishome === "true" && one.active === "true"))
                                    .map((one, index) => (
                                        <div className="col-lg-4 col-md-6 mb-30" key={index}>
                                            {/* Event Blog Start */}
                                            <div className="event-blog">
                                                <div className="event-image">
                                                    <a href={'/events/' + one.slug}>
                                                        <img src={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/" + bucket + "/event/" + one.banner} alt="banner" />
                                                    </a>
                                                    <div className="event-badge">
                                                        <span>{this.calculateTime(one.date, one.time, one.timeend)}</span>
                                                    </div>
                                                </div>
                                                <div className="event-content">
                                                    <div className="event-type">
                                                        <span>{one.type}</span>
                                                        {(() => {
                                                            if (one.isapply === "true" && this.calculateTime(one.date,  one.time, one.timeend) === "Selesai" | this.calculateTime(one.date, one.time, one.timeend) === "Sedang Berlangsung" ) {
                                                                return (
                                                                    <></>
                                                                )
                                                            }else if (one.isapply === "false" && this.calculateTime(one.date,  one.time, one.timeend) === "Selesai" | this.calculateTime(one.date, one.time, one.timeend) === "Sedang Berlangsung" ) {
                                                                return (
                                                                    <></>
                                                                )
                                                            } else  if (one.isapply === "true") {
                                                                return (
                                                                    <><button className="btn btn-sm btn-success" onClick={this.onUpdate.bind(this, one.paticipans, one.speakers, one.id)}>Daftar Sekarang</button></>
                                                                )  
                                                            }
                                                        })()}
                                                    </div>
                                                    <div className="event-meta">
                                                        <p className="event-date">
                                                            <i className="lnr lnr-calendar-full" />
                                                            <span className="text"></span>
                                                            <span className="author"><Moment locale="id" format="DD MMM YYYY">{one.date}</Moment></span>
                                                        </p>
                                                        <p className="event-time">
                                                            <i className="lnr lnr-clock" />
                                                            <span className="text"></span>
                                                            <span className="author">{one.time}  <strong>-</strong> {one.timeend}</span>
                                                        </p>
                                                        <p className="event-skp">
                                                            <i className="lnr lnr-check" />
                                                            <span className="text">SKP : </span>
                                                            <span className="author"><b>{one.skp}</b></span>
                                                        </p>
                                                    </div>
                                                    <h4 className="event-title">
                                                        <a href={'/events/' + one.slug}>{one.title}</a>
                                                    </h4>
                                                    <div className="event-info">
                                                        <div className="info-title">Pembicara</div>
                                                        <div className="info-value">
                                                            {one.speakers.map((value, key) => <span key={key}>{value.name}, </span>)}
                                                        </div>
                                                    </div>
                                                    <div className="event-info">
                                                        <div className="info-title">Moderator</div>
                                                        <div className="info-value">
                                                            {one.moderator}
                                                        </div>
                                                    </div>
                                                    <div className="read-more">
                                                        <a href={'/events/' + one.slug}>Lihat Detail <i className="lnr lnr-chevron-right" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Event Blog End */}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                }
                {/*  Event Section End */}
                {/* Job Section Start */}
                {(!this.state.dataJob) ? "" :
                    <div className="job-section section pb-90 pb-lg-70 pb-md-50 pb-sm-30 pb-xs-20">
                        <div className="container st-border pt-30 pt-lg-30 pt-md-30 pt-sm-30 pt-xs-30">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title-wrap mb-45 mb-xs-30">
                                        <div className="section-title">
                                            <h3 className="title">Lowongan Pekerjaan</h3>
                                        </div>
                                        <div className="jetapo-link">
                                            <a href="/job">Lihat Semua Pekerjaan <i className="lnr lnr-chevron-right" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {this.state.dataJob && this.state.dataJob
                                    .sort((a, b) => a.priority > b.priority ? 1 : -1)
                                    .filter(one => (one.ishome && one.active))
                                    .map((one, index) => (
                                        <div className="col-md-4 mb-20" key={index} style={this.calculateTimeLeftjob(one.expiredate) !== "Selesai" ? {} : { display: 'none' }}>
                                            {/* Single Job Start  */}
                                            <div className="digi-job-grid">
                                                <div className="digi-job-badge">
                                                    <span>{one.jobtype}</span>
                                                </div>
                                                <div className="job-info">
                                                    <div className="job-title">
                                                        <a href={"jobs/" + one.slug}>{one.jobtitle}</a>
                                                    </div>
                                                    <div className="job-company">
                                                        {one.companyname}
                                                    </div>
                                                    <div className="job-location">
                                                        {one.joblocation}
                                                    </div>
                                                    <div className="job-action">
                                                        {this.calculateTimeLeftjob(one.expiredate) !== "Selesai" && one.isapply === true ? <button className="ht-btn transparent-btn-two w-100" onClick={this.onLamar.bind(this, one.id,  one.jobtitle, one.companyname, one.joblocation, one.jobtype, one.psychotest)}>Lamar Sekarang</button> : null}
                                                        <a href={"jobs/" + one.slug} rel="noopener noreferrer" className="read-more">Baca Selengkapnya <i className="lnr lnr-chevron-right" /></a>
                                                    </div>

                                                    <div className="job-meta">
                                                        <div className="field-datetime">Ditayangkan pada : <Moment locale="id" fromNow>{one.postdate}</Moment></div>
                                                        <div className="field-datetime">Lamar sebelum &nbsp;&nbsp;&nbsp; : <Moment locale="id" format="dddd, DD MMM YYYY">{one.expiredate}</Moment></div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Single Job End */}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                }
                {/* Job Section End */}

                <Footers />

                <ModalSepuluh show={this.state.modalState} click={this.handleShow} />
                <button type="button" className="btn btn-primary" style={{ display: "none" }} id="login" data-toggle="modal" data-target="#exampleModalCenter">
                    register
                </button>
                <Modallogin />
            </div>



        )
    }
}
