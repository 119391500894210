import React, { useEffect, useState } from "react";
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { useSelector, useDispatch } from "react-redux";
import { updateUserAction, upload, deletecontent } from "../../actions/userActions";
import ErrorMessage from "../../components/ErrorMessage";
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const font = {
    fontSize: '12px',
}
const margin = {
    marginRight: "20px"
}

function Edit({ history }) {
    const dispatch = useDispatch();
    const [title, setTitle] = useState("");
    const [index, setIndex] = useState(0);
    const [name, setName] = useState("");
    const [gelardepan, setGelardepan] = useState("");
    const [gelarbelakang, setGelarbelakang] = useState("");
    const [tlp, setTlp] = useState("");
    const [nip, setNip] = useState("");
    const [email, setEmail] = useState("");
    const [str, setStr] = useState("");
    const [npkidi, setNpkidi] = useState("");
    const [nik, setNik] = useState("");
    const [tempatlahir, setTempatlahir] = useState("");
    const [tanggallahir, setTanggallahir] = useState("");
    const [profesi, setProfesi] = useState("");
    const [spesialisasi, setSpesialisasi] = useState("");
    const [consltant, setConsltant] = useState("true");
    const [address, setAddress] = useState([]);
    const [education, setEducation] = useState([]);
    const [educationlist, setEducationlist] = useState([]);
    const [jobs, setJobs] = useState([]);
    const userUpdate = useSelector((state) => state.userUpdate);
    const userLogin = useSelector((state) => state.userLogin);
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [institusi, setInsitusi] = useState("");
    const [jenjang, setJenjang] = useState("");
    const [ijazah, setIjazah] = useState("");
    const [sertifikat, setSertifikat] = useState([{ name: "", file: "-" }]);
    const [cv, setCv] = useState("");
    const [strfile, setStrfile] = useState("");
    const [bank, setBank] = useState("");
    const bucket = process.env.REACT_APP_API_KEY
    const [profile, setProfile] = useState("");

    const {
        error: errorUpdate,
        success: successUpdate,
    } = userUpdate;
    const { userInfo } = userLogin;
    useEffect(() => {
        if (userInfo) {
            history.push("/listpendidikan");
            var prod = JSON.parse(localStorage.getItem('info'));
            setName(prod['name']);
            setGelardepan(prod['gelardepan']);
            setGelarbelakang(prod['gelarbelakang']);
            setTlp(prod['tlp']);
            setEmail(prod['email']);
            setNip(prod['nip']);
            setStr(prod['str']);
            setNpkidi(prod['npkidi']);
            setNik(prod['nik']);
            setTempatlahir(prod['tempatlahir']);
            setTanggallahir(prod['tanggallahir']);
            setProfesi(prod['profesi']);
            setSpesialisasi(prod['spesialisasi']);
            setConsltant(prod['consltant']);
            setProfile(prod['profile']);
            if (prod['address'].length !== 0) {
                setAddress(prod['address']);
            }
            if (prod['education'].length !== 0) {
                setEducation(prod['education']);
                setEducationlist(prod['education']);
            }
            if (prod['jobs'].length !== 0) {
                setJobs(prod['jobs']);
            }
            if(prod['cv'] !== undefined){
                setCv(prod['cv']);
            }
            if(prod['strfile'] !== undefined){
                setStrfile(prod['strfile']);
            }
            if(prod['bank'] !== undefined){
                setBank(prod['bank']);
            }
            if(prod['sertifikat'] !== null ){
                setSertifikat(prod['sertifikat']);
            }
        } else {
            history.push("/login");

        }
    }, [
        history,
        userInfo,
        successUpdate,
    ]);

    const resetHandler = () => {
        setStart("");
        setEnd("");
        setInsitusi("");
        setJenjang("");
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateUserAction(name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs, strfile, bank, cv, sertifikat, profile));
        if (!name) return;
        resetHandler();
        history.push("/listpendidikan");
        // window.location.reload()
    };

    const handleUploadClick = (pics, index) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png" || pics.type === "application/pdf")) {
            document.getElementById("upload" + index).style.display = 'block';
            document.getElementById("preview" + index).style.display = 'none';
            const list = [...education];
            if (list[index]['ijazah'] !== undefined) {
                const deleteRequest = {
                    path: bucket+'/ijazah',
                    file: list[index]['ijazah']
                }
                deletecontent(deleteRequest)
            }
            const ijazahRequest = {
                path: bucket+'/ijazah',
                myFile: pics
            }

            upload(ijazahRequest).then((res) => {
                
                const list = [...education];
                        list[index]['ijazah'] = res.data
                        setEducation(list);
                        document.getElementById("upload" + index).style.display = 'none';
                        document.getElementById("preview" + index).style.display = 'block';
                        document.getElementById("preview" + index).href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/ijazah/"+res.data


            }).catch((err) =>{
                document.getElementById("upload" + index).style.display = 'none';
                document.getElementById("preview" + index).style.display = 'none';
                MySwal.fire('Oops...', 'mohon maaf sepertinya file yang anda masukan terlalu besar' , 'info')
            })
          
        } else {
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type atau ukuran file yang anda masukan tidak cocok', 'info')
        }
    };

    const handleUploadClickedit = (pics, index) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png" || pics.type === "application/pdf")) {
            document.getElementById("upload_edit").style.display = 'block';
            document.getElementById("preview_edit").style.display = 'none';
            if (ijazah !== undefined) {
                    const deleteRequest = {
                        path: bucket+'/ijazah',
                        file: ijazah
                    }
                    deletecontent(deleteRequest)
            }
             const ijazahRequest = {
                path: bucket+'/ijazah',
                myFile: pics
            }

            upload(ijazahRequest).then((res) => {
                setIjazah(res.data);
                document.getElementById("upload_edit").style.display = 'none';
                document.getElementById("preview_edit").style.display = 'block';
                document.getElementById("preview_edit").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/ijazah/"+res.data
            }).catch((err) => {
                document.getElementById("upload_edit").style.display = 'none';
                document.getElementById("preview_edit").style.display = 'none';
                MySwal.fire('Oops...', 'mohon maaf sepertinya file yang anda masukan terlalu besar' , 'info')
            })
            
        } else {
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type atau ukuran file yang anda masukan tidak cocok', 'info')
        }
    };

    const updateHandler = (e) => {
        e.preventDefault();
        const list = [...education];
        list[index]['mulai'] = start;
        list[index]['lulus'] = end;
        list[index]['institusi'] = institusi;
        list[index]['jenjang'] = jenjang;
        list[index]['ijazah'] = ijazah;
        setEducation(list);
        dispatch(updateUserAction(name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs));
        resetHandler();
        history.push("/listpendidikan");
    };


    var x = index
    // handle click event of the Add button
    const handleAddClick = () => {
        setTitle("Tambah Riwayat Pendidikan")
        document.getElementById('mulai').value = '';
        document.getElementById('lulus').value = '';
        document.getElementById('institusi').value = '';
        document.getElementById('jenjang').value = 'SD';
        document.getElementById('ijazah').value = '';

        var awal = education.length
        setIndex(awal)
        setEducation([...education, { mulai: "", lulus: "", institusi: "", jenjang: "SD", ijazah: "-" }]);
    };

    const handleEditClick = (id, start, end, institusi, jenjang, ijazah, e) => {
        setTitle("Edit Pendidikan")
        var awal = id
        setIndex(awal)
        setStart(start);
        setEnd(end);
        setInsitusi(institusi);
        setJenjang(jenjang);
        document.getElementById('ijazah').value = '';
        document.getElementById("preview_edit").style.display = 'none';
        if (ijazah !== "") {
            document.getElementById("preview_edit").style.display = 'block';
            document.getElementById("preview_edit").href = ijazah
        }
    };

    const handleReset = () => {
        setTitle("Tambah Pendidikan Baru")
        const list = [...education];
        list.splice(index, 1);
        setEducation(list);
        setIndex(0)
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...education];
        list[index][name] = value;
        setEducation(list);
    };



    // handle click event of the Remove button
    const handleRemoveClick = key => {
        const list = [...education];
        list.splice(key, 1);
        setEducation(list);
        dispatch(updateUserAction(name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, list, jobs));
        window.location.reload('/listpendidikan')
    };


    return (
        <div id="main-wrapper">
            <Headers />
            <Headermobile />
            <Menumobile />
            <BottomNavbarMobile />
            <BottomNavbarMobilePopup />

            <div className="dashboard-content-section section bg_color--5">
                <div className="container-fluid p-0">
                    <div className="row no-gutters">
                        {/* <Menudashboard /> */}
                        <div className="col-xl-12 col-lg-9">
                            <div className="dashboard-main-inner">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-breadcrumb-content mb-40">
                                            <h4>&nbsp;</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-overview">
                                    <div className="row">
                                        <div className="col-xl-12 col-12">
                                            <div className="profile-applications mb-50">
                                                <div className="profile-applications-heading">
                                                    <ul className="nav">
                                                        <li key="1" style={margin}><NavLink to="/memberdetailprofile" style={font}>Profile</NavLink></li>
                                                        <li key="2" style={margin}><NavLink to="/daftar_activity_admin" style={font}>Aktivitas</NavLink></li>
                                                        <li key="3" style={margin}><NavLink to="/listaddress" style={font}>Alamat</NavLink></li>
                                                        <li key="4" style={margin}><NavLink to="/listpendidikan" style={font}>Pendidikan</NavLink></li>
                                                        <li key="5" style={margin}><NavLink to="/listpekerjaan" style={font}>Pekerjaan</NavLink></li>
                                                        <li key="6" style={margin}><NavLink to="/attachmentFile" style={font}>Lampiran</NavLink></li>
                                                    </ul>
                                                </div>


                                                {errorUpdate && <ErrorMessage variant="danger">{errorUpdate}</ErrorMessage>}
                                                <div className="profile-applications-main-block">

                                                    <div className="profile-applications-form">
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <strong>Daftar Pendidikan</strong>
                                                            </div>
                                                            <div className="col-md-2 text-right">
                                                                <button className="btn btn-primary " data-toggle="modal" data-target="#paycard" onClick={handleAddClick} ><i className="fas fa-plus"></i> Tambah </button>
                                                            </div>
                                                            <br></br><br></br>
                                                            {educationlist.map((y, l) => {

                                                                return (
                                                                    <div className="col-md-4 mb-3" key={l}>
                                                                        <div className="shadow p-3 mb-5 bg-white rounded">
                                                                            <div className="container">
                                                                                <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <strong className="input-group mb-0">Tahun Mulai {y.mulai}</strong>
                                                                                        <strong className="input-group mb-0">Tahun Lulus {y.lulus}</strong>
                                                                                        <strong className="input-group mb-0">Jenjang {y.jenjang}</strong>
                                                                                        <strong>Institusi {y.institusi}</strong>
                                                                                        <br />
                                                                                    </div>

                                                                                    <div className="row">
                                                                                        <div className="col-md-12" >
                                                                                            <button className="btn btn-sm btn-warning " data-toggle="modal" data-target="#edit" onClick={() => handleEditClick(l, y.mulai, y.lulus, y.institusi, y.jenjang, y.ijazah)} ><i className="fas fa-edit"></i> Edit</button>
                                                                                            &nbsp;
                                                                                            <button className="btn btn-sm btn-danger" onClick={() => handleRemoveClick(l)} ><i className="fas fa-trash"></i> Hapus</button>
                                                                                            &nbsp;
                                                                                            {y.ijazah && <><a href={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/ijazah/"+y.ijazah} target="_blank" rel="noreferrer"  className="btn btn-primary btn-sm" ><i className="fas fa-image"></i> View Ijazah</a></>} 
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footers />

            <div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => handleReset()}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Tahun Mulai</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="mulai"
                                                id="mulai"
                                                className="form-control"
                                                placeholder="Tahun Mulai"
                                                value={x.mulai}
                                                type="number"
                                                onChange={e => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Tahun Lulus</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="lulus"
                                                id="lulus"
                                                className="form-control"
                                                placeholder="Tahun Lulus"
                                                value={x.lulus}
                                                type="number"
                                                onChange={e => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Institusi</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="institusi"
                                                id="institusi"
                                                className="form-control"
                                                placeholder="institusi"
                                                value={x.institusi}
                                                onChange={e => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Jenjang</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <select className="form-control" name="jenjang" id="jenjang" value={x.jenjang} onChange={e => handleInputChange(e, index)}>
                                                <option value="TK">TK</option>
                                                <option value="SD">SD</option>
                                                <option value="SMP">SMP</option>
                                                <option value="SMA">SMA</option>
                                                <option value="D1">D1</option>
                                                <option value="D2">D2</option>
                                                <option value="D2">D2</option>
                                                <option value="D3">D3</option>
                                                <option value="D4">D4</option>
                                                <option value="S1">S1</option>
                                                <option value="S2">S2</option>
                                                <option value="S3">S3</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Ijazah</label>
                                    <div className="row">
                                        <div className="col-md-10">
                                            <input
                                                className="form-control"
                                                onChange={(e) => handleUploadClick(e.target.files[0], index)}
                                                type="file"
                                                name="file"
                                                id="ijazah"
                                                label="Upload Profile Picture"
                                            />
                                        </div>
                                        <div className="col-sm-2 py-1">
                                            <div style={{ display: 'none' }} id={"upload" + index} class="spinner-border text-primary col-form-label br-1" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <a style={{ display: 'none' }} href={x.ijazah} target="_blank" rel="noreferrer" id={"preview" + index} className="btn btn-primary btn-sm mb-2" ><i className="fas fa-image"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" onClick={submitHandler.bind(this)} className="btn btn-success btn-block" data-dismiss="modal" aria-label="Close">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="edit" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Tahun Mulai</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={start}
                                                name="start"
                                                onChange={(e) => setStart(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Tahun Lulus</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="end"
                                                className="form-control"
                                                placeholder="Tahun Lulus"
                                                value={end}
                                                type="number"
                                                onChange={(e) => setEnd(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Institusi</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="institusi"
                                                className="form-control"
                                                placeholder="institusi"
                                                value={institusi}
                                                onChange={(e) => setInsitusi(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Jenjang</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <select className="form-control" name="jenjang" value={jenjang} onChange={(e) => setJenjang(e.target.value)}>
                                                <option value="TK">TK</option>
                                                <option value="SD">SD</option>
                                                <option value="SMP">SMP</option>
                                                <option value="SMA">SMA</option>
                                                <option value="D1">D1</option>
                                                <option value="D2">D2</option>
                                                <option value="D2">D2</option>
                                                <option value="D3">D3</option>
                                                <option value="D4">D4</option>
                                                <option value="S1">S1</option>
                                                <option value="S2">S2</option>
                                                <option value="S3">S3</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Ijazah</label>
                                    <div className="row">
                                        <div className="col-md-10">
                                            <input
                                                className="form-control"
                                                onChange={(e) => handleUploadClickedit(e.target.files[0])}
                                                type="file"
                                                name="file"
                                                id="ijazah"
                                                label="Upload Profile Picture"
                                            />
                                        </div>
                                        <div className="col-sm-2 py-1">
                                            <div style={{ display: 'none' }} id={"upload_edit"} class="spinner-border text-primary col-form-label br-1" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <a style={{ display: 'none' }} href="#0" target="_blank" rel="noreferrer" id={"preview_edit"} className="btn btn-primary btn-sm mb-2" ><i className="fas fa-image"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" onClick={updateHandler.bind(this)} className="btn btn-success btn-block" data-dismiss="modal" aria-label="Close">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}
export default Edit;
