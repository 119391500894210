import React, { Component } from 'react';
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { getDetails } from "../../actions/eventActions";
import Parser from 'html-react-parser';
import Moment from 'react-moment';
import 'moment/locale/id';
const bucket = process.env.REACT_APP_API_KEY


export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banner: '',
            title: '',
            type: '',
            date: '',
            time: '',
            timeend:'',
            user:'',
            moderator: '',
            skp: '',
            jmlhmateri: '',
            nomorpendaftaran: '',
            linkmeet: '',
            meetid: '',
            passcode: '',
            sertificate: '',
            speakers: [],
            desc: '',
            gelardepan:'',
            gelarbelakang:''

        };
    }
    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo) {
            this.receivedData()
        }else{
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }
  
    receivedData() {

        getDetails().then(res => {
            if (res.status !== 'success') {
                localStorage.removeItem('recent')
                this.props.history.push(`/`)
            } else {
                var master = JSON.parse(localStorage.getItem('info'));
                var user = master['name']
                // var id = master['data']['user']['id']
                // for (let index = 0; index < res['data']['participan'].length; index++) {
                //     if (res['data']['paticipans'][index]['idmember'] === id) {
                //         this.setState({
                //             nomorpendaftaran: res['data']['paticipans'][index]['nomorpendaftaran'],
                //         })
                //     }
                // }
                this.setState({
                    banner: res['data']['banner'],
                    title: res['data']['title'],
                    type: res['data']['type'],
                    date: res['data']['date'],
                    time: res['data']['time'],
                    timeend: res['data']['timeend'],
                    user: user,
                    moderator: res['data']['moderator'],
                    skp: res['data']['skp'],
                    jmlhmateri: res['data']['jmlhmateri'],
                    speakers: res['data']['speakers'],
                    desc: res['data']['desc'],
                    meetid: res['data']['meetid'],
                    passcode: res['data']['passcode'],
                    linkmeet: res['data']['linkmeet'],
                    sertificate: res['data']['sertificate'],
                })
               


            }

        })

    }


    render() {
       

        return (
            <div id="main-wrapper">
                <Headers />
                <Headermobile />
                <Menumobile />
                <BottomNavbarMobile />
                <BottomNavbarMobilePopup />
                {/* Page Banner Section Start */}
                <div className="page-banner-section section bg_color--5 pt-120 pt-lg-100 pt-md-80 pt-sm-60 pt-xs-50">
                    <div className="container p-0">
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="banner-image" id="banner-image">
                                    <img src={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/event/"+this.state.banner} alt="banner" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Page Banner Section End */}
                {/* Breadcrumb Section Start */}
                <div className="breadcrumb-section section bg_color--5">
                    <div className="container job-content-box">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-breadcrumb-content">
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="#0">Event</a></li>
                                        <li>Event Detail</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="job-head-content">
                                    <h1 className="title">{this.state.title}</h1>
                                    <p className="employer-name"></p>
                                    <div className="job-meta-detail">
                                        <ul>
                                            <li className="posted">
                                                <i className="lnr lnr-calendar-full" />
                                                <span className="text"> Tanggal: </span>
                                                <span className="time"><Moment locale="id" format="DD MMM YYYY">{this.state.date}</Moment></span>
                                            </li>
                                            <li className="expries">
                                                <i className="lnr lnr-clock" />
                                                <span className="text"> Waktu: </span>
                                                <span className="date theme-color"> {this.state.time}  <strong>-</strong> {this.state.timeend} </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <br></br>
                        <strong>Pendaftaran {this.state.type} Berhasil</strong>
                        <div className="employer-meta-box mt-30">
                            <div className="row no-gutters">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    {/* Single Meta Field Start */}
                                    <div className="single-meta-field meta-field-three">
                                        <div className="field-label">
                                            <i className="lnr lnr-earth" />
                                            <span>Meeting Room </span>
                                        </div>
                                        <div className="field-value">&nbsp;&nbsp;&nbsp;&nbsp;<a href={this.state.linkmeet} >Room</a></div>
                                    </div>
                                    {/* Single Meta Field Start */}
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    {/* Single Meta Field Start */}
                                    <div className="single-meta-field meta-field-three">
                                        <div className="field-label">
                                            <i className="lnr  lnr-users" />
                                            <span>Meeting ID </span>
                                        </div>
                                        <div className="field-value">&nbsp;&nbsp;&nbsp;&nbsp;{this.state.meetid}</div>
                                    </div>
                                    {/* Single Meta Field Start */}
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    {/* Single Meta Field Start */}
                                    <div className="single-meta-field meta-field-three border-0">
                                        <div className="field-label">
                                            <i className="lnr lnr-lock" />
                                            <span>Passcode </span>
                                        </div>
                                        <div className="field-value">&nbsp;&nbsp;&nbsp;&nbsp;{this.state.passcode}</div>
                                    </div>
                                    {/* Single Meta Field Start */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* Breadcrumb Section Start */}
                {/* Job Details Section Start */}
                <div className="job-details-section section bg_color--5 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
                    <div className="container job-content-box st-border">
                        <div className="row">
                            <div className="col-lg-4 order-lg-2 order-2 mt-sm-60 mt-xs-50">
                                <div className="sidebar-wrapper-three">
                                    <div className="common-sidebar-widget sidebar-three">
                                        <h2 className="sidebar-title">Informasi Kegiatan</h2>
                                        <div className="sidebar-meta">
                                            <div className="row no-gutters">
                                                <div className="col-lg-12">
                                                    {/* Single Meta Field Start */}
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-tag" />
                                                            <span>Tipe: </span>
                                                        </div>
                                                        <div className="field-value"><strong>{this.state.type}</strong></div>
                                                    </div>
                                                    {/* Single Meta Field Start */}
                                                </div>
                                                <div className="col-lg-12">
                                                    {/* Single Meta Field Start */}
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-user" />
                                                            <span>Moderator: </span>
                                                        </div>
                                                        <div className="field-value"><strong>{this.state.moderator}</strong></div>
                                                    </div>
                                                    {/* Single Meta Field Start */}
                                                </div>
                                                <div className="col-lg-12">
                                                    {/* Single Meta Field Start */}
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-gift" />
                                                            <span>Nilai SKP </span>
                                                        </div>
                                                        <div className="field-value"><strong>{this.state.skp}</strong></div>
                                                    </div>
                                                    {/* Single Meta Field Start */}
                                                </div>

                                                <div className="col-lg-12">
                                                    {/* Single Meta Field Start */}
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-mic" />
                                                            <span>Pembicara </span>
                                                        </div>
                                                        <div className="field-value">
                                                            <div className="job-skill-tag">
                                                                {this.state.speakers.map((value, key) =>
                                                                    <a href="#0" className="fw-600 btn"><strong>{value.name}</strong></a>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Single Meta Field Start */}
                                                </div>
                                                <div className="col-lg-12">
                                                    {/* Single Meta Field Start */}
                                                    <div className="single-meta-field">
                                                        <div className="field-label">
                                                            <i className="lnr lnr-layers" />
                                                            <span>Materi </span>
                                                        </div>
                                                        <div className="field-value">
                                                            <div className="job-skill-tag">
                                                                {this.state.speakers.map(function (value) {
                                                                    return <>
                                                                    {(() => {
                                                                   
                                                                    
                                                                        if (value.file !== "-" && value.file !== "" && value.type === "1") {
                                                                            return (
                                                                                <a target="blank" href={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/materi/"+value.file} className="fw-600 btn"><strong><i class="fas fa-file-download"></i> {value.materi}</strong></a>
                                                                            )
                                                                        }else {
                                                                            return (
                                                                                <a href="#0" className="fw-600 btn disabled"><strong>{value.materi}</strong></a>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </>
                                                               })}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Single Meta Field Start */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 order-lg-1 order-1 pr-55 pr-md-15 pr-sm-15 pr-xs-15">
                                <div className="job-detail-content">
                                    <div className="field-descriptions mt-xs-20 mb-60 mb-sm-30 mb-xs-30">
                                        <h2 className="sidebar-title">Deskripsi</h2>
                                        <p>{Parser(this.state.desc)}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Job Details Section End */}

                <Footers />
            </div>


        )
    }
}
