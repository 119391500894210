import React, { useEffect, useState } from "react";
import Footers from "../../components/Footer";
import Headers from "../../components/Header";
import BottomNavbarMobile from "../../components/BottomNavbarMobile.js";
import BottomNavbarMobilePopup from "../../components/BottomNavbarMobilePopup";
import Headermobile from "../../components/Headermobile";
import Menumobile from "../../components/Menumobile";
import { useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { getCandidatebyemail, getjobListclient } from "../../actions/jobAction";
import Moment from 'react-moment';
import 'moment/locale/id';

const font = {
    fontSize: '12px',
}
const margin = {
    marginRight: "20px"
}

function Edit({ history }) {
    const userLogin = useSelector((state) => state.userLogin);
    const [data, setData] = useState([]);
    const [job, setJob] = useState([]);
    const [searchTitle, setSearchTitle] = useState("");
    const [status, setStatus] = useState("");
    const [date, setDate] = useState("");
    const [filterdate, setFilterdate] = useState([]);

    const { userInfo } = userLogin;
    useEffect(() => {
        if (userInfo) {
            history.push("/riwayat-lamaran");
            Listcandidate("", "", "");
        } else {
            history.push("/login");
        }
    }, [
        history,
        userInfo
    ]);

    const onChangeFilter = (e) => {
        var statusfilter = ""
        var datefilter = ""
        var title = ""
        if (e.target.name === "searchTitle") {
            setSearchTitle(e.target.value)
            title = e.target.value
        }
        else if (e.target.name === "status") {
            setStatus(e.target.value)
            statusfilter = e.target.value
        } else if (e.target.name === "date") {
            setDate(e.target.value)
            datefilter = e.target.value
            var convert = parseInt(datefilter)
            // console.log(GetDays(convert))  
            GetDays(convert)
            setFilterdate(DateArray)
            // console.log(DateArray);
        }
        Listcandidate(title ,statusfilter, datefilter)
    }

    var DateArray = [];
    const GetDays = (d, Mention_today = false) => {
        //Mention today mean the array will have today date 
        var days = d;
        for (var i = 0; i < days; i++) {
            if (!Mention_today && i === 0) { i = 0; days += 1 }
            var date = new Date();
            var last = new Date(date.getTime() - (i * 24 * 60 * 60 * 1000));
            var day = last.getDate();
            var month = last.getMonth() + 1;
            var year = last.getFullYear();
            const fulld = (Number(year) + '-' + Number(month) + '-' + Number(day)) // Format date as you like
            DateArray.push(fulld);
        }
        // var filter = DateArray;
        // setFilterdate(filter)

        return DateArray;
    }

    const Listcandidate = (title, statusfilter, datefilter) => {
        getCandidatebyemail().then(res => {
            const data = res['data'] ? res['data'] : [];
            var filter = data.filter(item => (title ? item.jobtitle.toLowerCase().indexOf(title.toLowerCase()) > -1: item.jobtitle) && (statusfilter ? item.status === statusfilter : item.status))
            setData(filter)
        })

        getjobListclient({ "ishome": false }).then(res => {
            const data = res['data'] ? res['data'] : [];
            setJob(data)
        })

    }

    const filterbyDate = (date) => {
        const found = filterdate.some(el => el === date);
        console.log(found);
        if (!found) {
            return "false";
        } else {
            return "true";
        }

    }

    const jobtitle = (id) => {
        var masterjob = job.filter(one => (id ? one.id === id : []))
        if (masterjob.length !== 0) {
            return masterjob[0]['jobtitle'] ? masterjob[0]['jobtitle'] : ''
        } else {
            return ''
        }
    }

    const company = (id) => {
        var masterjob = job.filter(one => (id ? one.id === id : []))
        if (masterjob.length !== 0) {
            return masterjob[0]['companyname'] ? masterjob[0]['companyname'] : ''
        } else {
            return ''
        }
    }

    const slug = (id) => {
        var masterjob = job.filter(one => (id ? one.id === id : []))
        if (masterjob.length !== 0) {
            return masterjob[0]['slug'] ? masterjob[0]['slug'] : ''
        } else {
            return ''
        }
    }

    const type = (id) => {
        var masterjob = job.filter(one => (id ? one.id === id : []))
        if (masterjob.length !== 0) {
            return masterjob[0]['jobtype'] ? masterjob[0]['jobtype'] : ''
        } else {
            return ''
        }
    }

    return (
        <div id="main-wrapper">
            <Headers />
            <Headermobile />
            <Menumobile />
            <BottomNavbarMobile />
            <BottomNavbarMobilePopup />

            <div className="dashboard-content-section section bg_color--5">
                <div className="container-fluid p-0">
                    <div className="row no-gutters">
                        {/* <Menudashboard /> */}
                        <div className="col-xl-12 col-lg-9">
                            <div className="dashboard-main-inner">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-breadcrumb-content mb-40">
                                            <h4>&nbsp;</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-overview">
                                    <div className="row">
                                        <div className="col-xl-12 col-12">
                                            <div className="profile-applications mb-50">
                                                <div className="profile-applications-heading">
                                                    <ul className="nav">
                                                        <li key="1" style={margin}><NavLink to="/memberdetailprofile" style={font}>Profile</NavLink></li>
                                                        <li key="2" style={margin}><NavLink to="/daftar_activity_admin" style={font}>Aktivitas</NavLink></li>
                                                        <li key="3" style={margin}><NavLink to="/listaddress" style={font}>Alamat</NavLink></li>
                                                        <li key="4" style={margin}><NavLink to="/listpendidikan" style={font}>Pendidikan</NavLink></li>
                                                        <li key="5" style={margin}><NavLink to="/listpekerjaan" style={font}>Pekerjaan</NavLink></li>
                                                        <li key="6" style={margin}><NavLink to="/attachmentFile" style={font}>Lampiran</NavLink></li>
                                                    </ul>
                                                </div>

                                                <div className="profile-applications-main-block">
                                                    <div className="profile-applications-form">
                                                        <div className="row">
                                                            <div className="col-md-12 py-3">
                                                                <strong>Lamaran</strong>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="searchTitle"
                                                                        placeholder="Masukan Nama Posisi"
                                                                        value={searchTitle}
                                                                        onChange={onChangeFilter}
                                                                    />
                                                                </div></div>
                                                            <div className="col-md-4">
                                                                <div className="form-group row">
                                                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Status</label>
                                                                    <div className="col-sm-9">
                                                                        <select className="form-control" name="status" value={status} onChange={onChangeFilter} >
                                                                            <option value="">Semua</option>
                                                                            <option value="Lamaran Ditinjau">Lamaran Ditinjau</option>
                                                                            <option value="Diundang Wawancara">Diundang Wawancara</option>
                                                                            <option value="Lamaran Ditolak">Lamaran Ditolak</option>
                                                                            <option value="Lamaran Diterima">Lamaran Diterima</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4 ">
                                                                <div className="form-group row">
                                                                    <div className="col-sm-9">
                                                                        <select className="form-control" name="date" value={date} onChange={onChangeFilter}  >
                                                                            <option value="">Semua</option>
                                                                            <option value="90">90 Hari Terakhir</option>
                                                                            <option value="30">30 Hari Terakhir</option>
                                                                            <option value="14">14 Hari Terakhir</option>
                                                                            <option value="7">7 Hari Terakhir</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br></br><br></br>
                                                            {data
                                                            .sort((a, b) => a.applydate - b.applydate)
                                                            .map(function (y, l) {
                                                                return <>
                                                                    {(() => {
                                                                        if (filterbyDate(y.applydate) === "true" && date !== "") {
                                                                            return (
                                                                                <div className="col-md-4 mb-3" key={l}>
                                                                                    <div className="shadow p-3 mb-5 bg-white rounded">
                                                                                        <div className="container">
                                                                                            <div className="row">
                                                                                                <div className="col-md-12">
                                                                                                    <strong className="input-group mb-0">{jobtitle(y.jobid)}</strong>
                                                                                                    <strong className="input-group mb-0">{company(y.jobid)} - {type(y.jobid)}</strong>
                                                                                                    <strong className="input-group mb-0">{y.status}</strong>
                                                                                                    <strong>Anda sudah melamar pada <Moment locale="id" format="DD MMM YYYY">{y.applydate}</Moment> </strong>
                                                                                                    <br />
                                                                                                    <a href={"jobdetail?job=" + slug(y.jobid)}  className="ht-btn transparent-btn-two w-100">Lihat Detail Lowongan Pekerjaan</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        } else  if(date === ""){
                                                                            return (
                                                                                <div className="col-md-4 mb-3" key={l}>
                                                                                    <div className="shadow p-3 mb-5 bg-white rounded">
                                                                                        <div className="container">
                                                                                            <div className="row">
                                                                                                <div className="col-md-12">
                                                                                                    <strong className="input-group mb-0">{jobtitle(y.jobid)}</strong>
                                                                                                    <strong className="input-group mb-0">{company(y.jobid)} - {type(y.jobid)}</strong>
                                                                                                    <strong className="input-group mb-0">{y.status}</strong>
                                                                                                    <strong>Anda sudah melamar pada <Moment locale="id" format="DD MMM YYYY">{y.applydate}</Moment> </strong>
                                                                                                    <br />
                                                                                                    <a href={"jobdetail?job=" + slug(y.jobid)}  className="ht-btn transparent-btn-two w-100">Lihat Detail Lowongan Pekerjaan</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </>
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footers />
        </div>

    )
}
export default Edit;
