import React from "react";

function Menumobile() {

    return (
        <div className="popup-mobile-manu popup-mobile-visiable">
            <div className="inner">
                <div className="mobileheader">
                    <div className="logo">
                        <a href="/">
                            <img src="assets/images/logo-mobile.png" alt="Multipurpose" />
                        </a>
                    </div>
                    <a className="mobile-close" href="!#"> </a>
                </div>
                <div className="menu-content">
                    <ul className="menulist object-custom-menu">
                        <li className="has-mega-menu"><a href="!#"><span>Home</span></a>
                            {/* Start Dropdown Menu */}
                            <ul className="object-submenu">
                                <li><a href="/"><span>Home V1</span></a></li>
                                <li><a href="index-2.html"><span>Home V2</span></a></li>
                                <li><a href="index-3.html"><span>Home V3</span></a></li>
                                <li><a href="index-4.html"><span>Home V4</span></a></li>
                            </ul>
                            {/* End Dropdown Menu */}
                        </li>
                        <li className="has-mega-menu"><a href="!#"><span>Jobs</span></a>
                            {/* Start Dropdown Menu */}
                            <ul className="object-submenu">
                                <li><a href="#0"><span>Jobs Listing</span></a></li>
                                <li><a href="job-with-map.html"><span>Jobs With Map</span></a></li>
                                <li><a href="job-details.html"><span>Job Detail V1</span></a></li>
                                <li><a href="job-details-two.html"><span>Job Detail V2</span></a></li>
                            </ul>
                            {/* End Dropdown Menu */}
                        </li>
                        <li className="has-mega-menu"><a href="!#"><span>Candidates</span></a>
                            {/* Start Dropdown Menu */}
                            <ul className="object-submenu">
                                <li><a href="candidates-listing.html"><span>Candidates Listing</span></a></li>
                                <li><a href="candidate-details.html"><span>Candidate Details V1</span></a></li>
                                <li><a href="candidate-details-two.html"><span>Candidate Details V2</span></a></li>
                            </ul>
                            {/* End Dropdown Menu */}
                        </li>
                        <li className="has-mega-menu"><a href="!#"><span>Employers</span></a>
                            {/* Start Dropdown Menu */}
                            <ul className="object-submenu">
                                <li><a href="employer-listing.html"><span>Employers Listing</span></a></li>
                                <li><a href="employer-details.html"><span>Employer Detail V1</span></a></li>
                                <li><a href="employer-details-two.html"><span>Employer Detail V2</span></a></li>
                            </ul>
                            {/* End Dropdown Menu */}
                        </li>
                        <li className="has-mega-menu"><a href="!#"><span>Blog</span></a>
                            {/* Start Dropdown Menu */}
                            <ul className="object-submenu">
                                <li><a href="blog.html"><span>Blog</span></a></li>
                                <li><a href="blog-details.html"><span>Blog Details</span></a></li>
                            </ul>
                            {/* End Dropdown Menu */}
                        </li>
                        <li className="has-mega-menu"><a href="!#"><span>Pages</span></a>
                            {/* Start Dropdown Menu */}
                            <ul className="object-submenu">
                                <li><a href="about.html"><span>About Us</span></a></li>
                                <li><a href="contact.html"><span>Contact Us</span></a></li>
                                <li><a href="faq.html"><span>FAQS</span></a></li>
                                <li><a href="pricing.html"><span>Pricing &amp; Plan</span></a></li>
                                <li><a href="login-register.html"><span>Login / Register</span></a></li>
                                <li><a href="dashboard.html"><span>Dashboard</span></a></li>
                                <li><a href="shop.html"><span>Shop</span></a></li>
                                <li><a href="product-details.html"><span>Product Details</span></a></li>
                                <li><a href="cart.html"><span>Cart</span></a></li>
                                <li><a href="checkout.html"><span>Checkout</span></a></li>
                                <li><a href="wishlist.html"><span>Wishlist</span></a></li>
                                <li><a href="404.html"><span>404 Error</span></a></li>
                            </ul>
                            {/* End Dropdown Menu */}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    )
}
export default Menumobile;
