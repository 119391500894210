import React from "react";
import { useSelector } from "react-redux";

function BottomNavbarMobile(props) {
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const notlogin = (
        <a className={`toggle-btn ${props.menu && props.menu === 'account' ? 'active' : ''}`} href="login">
            <span><i className="lnr lnr-user" /></span>
            <span className="text">Akun</span>
        </a>
    );
    const login = (
        <a className={`toggle-btn ${props.menu && props.menu === 'account' ? 'active' : ''}`} href="profile">
            <span><i className="lnr lnr-user" /></span>
            <span className="text">Akun</span>
        </a>
    );

    return (
        <div className="bottom-navbar-mobile section d-block d-lg-none" >
            <nav id="menumobile">
                <ul className="list-actions">
                    <li>
                        <a className={`toggle-btn ${props.menu && props.menu === 'home' ? `active` : props.menu}`} href="/">
                            <span><i className="lnr lnr-home" /></span>
                            <span className="text">Home</span>
                        </a>
                    </li>
                    <li>
                        <a className={`toggle-btn ${props.menu && props.menu === 'event' ? 'active' : ''}`} href="/event">
                            <span><i className="lnr lnr-calendar-full" /></span>
                            <span className="text">Kegiatan</span>
                        </a>
                    </li>
                    <li>
                        <a className={`toggle-btn ${props.menu && props.menu === 'job' ? 'active' : ''}`} href="/job">
                            <span><i className="lnr lnr-briefcase" /></span>
                            <span className="text">Pekerjaan</span>
                        </a>
                    </li>
                    <li>
                        {userInfo ? login : notlogin}

                    </li>
                </ul>
            </nav>
        </div>

    )
}
export default BottomNavbarMobile;
